import { DateTime } from 'luxon';
import moment from 'moment'


/**
 * Format Date.
 * @param {date} date
 * @param {format} format
 * @returns {string}
 */
export default (date, format) => {
  return DateTime.fromISO(date).toFormat(format)
}

// date range
export function startAndEndDate(start, end, edit = false) {
  if(isValidDate(end)){
    if(edit){
      if(start && new Date(end) >= new Date(start)){
        return true;
      }else{
        return false
      }
    }else if(!edit && isFutureDate(end)){
      if(start && new Date(end) >= new Date(start)){
        return true;
      }else{
        return false
      }
    }else{
      return false
    }
  }
  return false
}

// https://stackoverflow.com/a/17422369
export function isValidDate(date) {

  // MM/DD/YYYY
  var date_regex_slash = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  // YYYY-MM-DD
  var date_regex_dash = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/;
  // DD-MM-YYYY
  var date_month_regex_dash = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;

  if ((date_regex_slash.test(date)) || (date_regex_dash.test(date)) || (date_month_regex_dash.test(date))) {
    return true;
  }

  return false;
}

// future date select
export function isFutureDate(date) {
  if(isValidDate(date)){
    if(new Date(date) >= new Date()){
      return true;
    }else{
      return false
    }
  }
    return false
}

// https://stackoverflow.com/a/17422369
export function isValidationDatePicker(date) {
  if(isValidDate(date)){
    var dob = new Date(date);
    var today = new Date();
    if (dob > today) {
      return error = "Age must be minimum of 13 years.";
    }
    //calculate month difference from current date in time
    var month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    var age_dt = new Date(month_diff);

    //extract year from date
    var year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    var age = Math.abs(year - 1970);
    var error = ""
    if (age >= 13) {
      return error = "";
    } else {
      return error = "Age must be minimum of 13 years.";
    }
  } else {
    return error = 'Please enter valid date.';
  }


}



export function formatDate(dateStr = '', includeYear = true) {

  // MM/DD/YYYY
  var date_regex_slash = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/

  // DD/MM/YYYY
  var date_month_regex_slash = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/

  // YYYY-MM-DD
  var date_regex_dash = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/;
  // /(\d{4})-(\d{2})-(\d{2})/g
  // DD_MM_YYYY
  var date_regex_format_date = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{4}$/;

  if (dateStr && (date_regex_slash.test(dateStr))) {
    return dateStr.replace(date_regex_slash, (match, monthStr, dayStr, yearStr) => {
      let date = match.split('/');
      let yearFormatted = '';
      if (includeYear) yearFormatted = `/${date[2]}`
      return `${date[0]}/${date[1]}${yearFormatted}`;
    });

  } else if ((date_month_regex_slash.test(dateStr))) {
    return dateStr.replace(date_month_regex_slash, (match, dateStr, monthStr, yearStr) => {
      let date = match.split('/')
      let yearFormatted = '';
      if (includeYear) yearFormatted = `/${date[2]}`;
      // return `${parseInt(monthStr, 10)}/${parseInt(dayStr, 10)}${yearFormatted}`;
      // return `${monthStr}/${dayStr}${yearFormatted}`;
      return `${date[0]}/${date[1]}${yearFormatted}`;

    });
  }
  else if ((date_regex_dash.test(dateStr))) {
    return dateStr.replace(date_regex_dash, (match, yearStr, monthStr, dayStr) => {
      let date = match.split('-')
      let yearFormatted = '';
      if (includeYear) yearFormatted = `/${date[0]}`;

      // return `${parseInt(monthStr, 10)}/${parseInt(dayStr, 10)}${yearFormatted}`;
      // return `${monthStr}/${dayStr}${yearFormatted}`;
      return `${date[1]}/${date[2]}${yearFormatted}`;

    });
  } else if ((date_regex_format_date.test(dateStr))) {
    return dateStr.replace(date_regex_format_date, (match, monthStr, dayStr, yearStr) => {
      let date = match.split('/');
      return `${date[1]}-${date[0]}-${date[2]}`;
    });

  }

  else {
    return dateStr;
  }


  // return dateStr.replace(/(\d{4})-(\d{2})-(\d{2})/g, (match, yearStr, monthStr, dayStr) => {
  //   let yearFormatted = '';
  //   if (includeYear) yearFormatted = `/${yearStr}`;
  //   // return `${parseInt(monthStr, 10)}/${parseInt(dayStr, 10)}${yearFormatted}`;
  //   return `${monthStr}/${dayStr}${yearFormatted}`;
  // });
}


export const formatDateByMoment = (date, formate) => {
  return date ? moment(date).format(formate) : ''
}