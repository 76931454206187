import React from 'react';
import { AutoComplete } from 'antd';
import './antd.scss';
import './form.scss'


export default function SelectAutosuggest({ setSchool = () => { }, isChat = false, label = "", options = [], handleChangeChat = () => { }, handleSchoolSearch = () => { }, setIsSchool = () => { }, isSchool = "", isError = false  , value  ,disabled= false } ) {


    const handleSelectSchool = (val) => {
        var tempArr = val.split(/[\s,]+/);
        var last = tempArr[tempArr.length - 1]
        var result = val.replace("," + last, "");
        setIsSchool(result)
        setSchool(val)

    }
    // Finally, render it!
    return (
        <div className="auto-search-select">
            <label className="CTAGrey">{label}</label>
            <AutoComplete
                options={options}
                className={isError && "error-select"}
                value={isSchool ? isSchool : value? value.value : ""}
                onChange={(val) => handleSchoolSearch(val)}
                onSelect={isChat ? handleChangeChat : handleSelectSchool}
                disabled = {disabled}
            // filterOption={(inputValue, option) =>
            //     option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            // }
            />
        </div>

    );

}
