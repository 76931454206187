import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "32", height = "45", fill = MAIN_CONFIG.COLORS.fillBlue, iconColor = MAIN_CONFIG.COLORS.fillBlack, ariaLabel = "Video", ariaLabelDescription = "Video" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill} viewBox="0 0 384 512">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <path d="M105.5 1.4c-1.6.8-25.8 24.3-53.7 52.2C8.6 97 1 105 .5 108c-.3 1.9-.4 85.2-.3 185 .3 174.9.4 181.7 2.2 186.4 5.9 15.2 15 24.3 30.2 30.2 4.7 1.8 10.8 1.9 154.9 2.2 94.1.2 152.4-.1 156.3-.7 13-2 25.7-10.6 32.6-21.9 7.1-11.9 6.6 7.3 6.6-233.2s.5-221.3-6.6-233.2C369.6 11.7 357 3 344.4 1c-3.6-.6-51.7-1-120.9-1-94.7.1-115.5.3-118 1.4zm238.1 30.5c2.3 1 5.2 3.4 6.5 5.2l2.4 3.4v431l-2.4 3.4c-1.3 1.8-4.2 4.2-6.5 5.2-3.9 1.8-9.9 1.9-151.9 1.9-123.3 0-148.3-.2-151-1.4-3.9-1.6-8.2-6.1-9.6-9.9-.8-2-1.1-53.1-1.1-165.7V142.1l38.3-.3c37.3-.3 38.3-.4 44-2.6 11.7-4.8 22.1-15.2 26.9-27 2.2-5.6 2.3-6.6 2.6-44l.3-38.2h98.7c94.1 0 98.9.1 102.8 1.9zM112 68.4c0 31.9 0 32.3-2.3 35.7-1.2 1.8-3.8 4.4-5.6 5.6-3.4 2.3-3.7 2.3-36 2.3H35.5l38-38c20.9-20.9 38.1-38 38.2-38 .2 0 .3 14.6.3 32.4z" />
            <path d="M264 179.2c-4.7 3.3-7.2 8.3-6.7 13.6.5 4.5 2.2 7.3 11.2 18.2 10 12 17.2 26 21.9 42.4 3.7 12.6 4.8 20.4 4.8 34.6.1 31.3-10.2 58.6-31.3 83.2-3.6 4.2-6 8-6.4 10.2-1.8 9.6 4.8 17.6 14.5 17.6 5.9 0 9.2-2.1 16.3-10.5 45-52.7 49.5-128.5 11.1-186.1-6.8-10.4-16.9-22.1-20.6-24-4.2-2.2-11-1.8-14.8.8zM186 194.1c-1.3.6-16.2 11.3-33.1 24L122.3 241h-15.5c-18.1 0-23.3 1.2-30.7 7-3.5 2.8-5.9 5.8-8.3 10.7l-3.3 6.8-.3 22.9c-.4 23.3.4 32 3.5 37.9 2.5 4.9 8.5 10.8 13.7 13.5 4.8 2.5 6 2.7 23.6 3.2l18.5.5 29 18.1c16 10 30.4 18.9 32.2 19.8 7.1 3.6 16.4.9 20.1-5.7 1.6-3 1.7-9.6 1.7-87.8v-84.5l-2.5-3.7c-4-6-11.4-8.2-18-5.6zm-9.4 146.6c-.2.2-9.7-5.5-21.2-12.7-11.5-7.2-22.4-13.5-24.4-14-1.9-.5-11-1-20.3-1H94l.2-20.8.3-20.7 19.5-.6c10.7-.3 19.5-.6 19.6-.7 0-.1 9.7-7.4 21.5-16.3l21.4-16 .3 51.2c.1 28.2 0 51.4-.2 51.6z" />
            <path d="M233.4 229c-4.7 2.4-8.4 8.1-8.4 13.2 0 2.1 1.6 6.1 4.3 10.9 8.7 15.2 11.3 25.3 10.4 39.3-.6 10-3.4 18.7-9.4 28.9-5.3 9.1-6.3 13.9-3.8 19.1 3.5 7.4 12.1 10.5 19.6 7 6.7-3 15.6-17.7 20.6-34.1 2.4-8 2.6-10.3 2.7-24.8 0-13.4-.4-17.3-2.2-23.9-3.9-14.2-14.2-32.4-20.1-35.5-4.1-2.2-9.5-2.3-13.7-.1z" />
        </svg>
    );
};
