import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, height = "40px", width = "40px", ariaLabel = "Calendar", ariaLabelDescription = "Calendar" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 40 40">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <g fill="none" fillRule="evenodd">
                <g fill={color}>
                    <g>
                        <g>
                            <path d="M9.648.154V4h12.35V.154h.616V4h1.078c3.154 0 5.732 2.5 5.843 5.637l.003.104v13.644c0 2.662-1.78 4.91-4.215 5.615-.912 1.047-2.255 1.709-3.753 1.709H4.978C2.228 30.709 0 28.48 0 25.73V12.457c0-1.639.792-3.093 2.014-4 .614-2.526 2.87-4.409 5.574-4.456L7.692 4h1.34V.154h.616zm19.275 11.982H2.462v11.249c0 2.855 2.288 5.176 5.13 5.23h16.1c.197 0 .392-.01.583-.032.608-.075 1.181-.26 1.7-.533l-.033.058c1.735-.828 2.942-2.583 2.98-4.624l.001-.1V12.137zm-19.89-7.52h-1.34c-2.82 0-5.124 2.232-5.228 5.031l-.003.1v1.774h26.461V9.846c0-2.856-2.288-5.177-5.131-5.23H22.614v4.142H22V4.615H9.647v4.143h-.615V4.615z" transform="translate(-370 -42) translate(370 42) translate(5.538 4)" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
