import React from 'react';
import Head from 'next/head';
import { MAIN_CONFIG } from '../../config/main';
import { get } from 'lodash';
import { reduxPage } from '../Core/store'
import { useSelector, shallowEqual } from 'react-redux';


const MetaTags = ({ pageData, isArticle = '', userContext }) => {

    const tag = useSelector(state => state.metaTitle)
    const data = {};
    let pageTitle = pageData.pageType ? pageData.pageType.charAt(0).toUpperCase() + pageData.pageType.slice(1).toLowerCase() : ''
    let pageDescription = ''
    //new Switch case 
    let userContexArray=[MAIN_CONFIG.USER_CONTEXT.APPLICANT,MAIN_CONFIG.USER_CONTEXT.TEAMMEMBER,MAIN_CONFIG.USER_CONTEXT.LOGGED_OUT]
    switch (pageData.pageType) {
        case MAIN_CONFIG.PAGE_TYPES.DONOR.APPLICATIONS: {
            // eslint-disable-next-line
            let isApplicant = userContexArray.includes(userContext)
            pageTitle = isApplicant ? get(pageData, 'scholarshipDetail.scholarship.name', pageTitle) : get(pageData, 'scholarship.name', pageTitle)
            pageDescription = isApplicant ? get(pageData, 'scholarshipDetail.scholarship.description__c', pageDescription) : get(pageData, 'scholarship.description__c', pageDescription)
            break;
        }
        case MAIN_CONFIG.PAGE_TYPES.APPLICANT.APPLY:
            // eslint-disable-next-line
            pageTitle = tag.title
            pageDescription = tag.description
            break;
        case MAIN_CONFIG.PAGE_TYPES.DONOR.HOME:
            switch (userContext) {
                case MAIN_CONFIG.USER_CONTEXT.REVIEWER: 
                    pageTitle = "Reviewer"
                    pageDescription = "Reviewer"
                    break;
                
                case MAIN_CONFIG.USER_CONTEXT.RECOMMENDER:
                case MAIN_CONFIG.USER_CONTEXT.NGB:
                case MAIN_CONFIG.USER_CONTEXT.ENDORSEDUSER:
                case MAIN_CONFIG.USER_CONTEXT.THIRDPARTYCONTRIBUTOR:
                case MAIN_CONFIG.USER_CONTEXT.SCHOOLCOUNSELLOR:
                case MAIN_CONFIG.USER_CONTEXT.VERIFIER:
                    pageTitle = "Recommendation"
                    pageDescription = "Recommendation"
                    break;

                default:
                    pageTitle = "Home | Find Scholarship Programs | Kaleidoscope"

                    break;
                
            }
        case MAIN_CONFIG.PAGE_TYPES.APPLICANT.RESUME:
            pageTitle = get(pageData, 'scholarship.name', pageTitle)
            pageDescription = get(pageData, 'scholarship.description__c', pageDescription)
            break;
            
        case MAIN_CONFIG.PAGE_TYPES.APPLICANT.APPLICATIONS:
            pageTitle = "Applications"
            pageDescription = "Applications"
            break;
        case MAIN_CONFIG.PAGE_TYPES.DONOR.SCHOLARSHIPS:
            pageTitle = "Award Programs"
            pageDescription = "Award Programs"
            break;
        case MAIN_CONFIG.PAGE_TYPES.DONOR.APPLICATION_DETAILS:
            pageTitle = "Application #" + get(pageData, 'application.name', "")
            pageDescription = get(pageData, 'application.description__c', pageDescription)
            break;
        case MAIN_CONFIG.PAGE_TYPES.DONOR.APPLICANTS:
            pageTitle = "Applications"
            pageDescription = "Applications"
            break;
        case MAIN_CONFIG.PAGE_TYPES.DONOR.NEW_SCHOLARSHIP:
            pageTitle = "New Program"
            pageDescription = "New Program"
            break;
        case MAIN_CONFIG.PAGE_TYPES.DONOR.EDIT_SCHOLARSHIP:
            pageTitle = get(pageData, 'editScholarshipData.scholarship.name', pageTitle)
            pageDescription = get(pageData, 'editScholarshipData.scholarship.description__c', pageDescription)
            break;
        case MAIN_CONFIG.PAGE_TYPES.DONOR.ACCOUNT:
            pageTitle = get(pageData, 'account.name', pageTitle)
            pageDescription = get(pageData, 'account.description__c', pageDescription)
            break;
        case MAIN_CONFIG.PAGE_TYPES.DONOR.MY_TASK:
            pageTitle = "My Task"
            pageDescription = "My Task"
            break;
        case MAIN_CONFIG.PAGE_TYPES.RECOMMENDER.RECOMMENDATION:
            pageTitle = "Recommendation #" + get(pageData, 'applicant_recommendations.name', "")
            pageDescription = get(pageData, 'applicant_recommendations.description__c', pageDescription)
            break;

        default:
            pageTitle = get(pageData, 'scholarship.name', pageTitle)
            pageDescription = get(pageData, 'scholarship.description__c', pageDescription)
    }
    return (
        <Head>
            <title>{pageTitle || 'Kaleidoscope'}</title>
            <meta name="description" content={pageDescription} />
            {isArticle && <meta property="og:type" content="article" />}
            {data.social_title && <meta property="og:title" content={data.social_title} />}
            {data.social_description && <meta property="og:description" content={data.social_description} />}
            {data.social_image && data.social_image.url && <meta property="og:image" content={data.social_image.url} />}
            {data.social_image && data.social_image_height && <meta property="og:image:height" content={parseInt(data.social_image_height, 10)} />}
            {data.social_image && data.social_image_width && <meta property="og:image:width" content={parseInt(data.social_image_width, 10)} />}
            {data.social_image && data.social_image_type && <meta property="og:image:type" content={data.social_image_type} />}
            {data.social_image && (data.social_image_alt_text || data.social_image.alt) && <meta property="og:image:alt" content={data.social_image.alt || data.social_image_alt_text} />}
            <meta name="twitter:card" content="summary_large_image" />
            {data.social_title && <meta name="twitter:title" content={data.social_title} />}
            {data.social_description && <meta name="twitter:description" content={data.social_description} />}
            {data.social_image && data.social_image.url && <meta name="twitter:image" content={data.social_image.url} />}
            {data.social_image && (data.social_image_alt_text || data.social_image.alt) && <meta name="twitter:image:alt" content={data.social_image.alt || data.social_image_alt_text} />}
            <link rel="shortcut icon" href="/static/favicon.ico" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
    );

}

export default reduxPage(MetaTags)