import React from 'react';

export default ({ id, className, color = '#FFF', backgroundColor = 'none', width = "40", height = "40", ariaLabel = "Kaleidoscope", ariaLabelDescription = "Kaleidoscope" }) => {
    return (
        <svg id={id} className={className} width={width} height={height} viewBox="0 0 27 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.64613 0.299805V16.346H10.5869L16.3031 0.299805H26.0985L21.1918 14.0458C20.3284 16.4694 18.0165 18.1019 15.4218 18.1019H13.2879V19.9977H15.4233C18.018 19.9977 20.3299 21.6124 21.1933 24.0538L26.1 37.7998H16.3061L10.6573 21.9469H8.64613V37.7983H0V0.299805H8.64613Z" fill={color} />
        </svg>
    );
}
