import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "130", height = "130", iconColor = MAIN_CONFIG.COLORS.fillBlack }) => {
    return (
        <svg id={id} className={className} data-name="Elements / Brand Icon / Calendar" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 130 130">
            <title>Date</title>
            <desc>Date</desc>
            <path id="Calendar" d="M16.177,99.3A16.178,16.178,0,0,1,0,83.126V39.985a16.144,16.144,0,0,1,6.537-13A19.018,19.018,0,0,1,24.663,12.5l.337,0h4.355V0h2l0,12.5H71.5V0h2l0,12.5H77A19,19,0,0,1,95.989,30.819L96,31.16l0,.34v44A19.025,19.025,0,0,1,82.308,93.752,16.141,16.141,0,0,1,70.1,99.3ZM8,38.944V75.5a17.081,17.081,0,0,0,16.677,17l.323,0H77a16.84,16.84,0,0,0,1.857-.1,16.179,16.179,0,0,0,5.565-1.737l-.082.153A17.081,17.081,0,0,0,94,75.823l0-.323V38.943Zm.012-8.09L8,31.176,8,31.5v5.444H94V31.5a17.083,17.083,0,0,0-16.677-17L77,14.5H73.495l0,13.463h-2V14.5H31.354l0,13.463h-2V14.5H25A16.936,16.936,0,0,0,8.012,30.854Z" transform="translate(18 13.5)" fill={color} />
        </svg>
    );
};
