import React, { useState, useEffect, useRef } from 'react';
import BrandMark from '../../../Components/SVG/BrandMark';
// import './staff-header.scss';
import ArrowDown from '../../../Components/SVG/ArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import { reduxPage } from '../../../Core/store';
// import '../DonorHeader/donor-header.scss';
import cookies from 'next-cookies';
import AirPlane from '../../../Components/SVG/AirPlane';
import MenuIcon from '../../../Components/SVG/MenuIcon';
import UserBadge from '../../../Components/UserBadge/UserBadge';
import Field from '../../../Components/Field/Field';
import { MAIN_CONFIG } from '../../../../config/main';
import './AdminHeader.scss';
import CTAButton from '../../../Components/CTAButton/CTAButton';
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import { useRouter } from 'next/router';


const AdminHeader = ({ pageType , user , accountData , setLoading = ()=> {} }) => {
    const dispatch = useDispatch();
    const currentUserType = cookies('context').admincontext;

    const router = useRouter()

    const API = new KaleidoscopeAPI({});


    return (
        <header className="admin-header">
            <h2>
                <label for="nav-toggle">
                    <MenuIcon color={MAIN_CONFIG.COLORS.fillWhite} width="25" height="25" />
                </label>
            </h2>
            <h1 className="CTAGrey" >
                {/*<p className="date">Last Login : 10-Jan-2021</p>
                    <p className="time">Time : 10:30 PM (IST)</p>*/}
            </h1>
            <div className="admin-header__user-wrapper">
                <UserBadge
                    firstName={user?.first_name}
                    lastName={user?.last_name}
                    className="user-badge"
                />
                <div className='admin-header-user-full-name'>
                    <h4 className="CTAGrey">{`${user?.first_name} ${user?.last_name}`}</h4>
                    <small className="CTAGrey">{currentUserType}</small>
                </div>
            </div>
        </header>
    );
}
export default reduxPage(AdminHeader)