import React, { useState } from 'react';
import './dot-menu.scss';
import ThreeDots from '../SVG/ThreeDots';
import Menu from '../Menu/Menu';

export default ({ id, className, orientation = 'horizontal', items = [], openMenu = "", setOpenMenu = () => { }, index = "", isTable = false, pageType, ensureDotMenuVisible = () => { }}) => {
    const [menuOpenNew, setMenuOpenNew] = useState(false);
    let hideDotManu = sessionStorage.getItem('HideDotManu')
    if (items.length === 0) {
        return null
    }

    const handleClickMenu = (e) => {
        if (isTable) {
            setOpenMenu(openMenu == index ? "" : index)
        } else {
            setMenuOpenNew(!menuOpenNew)
        }
        ensureDotMenuVisible(index)
    }
    let show = isTable && hideDotManu == 0

    return (
       <>
      { (show || (hideDotManu == null && isTable) || pageType === "RESOURCES") && 
        <button id={id} className={`dot-menu ${orientation} ${className}`} onClick={handleClickMenu}>
           {<ThreeDots ariaLabel="Menu" ariaLabelDescription="" />}
            <Menu className='dot-menu__menu' items={items} visible={isTable ? (openMenu == index && index != "" ? true : false) : menuOpenNew} />
        </button>}
        {!isTable && pageType !== "RESOURCES" && <button id={id} className={`dot-menu ${orientation} ${className}`} onClick={handleClickMenu}>
           { <span className='run-export'>Run Report</span>}
            <Menu className='dot-menu__menu' items={items} visible={isTable ? (openMenu == index && index != "" ? true : false) : menuOpenNew} />
        </button>}
       </>
    );
};
