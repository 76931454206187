import React, { useState, useEffect } from 'react';
import "./full-screen-menu.scss";
import Close from '../SVG/Close';
import WordMark from '../SVG/WordMark';
import { MAIN_CONFIG } from '../../../config/main';
import Eyes from '../SVG/Eyes';
import { DESKTOP_WIDTH } from '../../Utility/Breakpoints';
import { handleResize } from '../../Utility/HelperFunctions';

export default ({ items, closeBtnClick }) => {
    const [isMobile, setIsMobile] = useState(false);

    const resize = () => handleResize(setIsMobile, window.innerWidth < DESKTOP_WIDTH);

    useEffect(() => {
        setIsMobile(window.innerWidth < DESKTOP_WIDTH);
        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        }
    }, []);

    const renderMenuItems = () => items.map((item, i) => {
        const [showEyes, setShowEyes] = useState(false);
        const [hover, setHover] = useState(false);

        const mouseOver = () => {
            setShowEyes(true);
            setHover(true);
        };

        const mouseLeave = () => {
            setShowEyes(false);
            setHover(false);
        };

        return (
          <li key={ i } className="full-screen-menu__list-item"
              onMouseOver={ mouseOver }
              onMouseLeave={ mouseLeave }>
              { showEyes ? <Eyes className="full-screen-menu__eyes"
                                 height={ (isMobile) ? 36 : 80 }
                                 width={ (isMobile) ? 45 : 92 }/> : null }
              <a href={ item.url } className="full-screen-menu__link"
                 style={ { color: MAIN_CONFIG.COLORS.fillWhite, opacity: (hover) ? 1.0 : 0.2 } }>{ item.label }</a>
              { showEyes ? <Eyes className="full-screen-menu__eyes"
                                 height={ (isMobile) ? 36 : 80 }
                                 width={ (isMobile) ? 45 : 92 }/> : null }
          </li>
        )
    });

    return (
      <div className="full-screen-menu">
          <div className="full-screen-menu__content">
              <div className="full-screen-menu__header">
                  <button className="full-screen-menu__close-btn" onClick={ closeBtnClick }>
                      <Close/>
                  </button>

                  <WordMark color={ MAIN_CONFIG.COLORS.fillWhite }/>

                  <a href={ MAIN_CONFIG.URLS.LOGIN } className="full-screen-menu__sign-in CTA color__white">Sign In</a>
              </div>

              <ul className="full-screen-menu__list">{ renderMenuItems() }</ul>
          </div>
      </div>
    );
};
