import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "40", height = "40", ariaLabel = "Paper Clip", ariaLabelDescription = "Paper Clip" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 40 40">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <defs>
                <filter id="583tifp30a">
                    <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.000000 0 0 0 0 0.501961 0 0 0 0 0.403922 0 0 0 1.000000 0" />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g transform="translate(-530 -611) translate(108 168) translate(422 443)">
                            <g>
                                <path fill={color} d="M24.307 26.275c-.228.137-.525.064-.661-.164L10.18 3.754C8.86 1.56 6.005.774 3.818 2.004c-1.114.626-1.908 1.653-2.235 2.89-.327 1.239-.144 2.523.515 3.618l14.341 23.736c.469.777 1.213 1.327 2.097 1.546.883.22 1.798.082 2.575-.386.777-.468 1.326-1.212 1.545-2.095.22-.884.082-1.798-.386-2.576L10.093 8.52c-.318-.528-.846-.9-1.45-1.02-.609-.12-1.219.017-1.72.387-.893.659-1.13 1.943-.539 2.923l9.351 15.527c.067.11.086.24.055.365-.03.124-.108.23-.219.296-.11.067-.239.086-.364.055-.125-.03-.23-.109-.297-.219L5.572 11.328c-.884-1.468-.469-3.406.926-4.32.72-.471 1.58-.626 2.422-.434.842.192 1.55.703 1.995 1.443l12.13 20.14c1.233 2.048.64 4.742-1.325 6.006-1.001.645-2.191.851-3.353.58-1.161-.27-2.139-.98-2.752-1.998L1.273 9.01C.483 7.696.26 6.153.648 4.665c.388-1.487 1.336-2.724 2.668-3.484C4.172.693 5.113.46 6.048.46c1.953 0 3.883 1.01 4.966 2.81l13.457 22.343c.137.227.063.524-.164.661m.56-.9L11.41 3.033C9.68.16 5.946-.85 3.088.78 1.646 1.602.62 2.94.202 4.549c-.42 1.609-.18 3.277.676 4.699l14.341 23.735c.679 1.127 1.76 1.912 3.044 2.21.368.086.739.128 1.107.128.914 0 1.81-.262 2.6-.77 2.172-1.397 2.831-4.373 1.47-6.632L11.343 7.829l-.033-.052c-.51-.846-1.321-1.433-2.286-1.653-.965-.221-1.951-.043-2.778.498-1.6 1.048-2.079 3.266-1.068 4.944l9.338 15.505c.268.446.85.59 1.295.322.215-.13.368-.336.428-.581.061-.245.023-.498-.107-.714L6.78 10.571c-.47-.778-.286-1.795.417-2.314.396-.292.878-.4 1.356-.305.477.095.893.388 1.145.805l12.177 20.218c.404.672.523 1.463.334 2.226-.19.764-.664 1.407-1.336 1.812-.672.404-1.463.523-2.226.333-.764-.19-1.407-.664-1.812-1.337L2.493 8.273c-.594-.986-.758-2.144-.463-3.26.294-1.116 1.01-2.042 2.015-2.607 1.972-1.11 4.547-.397 5.74 1.586L23.25 26.349c.268.445.85.59 1.295.321.445-.268.59-.849.321-1.294" transform="translate(7.385 2.462)" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
