import dynamic from 'next/dynamic'
import React, { useState, useRef, useEffect } from 'react';
import BrandMark from '../../../Components/SVG/BrandMark';
import Marketplace from '../../../Components/SVG/Marketplace';
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import { reduxPage } from '../../../Core/store'
import { MAIN_CONFIG, iterationModalCloseMsg } from '../../../../config/main';
import '../DonorHeader/donor-header.scss';
import AccountMenu from '../../../Components/AccountMenu/AccountMenu';
import Field from '../../../Components/Field/Field';
import { useRouter } from 'next/router';
import cookies from 'next-cookies';
import Loader from '../../../Components/Loader/Loader';
import { alretMsg, clearShowBackButtonSessionStorage, handleKeyDown } from "../../../Utility/ApplicationFormUtility"


const ApplicantLoginHeader = ({ data, currentUser, activeHeaderNav, userContext, pageType, pageData, accountData, setAccountLoaderVisible, isOpenSweetAlert = false, setIsOpenSweetAlert = () => { } , setLoading = ()=> {}}) => {

    const router = useRouter();
    let route = router.query

    let selectedAccount = accountData?.availableAccounts ? accountData?.availableAccounts.filter(account => (account.url_name__c === route.indexId || account.sfid === route.indexId || account.sfid === cookies('currentAccount').currentAccount && !route?.indexId)) : [];


    const [acount, setAccount] = useState({
        label: (selectedAccount.length > 0) ? selectedAccount[0].name : 'All Programs',
        value: (selectedAccount.length > 0) ? selectedAccount[0].sfid : ''
    })
    const [dataLoad, setDataLoad] = useState(false);


    const { user } = accountData;
    const LOADING_SCREEN_SECONDS = 2;

    const isModalOpen = localStorage.getItem("isModalOpen")

    const { sectionVisibilityOptions } = data;
    // const API = new KaleidoscopeAPI({});

       const handleMenuUrlClick = (str) => {
        var isRediractLocal = localStorage.getItem("isRedireactSave") ? JSON.parse(localStorage.getItem("isRedireactSave")) : false
        localStorage.setItem('activeApplicantHeader', str)
           let url = `/applicant/${str === "explore" ? "" : str}`
        if (pageType == MAIN_CONFIG.PAGE_TYPES.APPLICANT.APPLY && isRediractLocal 
            || pageType == MAIN_CONFIG.PAGE_TYPES.APPLICANT.RESUME && route.action === "submit" 
            // || route.applicationstepid
            ) {
            alretMsg("", setIsOpenSweetAlert, setDataLoad, isModalOpen, pageType, () => { window.location.href = url })
            localStorage.removeItem('hasAccessCode')
            localStorage.removeItem('accessCode')
            clearShowBackButtonSessionStorage()
        } else {
            router.push(`/applicant/${str === "explore" ? "" : str}`, undefined, { shallow: true })
        }
    }
    
    const renderNavigation = () => {
        const UpdatedSectionVisibilityOptions = sectionVisibilityOptions ? sectionVisibilityOptions : {is_gradebook_section_visible : false, is_donor_information_section_visible : false, is_disbursement_section_visible : false, is_document_library_section_visible : false}
        const { is_gradebook_section_visible , is_donor_information_section_visible , is_disbursement_section_visible , is_document_library_section_visible } = UpdatedSectionVisibilityOptions 
        const navLabelVisibility = {
            Gradebook: is_gradebook_section_visible || false,
            Donors: is_donor_information_section_visible || false,
            Disbursements: is_disbursement_section_visible || false,
            Resources: is_document_library_section_visible || false,
            Applications: true,
            Explore: true
        };
        return MAIN_CONFIG.NAVLABElS.map((navLabel, index) => {
            let str = navLabel.toLowerCase()
            const isActive = navLabel.toLowerCase() === activeHeaderNav.toLowerCase();
            if (navLabelVisibility[navLabel]) {
                return (
                    <li ariaLabel={navLabel} tabIndex={0} key={`main-nav-item-${index}`} role='nav' onKeyDown={(e)=>handleKeyDown(e,()=>handleMenuUrlClick(str))} className={`CTAWhite_Applicant_header ${isActive && "CTAWhite active-current-page"}`} onClick={() => handleMenuUrlClick(str)}>
                        {navLabel}
                    </li>
                )
            } else {
                return null
            }
        });
    }

    const renderActions = () => {
        return (
            <>
                <AccountMenu
                    userContext={userContext}
                    user={currentUser}
                    pageData={pageData}
                    setLoading={setLoading}
                />
            </>
        )
    }



    return (
        <div id='donor-header__inner' className='application_login_header_main'>
            <Loader loading={dataLoad} />
            <div id='header_left_applicant'>
                <a href="/"><BrandMark id='donor-header__brand-mark' ariaLabel="Kaleidoscope Logo" ariaLabelDescription="" /></a>
                <nav aria-label="Main Navigation" role="navigation">
                    <ul className='header__menu'>
                    {renderNavigation()}
                    </ul>
                </nav>
            </div>
            <div id='donor-header__right'>
                {/* <div className="main-button-div">
                    {renderAccountSelector()}
                    <div className="marketplace_button">
                        <button onClick={handleMenuUrlClick} className={`CTAWhite_Applicant_header ${activeHeaderNav === 'marketplace' ? "CTAWhite active-current-page" : ''}`}>Marketplace</button>
                    </div>
                </div> */}
                <div className="applicant-actions">
                    {renderActions()}
                </div>
            </div>
            {isOpenSweetAlert}

        </div>
    );
}

export default reduxPage(ApplicantLoginHeader)