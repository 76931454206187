import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';


export default ({ id, className, color = MAIN_CONFIG.COLORS.fillDarkGrey, width = "24.486", height = "24" }) => {
    return (
        <svg id={id} className={className} data-name="Elements / System / Pencil" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24.486 24">
            <title>Edit</title>
            <desc>Edit</desc>
            <path id="Icon_Edit" data-name="Icon / Edit" d="M0,23.478v0l.238-.691L2.42,16.24l.023-.079L2.5,16.1,15.079,3.524l3-3a1.768,1.768,0,0,1,.9-.478A2.267,2.267,0,0,1,19.437,0H19.5a2.958,2.958,0,0,1,.545.068A4.427,4.427,0,0,1,22.181,1.3a4.474,4.474,0,0,1,1.239,2.148,2.092,2.092,0,0,1-.454,1.966L7.386,20.978l-.068.068-.079.022L.693,23.251,0,23.478Zm1.727-2.886-.58,1.739,1.75-.58a4.442,4.442,0,0,0-.523-.636,4.132,4.132,0,0,0-.648-.523h0Zm2.03-4.208q-.056,0-.109,0a1.286,1.286,0,0,0-.545.148l-.01.005-.012.006v.011L1.966,19.876a4.878,4.878,0,0,1,.921.716,5.055,5.055,0,0,1,.716.92L6.932,20.4h.012l.011-.023A1.752,1.752,0,0,0,7,19.092a3.856,3.856,0,0,0-.989-1.614,3.889,3.889,0,0,0-1.624-1A2.3,2.3,0,0,0,3.757,16.384ZM16.267,3.892a1.253,1.253,0,0,0-.756.222L3.966,15.672a3.225,3.225,0,0,1,.625.113,4.515,4.515,0,0,1,1.931,1.17A4.628,4.628,0,0,1,7.7,18.9a3.265,3.265,0,0,1,.113.614L19.375,7.967a1.523,1.523,0,0,0,.148-1.3A3.882,3.882,0,0,0,18.5,4.978a3.8,3.8,0,0,0-1.682-1.011,2.306,2.306,0,0,0-.4-.068C16.369,3.895,16.317,3.892,16.267,3.892ZM19.442.723c-.046,0-.091,0-.136.005a1.45,1.45,0,0,0-.17.023,1.063,1.063,0,0,0-.545.284L16.443,3.182a3.079,3.079,0,0,1,.557.08,4.54,4.54,0,0,1,2.022,1.2A4.545,4.545,0,0,1,20.227,6.49a3.087,3.087,0,0,1,.091.545L22.454,4.9a1.382,1.382,0,0,0,.262-1.307A3.8,3.8,0,0,0,21.67,1.808,3.761,3.761,0,0,0,19.886.774,2.054,2.054,0,0,0,19.442.723Z" transform="translate(1 0)" fill={color} />
        </svg>
    );
}
