import React from 'react';
import dynamic from "next/dynamic";

const ActionCableModule = dynamic(import('react-actioncable-provider').then(module => {
  const {ActionCable} = module
  return ActionCable
}), { ssr: false });

const Cable = ({ conversations = [], handleReceivedMessage }) => {
  return (
    <div>
      {conversations.map(conversation => {
        return (
          <ActionCableModule
            key={Math.random()}  
            channel={{ channel: 'MessagesChannel', conversation: conversation.id }}
            onReceived={handleReceivedMessage}
          />
        )
      })}
    </div>
  )
}

export default Cable;