import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "200", height = "200" }) => {
    return (

        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 200">
            <title>Promote</title>
            <desc>Promote</desc>
            <defs>
                <clipPath id="clip-Promote">
                    <rect width="200" height="200" />
                </clipPath>
            </defs>
            <g id="Promote" clipPath="url(#clip-Promote)">
                <g id="noun_loudspeaker_3408588" transform="translate(7.4 18.7)">
                    <g id="Group_313" data-name="Group 313" transform="translate(2.6 15.3)">
                        <path id="Path_7797" data-name="Path 7797" d="M85.217,32.9a6.168,6.168,0,0,0-8.532,8.911,20.605,20.605,0,0,1,0,29.576A6.237,6.237,0,0,0,76.5,80.1a5.881,5.881,0,0,0,4.55,1.9,6.68,6.68,0,0,0,4.361-1.706,32.673,32.673,0,0,0,10.048-23.7A33.619,33.619,0,0,0,85.217,32.9Z" transform="translate(62.359 -0.972)" fill={color} />
                        <path id="Path_7798" data-name="Path 7798" d="M92.566,23.544a6.18,6.18,0,1,0-8.152,9.29,42.829,42.829,0,0,1,0,64.082,6.258,6.258,0,0,0-.569,8.721,6.056,6.056,0,0,0,4.55,2.086,6.625,6.625,0,0,0,4.171-1.517,55.308,55.308,0,0,0,18.391-41.331A54.348,54.348,0,0,0,92.566,23.544Z" transform="translate(69.04 -9.325)" fill={color} />
                        <path id="Path_7799" data-name="Path 7799" d="M105.378,15.3c-5.509,0-11.019,3.228-15.578,9.873L24.827,45.679A11.319,11.319,0,0,0,17.8,42.831c-10.449,0-15.2,14.43-15.2,28.1,0,13.481,4.749,28.1,15.2,28.1a10,10,0,0,0,7.029-2.848l8.929,2.848,7.409,42.91a6.119,6.119,0,0,0,6.079,5.126H71.752a5.765,5.765,0,0,0,4.749-2.278,6.092,6.092,0,0,0,1.33-5.126l-4.939-28.67L89.8,116.311c4.559,6.456,10.069,9.873,15.578,9.873,13.3,0,26.6-18.987,26.6-55.632S118.676,15.3,105.378,15.3ZM64.343,134.728H52.374L46.865,103.02l12.729,3.987ZM24.637,83.083a6.753,6.753,0,0,0-1.9-.38,6.578,6.578,0,0,0-5.129,2.658c-1.33-2.468-2.85-7.4-2.85-14.62,0-7.025,1.52-12.152,2.85-14.43a5.781,5.781,0,0,0,6.839,2.278L82.581,40.173c-2.47,8.164-3.99,18.417-3.99,30.569s1.52,22.4,3.99,30.569Zm80.741,30.949c-4.749,0-14.248-15.19-14.248-43.1s9.309-43.1,14.248-43.1,14.248,15.19,14.248,43.1S110.317,114.032,105.378,114.032Z" transform="translate(-2.6 -15.3)" fill={color} />
                        <ellipse id="Ellipse_12" data-name="Ellipse 12" cx="6.257" cy="12.892" rx="6.257" ry="12.892" transform="translate(96.464 42.736)" fill={color} />
                    </g>
                </g>
            </g>
        </svg>
    );
};
