import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ width = 40, height = 40, id, className, color = MAIN_CONFIG.COLORS.fillGreen, ariaLabel = "Paper", ariaLabelDescription = "Paper" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} id={id} className={className} viewBox="0 0 40 40">
        <title>{ariaLabel}</title>
        <desc>{ariaLabelDescription}</desc>
        <defs>
            <filter id="b8y3r9sata">
                <feColorMatrix in="SourceGraphic"
                    values="0 0 0 0 0.000000 0 0 0 0 0.501961 0 0 0 0 0.403922 0 0 0 1.000000 0" />
            </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
            <g>
                <g transform="translate(-106 -1249) translate(106 1249)">
                    <g fill={color}>
                        <path
                            d="M.462 34.81V.462H20.52V7.92c0 .128.104.231.231.231h7.428V34.81H.462zm28.11-27.115L20.927.08l-.01-.01-.001-.001-.003-.002c-.02-.019-.042-.032-.065-.044l-.022-.007c-.02-.007-.039-.01-.06-.012-.004 0-.009-.003-.014-.003H.231C.103 0 0 .103 0 .23v34.81c0 .128.103.231.23.231h28.18c.127 0 .23-.103.23-.23V7.858c0-.061-.024-.12-.067-.164z"
                            transform="translate(5.538 2.462)" />
                        <path
                            d="M8.501 14.964h16.225v-2.067H8.5v2.067zm16.455-2.529H8.271c-.128 0-.231.104-.231.231v2.529c0 .127.103.23.23.23h16.686c.128 0 .231-.103.231-.23v-2.529c0-.127-.103-.23-.23-.23zM4.232 12.745c-.653 0-1.185.532-1.185 1.185 0 .654.532 1.186 1.185 1.186.654 0 1.186-.532 1.186-1.186 0-.653-.532-1.185-1.186-1.185M8.501 21.93h16.225v-2.066H8.5v2.066zm16.455-2.528H8.271c-.128 0-.231.104-.231.231v2.528c0 .127.103.23.23.23h16.686c.128 0 .231-.103.231-.23v-2.528c0-.127-.103-.23-.23-.23zM4.232 19.711c-.653 0-1.185.532-1.185 1.186 0 .654.532 1.186 1.185 1.186.654 0 1.186-.532 1.186-1.186 0-.654-.532-1.186-1.186-1.186M8.501 28.897h16.225V26.83H8.5v2.067zm16.455-2.528H8.271c-.128 0-.231.103-.231.23v2.528c0 .128.103.231.23.231h16.686c.128 0 .231-.103.231-.23v-2.529c0-.127-.103-.23-.23-.23zM4.232 28.588c-.399 0-.724-.325-.724-.724 0-.4.325-.725.724-.725.4 0 .725.325.725.725 0 .399-.325.724-.725.724m0-1.91c-.653 0-1.185.532-1.185 1.186 0 .653.532 1.185 1.185 1.185.654 0 1.186-.532 1.186-1.185 0-.654-.532-1.186-1.186-1.186M9.545 5.709L19.097 5.681 19.096 5.22 9.544 5.248z"
                            transform="translate(5.538 2.462)" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
