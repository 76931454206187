import dynamic from 'next/dynamic'
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import BrandMark from '../../../Components/SVG/BrandMark';
import Notifications from '../../../Components/SVG/Notifications';
import AccountMenu from '../../../Components/AccountMenu/AccountMenu';
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import { API_CONFIG } from '../../../../config/api';
import { setConversationsData } from '../../../hooks/ConversationAction';
import NotificationList from '../../../Components/NotificationList/NotificationList';
import ArrowDown from '../../../Components/SVG/ArrowDown';
import AirPlane from '../../../Components/SVG/AirPlane';
import Chat from '../../../Components/SVG/Chat';
import { toast } from 'react-toastify';
import { reduxPage } from '../../../Core/store'
import { useRouter } from 'next/router';
import { applicationIsLoading } from '../../../Core/applicationIsLoading'
import cookies from 'next-cookies';

import { MAIN_CONFIG } from '../../../../config/main';
import './donor-header.scss';
import { set } from 'lodash';
import { handleKeyDown } from '../../../Utility/ApplicationFormUtility';

const ChatList = dynamic(() => import('../../../Components/ChatList/ChatList'))


const { USER_CONTEXT, PAGE_TYPES } = MAIN_CONFIG;

const ActionCableModule = dynamic(import('react-actioncable-provider').then(module => {
    const { ActionCable } = module
    return ActionCable
}), { ssr: false });

const DonorHeader = ({ data, currentUser, currentPath, setAccountSwitcherVisible, notifications, activeHeaderNav, userContext, pageType, setRoleSwitcherVisible, herokuRole, setModalState = () => { }, pageData }) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const { branding, currentAccount, availableAccounts, user, token } = data;
    const selectedClass = 'CTAWhite active-current-page';
    const userConversations = useSelector(state => state.conversations)
    const [conversations, setConversations] = useState(userConversations);
    const [notificationsData, setNotifications] = useState(notifications);
    const [showNotifications, setShowNotifications] = useState(false);

    const unreadNotifications = notificationsData ? !!notificationsData.find(item => item.view_status__c === 'Not Viewed') : [];
    const [showChatBox, setShowChatBox] = useState(false);
    const unreadMessages = conversations && conversations.length > 0 && conversations.filter(conversation => conversation.messages.filter(message => message.user_id !== currentUser.id && !message.read_at).length > 0).length > 0
    const API = new KaleidoscopeAPI({});

    useEffect(() => {
        setConversations(userConversations)
    }, [userConversations])

    useEffect(() => {
        (async () => {
            // await fetchConversations()
        })();
    }, []);

    const fetchConversations = async () => {
        const response = await API.fetchConversations({ 'token': token })
        if (response) {
            setConversations(response)
            dispatch(setConversationsData(response))
        }
    }

    const getNavData = () => {
        switch (userContext) {      
            case USER_CONTEXT.DONOR:
            case USER_CONTEXT.SUPERDONOR:
                return {
                    navString: branding ? branding.primary_tabs__c : 'Overview',
                    menuMap: API_CONFIG.NAV_MENU_MAP.DONOR
                };
            default:
                return {
                    navString: 'Dashboard',
                    menuMap: API_CONFIG.NAV_MENU_MAP.REVIEWER
                }
        }
    }

    const notificationsTrigger = useRef()
    const messageTrigger = useRef()

    /**
     * Updates all unread notification status.
     * Set notification window visibility
     */
    const updateNotificationStatus = async () => {
        setShowNotifications(!showNotifications)
        setShowChatBox(false)
        if (unreadNotifications) {
            const touchResponse = await API.touchAllNotifications({ token })
            if (touchResponse.success) {
                const notificationResponse = await API.fetchNotifications({ token })
                setNotifications(notificationResponse.notifications)
            }
        }
    }

    const handleReceivedConversation = (response) => {
        const { conversation } = response;
        if (conversation.receiver.id === currentUser.id || conversation.sender.id === currentUser.id) {
            let newState = Object.assign([], conversations);
            const hasConv = newState.find(a => a.id === conversation.id)
            if (!hasConv) {
                newState.push(conversation)
                setConversations(newState)
                dispatch(setConversationsData(newState))
            }
        }
    }
    const handleClickHelp = () => {
        window.open('https://help.mykaleidoscope.com/collection/119-administrator-help-center', '_blank')
     }

    const reorderItems = (item) => {
      const desiredOrder = [
        "Overview",
        "Programs",
        "Applications",
        "Connect",
        "Reports",
        "Disbursements",
        "Donations",
        "People",
        "Admin",
      ];
      
      // Create a new array with elements sorted according to the desired order
      const sortedItems = item?.sort(
        (a, b) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b)
      );
      return sortedItems;
    };

    /**
     * Renders the navigation based on the user context.
     * The API returns a semi-colon separated string with the nav
     * items, the mapping is hard-coded for now.
     * @returns {object[]}
     */
    const renderNavigation = () => {
        if (pageType === PAGE_TYPES.DONOR.NEW_SCHOLARSHIP) {
            return [<li key='main-nav-item-0' className='H6DesktopWhite'>New&nbsp;Award Program</li>]
        }

        if (pageType === PAGE_TYPES.DONOR.EDIT_SCHOLARSHIP) {
            return [<li key='main-nav-item-0' className='H6DesktopWhite'>Edit&nbsp;Award Program</li>]
        }
        if (pageType === PAGE_TYPES.DONOR.PREVIEW_SCHOLARSHIP) {
            return null
        }
        const navData = getNavData();
        const navLabels = navData.navString.split(';');
        let reorderNavLabels = reorderItems(navLabels)
        return reorderNavLabels.map((navLabel, index) => {
            let urlArg = navData.menuMap.find(item => item.apiLabel === navLabel)
            let url = '/'
            if (urlArg) {
                url = urlArg.url
            }
            const isActive = navLabel.toLowerCase() === activeHeaderNav.toLowerCase();
            if(navLabel === 'Connect' && currentAccount?.elements_communication_tab__c){
                url = MAIN_CONFIG.ELEMENTS_URLS.DONOR.CONNECT(currentAccount.sfid)
            }
            if(navLabel === "Reports") {
                url = MAIN_CONFIG.ELEMENTS_URLS.DONOR.REPORT_DASHBOARD(currentAccount.sfid)
            }
            const handleMenuUrlClick = () => {
                window.location.href = url
                sessionStorage.removeItem('isBackButtonClick');
            }


            return (
                <li tabIndex={0} onKeyDown={(e)=>handleKeyDown(e,handleMenuUrlClick)} key={`main-nav-item-${index}`} className={`CTAWhite ${isActive ? selectedClass : ''} ${navLabel === 'Overview' && 'Dashboard'}`} onClick={handleMenuUrlClick}>
                    {navLabel === 'Overview' ? "Dashboard" : navLabel}
                </li>
            )
        });
    }

    /**
     * Fetch SSO Token and redirect over other application.
     * @returns {JSX.Element}
     */
    const handleSSO = async (e) => {
        await dispatch(applicationIsLoading(true))
        const response = await API.fetchSsoCode({ token })
        await dispatch(applicationIsLoading(false))
        if (response.secure_login_id) {
            // router.push(`${process.env.MYKALEIDOSCOPE_BASE_URL}authenticate/${response.secure_login_id}`, undefined, { shallow: true })
        } else {
            toast.error('Something went wrong, please try again later.')
        }
    }

    const handleClickChatBox = () => {
        var account = cookies('context').currentAccount
        if (!account) {
            document.cookie = `currentAccount=${currentAccount.sfid};path=/`;
        }
        setShowChatBox(!showChatBox)
        setShowNotifications(false)
    }

    /**
     * Render the action section of the header.
     * @returns {JSX.Element}
     */
    const renderActionsold = () => {
        return (
            <>
                <ActionCableModule
                    channel={{ channel: 'ConversationsChannel' }}
                    onReceived={handleReceivedConversation}
                />
                {pageData && pageData.account === false ?
                    ''
                    :
                    <>
                        <button
                            id={'donor-header__notifications-icon'}
                            className='icon'
                            onClick={() => updateNotificationStatus()}
                            ref={notificationsTrigger}
                        >
                            <Notifications showIndicator={unreadNotifications} ariaLabel="Notification Icon" ariaLabelDescription="You can check all the notification item here" />
                            <ArrowDown
                                id='donor-header__notifications-arrow'
                                className={showNotifications ? 'open' : ''}
                                height={32}
                                width={32}
                            />
                        </button>

                        {branding.enable_messaging__c &&
                            <button
                                id={'donor-header__chat-icon'}
                                className='icon'
                                ref={messageTrigger}
                                onClick={handleClickChatBox}
                            >
                                <Chat showIndicator={unreadMessages} ariaLabel="Chat Icon" ariaLabelDescription="Click here to chat with other users" />
                                <ArrowDown
                                    id='donor-header__chat-arrow'
                                    className={showChatBox ? 'open' : ''}
                                    height={32}
                                    width={32}
                                />
                            </button>
                        }
                    </>
                }
                {/*
                    <button
                        id={'donor-header__sso-icon'}
                        className='icon'
                        onClick={(e) => handleSSO(e)}
                    >
                        <AirPlane />
                        <ArrowDown
                            id='donor-header__chat-arrow'
                            className={showChatBox ? 'open' : ''}
                            height={32}
                            width={32}
                        />
                    </button>
                */}

                <ChatList
                    id='donor-header__chat-menu'
                    className={showChatBox ? 'open' : ''}
                    showChatBox={showChatBox}
                    setModalState={setModalState}
                    token={token}
                    stateHandler={setShowChatBox}
                    trigger={messageTrigger}
                    currentUser={currentUser}
                />

                <NotificationList
                    id='donor-header__notification-menu'
                    items={notificationsData}
                    className={showNotifications ? 'open' : ''}
                    trigger={notificationsTrigger}
                    stateHandler={setShowNotifications}
                    notification={true}
                />
                <AccountMenu
                    currentAccount={currentAccount}
                    availableAccounts={availableAccounts}
                    setAccountSwitcherVisible={setAccountSwitcherVisible}
                    setRoleSwitcherVisible={setRoleSwitcherVisible}
                    userContext={userContext}
                    setShowChatBox={setShowChatBox}
                    user={user}
                    herokuRole={herokuRole}
                    pageData = {pageData}
                />
            </>
        )

    }
    /**
     * Render the action section of the header.
     * @returns {JSX.Element}
     */
    const renderActions = () => {
        return (
            <>
                <AccountMenu
                    currentAccount={currentAccount}
                    availableAccounts={availableAccounts}
                    setAccountSwitcherVisible={setAccountSwitcherVisible}
                    setRoleSwitcherVisible={setRoleSwitcherVisible}
                    userContext={userContext}
                    setShowChatBox={setShowChatBox}
                    user={user}
                    herokuRole={herokuRole}
                    pageData = {pageData}
                />
            </>
        )

    }



    return (
        <header id='donor-header__inner'>
            <div id='donor-header__left'>
                <a href="/"><BrandMark id='donor-header__brand-mark' ariaLabel="Kaleidoscope Logo" ariaLabelDescription="" /></a>
                <nav aria-label="Header Top Navigation"  aria-labelledby='Header Top Navigation' role=''>
                    <ul className='header__menu'>
                        {renderNavigation()}
                    </ul>
                </nav>
            </div>
            <div id='donor-header__right'>
                { userContext == 'DONOR' && <button className='help-icon' tabIndex={0} onClick={handleClickHelp} aria-label="help"><img className='help-icon' src="/static/images/help.png" alt="help button" title='Help' /></button>}
                {renderActions()}
            </div>
        </header>
    );
}

// handle PropTypes
DonorHeader.propTypes = {
    data: PropTypes.object,
    currentUser: PropTypes.object,
    currentPath: PropTypes.string,
    setAccountSwitcherVisible: PropTypes.func,
    notifications: PropTypes.array,
    activeHeaderNav: PropTypes.string,
    userContext: PropTypes.string,
    pageType: PropTypes.string,
    setRoleSwitcherVisible: PropTypes.func,
    herokuRole: PropTypes.string,
    setModalState: PropTypes.func,
    pageData: PropTypes.object
};
export default reduxPage(DonorHeader)