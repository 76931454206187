import React from 'react';
import './navigation-list-item.scss';
import Globe from '../SVG/Globe';
import Calendar from '../SVG/Calendar';
import { DateTime } from 'luxon';
import TimeAgo from 'react-timeago';
import RightCheck from '../../Components/SVG/RightCheck';
import FormatDate from '../../Utility/FormatDate';
import Pencil from '../../Components/SVG/Pencil';
import FieldCheckbox from '../../Components/SVG/FieldCheckbox';


export default ({ key, item, showSelector = false, handleAction = () => { }, handleEditAction = () => { }, type = "donor", notificationRedirection = () => { } }) => {
	// TODO: Update icon based on item type

	return (
		<li key={key} className='notification-list__item' onClick={() => notificationRedirection(item)}>
			<div className='notification-list__item-left task-list-column-1'>
				<div className='notification-list__item-indicator'>
					{item.unread && <div className='notify-dot'> </div>}
				</div>
				<Globe
					className='notification-list__item-icon'
					ariaLabel="Task"
					ariaLabelDescription=""
				/>
				<div className='notification-list__item-copy'>
					<div className="title-and-mark-as-complete-container">
						<div className='BodyDefaultBoldBlack'>
							{item.title}
						</div>

					</div>
					<div className='BodySmallRegularGrey'>{item.subtitle}</div>
				</div>
			</div>

			{
				showSelector && item.priority && (
					<div className='task-notification-due-date task-list-column-4'>
						<div className="LabelGrey"></div>
						<div className="scholarship-card__date">
							<span className="BodyDefaultBoldBlack">{item.priority}</span>
						</div>
					</div>
				)
			}
			{
				showSelector ?
					type === "donor" &&

					<div className='task-notification-due-date task-list-column-2'>

						<div className="LabelGrey"></div>
						{
							item.date && (
								<div className="scholarship-card__date">
									<span className="BodyDefaultBoldBlack">{FormatDate(item.date, 'MM/dd/yyyy')}</span> <Calendar />
								</div>

							)
						}
					</div>
					:
					item.date && (
						<div className='LabelGrey notification-list__item-timestamp task-list-column-2'>
							<div className="task-notification-date-display">
								<TimeAgo date={DateTime.fromISO(item.date).toMillis()} />
							</div>
						</div>
					)
			}
			{
				item.create_date && (
					<div className='task-notification-due-date task-list-column-4'>
						<div className="LabelGrey"></div>
						<div className="scholarship-card__date">
							<span className="BodyDefaultBoldBlack">{item.create_date}</span>
							<Calendar
								ariaLabel="Create Date"
								ariaLabelDescription=""
							/>
						</div>
					</div>
				)
			}
			{
				showSelector &&
				<React.Fragment>
					<div className="task-notification-due-date task-list-column-3">
						<div className="LabelGrey"></div>
						{
							item.due_date && (
								<div className="scholarship-card__date">
									<span className="BodyDefaultBoldBlack">{FormatDate(item.due_date, 'MM/dd/yyyy')}</span>
									<Calendar
										ariaLabel="Due Date"
										ariaLabelDescription=""
									/>
								</div>
							)
						}
						{
							item.activitydate && (
								<div className="scholarship-card__date">
									<span className="BodyDefaultBoldBlack">{FormatDate(item.activitydate, 'MM/dd/yyyy')}</span>
									<Calendar
										ariaLabel="Due Date"
										ariaLabelDescription=""
									/>
								</div>
							)
						}
					</div>
					<div className="task-notification-mark-as-complete-display task-list-column-4">
						{item.status === 'Completed' ?

							<div className="checkbox-complete">
								<label className="option-checkbox">
									<span className="checkbox__input">
										<input
											className='table__checkbox'
											type='checkbox'
											name="status"
											checked={true}
											value={item.id}
											onChange={handleAction}
										/>
										<span className="checkbox__control">
											<FieldCheckbox />
										</span>
									</span>
								</label>
								<div className="LabelGrey">Completed</div>
							</div>
							:
							<div className="checkbox-incomplete">
								<label className="taskincomplete">
									<input
										className='table__checkbox'
										type='checkbox'
										name="status"
										checked={false}
										value={item.id}
										onChange={handleAction}
									/>
									<span></span>
								</label>
								<div className="LabelGrey">Click to Complete</div>
							</div>
						}
					</div>
					<div className="task-notification-mark-as-complete-display task-list-column-5">
						<div className="LabelGrey edit-my-task-icon" onClick={() => handleEditAction(item)}>
							<Pencil
								ariaLabel="Edit"
								ariaLabelDescription=""
							/>
						</div>
					</div>


				</React.Fragment>
			}
		</li>
	);
}
