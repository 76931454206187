import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillBlue, width = 48, height = 48, ariaLabel = "Plus", ariaLabelDescription = "Plus" }) => {

    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g transform="translate(-677 -2242) translate(164 2242) translate(513)">
                            <circle cx="24" cy="24" r="24" fill={color} />
                            <g fill="#FFF">
                                <path d="M17 5.333L17 15 26.667 15 26.667 17 17 17 17 26.667 15 26.667 15 17 5.333 17 5.333 15 15 15 15 5.333z" transform="translate(8 8)" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};