import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import withRedux from 'next-redux-wrapper'
import rootReducer from './rootReducer';


export const initStore = (initialState = {}) => {
  return createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunkMiddleware)
      // window.devToolsExtension ? window.devToolsExtension() : f => f
  )
  )
}

export const reduxPage = (comp) => withRedux(initStore)(comp)


