import React, { useState, useEffect } from 'react';
import './applicant-header.scss';
import WordMark from '../../../Components/SVG/WordMark';
import { useRouter } from 'next/router';

// TODO: Update this with logged-out style once available
export default ({ pageType, isMobile = false, setToggleHamburgerMenu = () => { }, toggleHamburgerMenu = false }) => {
  const router = useRouter();

  const handleClick = (e) => {
    e.preventDefault()
    // document.cookie = 'marketPlace=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    router.push('/', undefined, { shallow: true })
  }

  return (
    <>
      <div id='logged-out-header__inner'>
        <div id='logged-out-header__left'>
        </div>
        <div id="logged-out-header__middle">
          <a className="logo-pointer" onClick={(e) => handleClick(e)}><WordMark id='logged-out-header__brand-mark' /></a>
        </div>
        <nav id='logged-out-header__right' aria-label="Navigation">
          {isMobile &&
            <div className="hamburger-menu-icon-container" onClick={() => setToggleHamburgerMenu(!toggleHamburgerMenu)}>
              <div className="hamburger-menu-icon-container__icon"></div>
              <div className="hamburger-menu-icon-container__icon"></div>
            </div>
          }
        </nav>
      </div>
    </>
  );
}
