import React from 'react';
import PropTypes from 'prop-types';

const CheckBox = ({ id, className, width, height, title }) => {

    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20.95 20.95">
            <title>{title}</title>
            <desc>Check Box</desc>
            <g id={`Button_Checkbox_Selected-${id}`} data-name="Button / Checkbox / Selected" transform="translate(0.5 0.5)">
                {/* <rect id="Rectangle_Copy_5" data-name="Rectangle Copy 5" width="19.95" height="19.95" rx="3.8" fill="none" stroke={color} stroke-miterlimit="10" stroke-width="1" /> */}
                {/* <path id="Path_31_Copy" data-name="Path 31 Copy" d="M0,2.438,3.557,6,11,0" transform="translate(5 7)" fill="none" stroke={color} stroke-miterlimit="10" stroke-width="2" /> */}
            </g>
        </svg>
    );
}

export default CheckBox;

CheckBox.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    title: PropTypes.string.isRequired,
};
