import React, { useState, useEffect } from 'react';
import './app-header.scss';
import { MAIN_CONFIG, hideSidebarApplicantList } from '../../../config/main';
import DonorHeader from './DonorHeader/DonorHeader';
import RecommenderHeader from './RecommenderHeader/RecommenderHeader';
import LoggedOutHeader from './LoggedOutHeader/LoggedOutHeader';
import ApplicantHeader from './ApplicantHeader/ApplicantHeader';
import StaffHeader from './StaffHeader/StaffHeader';
import AdminHeader from './AdminHeader/AdminHeader';
import ApplicantLoginHeader from './ApplicantLoginHeader/ApplicantLoginHeader';

const { USER_CONTEXT: { LOGGED_OUT, DONOR, RECOMMENDER, REVIEWER, SUPERDONOR, APPLICANT, NGB, SCHOOLCOUNSELLOR, ENDORSEDUSER, THIRDPARTYCONTRIBUTOR, VERIFIER, TEAMMEMBER, UNIVERSITYSTAFF, HSSTAFF, COLLEGESTAFF, DETROITSF, ADMIN } } = MAIN_CONFIG;

export default ({
  data,
  pageData,
  userContext = MAIN_CONFIG.USER_CONTEXT.LOGGED_OUT,
  currentPath,
  setAccountSwitcherVisible,
  setRoleSwitcherVisible,
  roleData,
  notifications,
  activeHeaderNav,
  pageType,
  marketPlaceData = {},
  setModalState = () => { },
  setShowApplicantOnboarding = () => { },
  isMobile = false,
  setToggleHamburgerMenu,
  toggleHamburgerMenu,
  accountData,
  setAccountLoaderVisible,
  isOpenSweetAlert = false,
  setIsOpenSweetAlert = ()=> {},

  setLoading = ()=> {}
}) => {

  /**
   * Determines which header to load.
   * // TODO: Determine if this is still necessary after seeing all other headers.
   * @returns {JSX.Element}
   */
  const renderHeaderContent = () => {
    switch (userContext) {
      case RECOMMENDER:
      case NGB:
      case SCHOOLCOUNSELLOR:
      case ENDORSEDUSER:
      case THIRDPARTYCONTRIBUTOR:
      case VERIFIER:
        return (
          <RecommenderHeader
            applicant_recommendations={pageData.applicant_recommendations}
            data={data}
            pageData={pageData}
            currentPath={currentPath}
            setRoleSwitcherVisible={setRoleSwitcherVisible}
            herokuRole={roleData}
            activeHeaderNav={activeHeaderNav}
            userContext={userContext}
            pageType={pageType}
            setModalState={setModalState}
            currentUser= {pageData.userInfo}
          />
        )
      case UNIVERSITYSTAFF:
      case HSSTAFF:
      case COLLEGESTAFF:
      case DETROITSF:
        return (
          <StaffHeader
            applicant_recommendations={pageData.applicant_recommendations}
            data={data}
            pageData={pageData}
            currentPath={currentPath}
            setRoleSwitcherVisible={setRoleSwitcherVisible}
            herokuRole={roleData}
            activeHeaderNav={activeHeaderNav}
            userContext={userContext}
            pageType={pageType}
            setModalState={setModalState}
            currentUser= {pageData.userInfo}
          />
        )
      case DONOR:
      case SUPERDONOR:
      case REVIEWER:
        return (
          <DonorHeader
            data={data}
            setModalState={setModalState}
            currentPath={currentPath}
            setAccountSwitcherVisible={setAccountSwitcherVisible}
            setRoleSwitcherVisible={setRoleSwitcherVisible}
            herokuRole={roleData}
            notifications={notifications}
            activeHeaderNav={activeHeaderNav}
            userContext={userContext}
            pageType={pageType}
            pageData={pageData}
            currentUser= {pageData.userInfo}
          />
        );

      case APPLICANT:
      case TEAMMEMBER:
        return (
          <ApplicantLoginHeader 
          data={data}
          setModalState={setModalState}
          currentPath={currentPath}
          setAccountSwitcherVisible={setAccountSwitcherVisible}
          setRoleSwitcherVisible={setRoleSwitcherVisible}
          herokuRole={roleData}
          notifications={notifications}
          activeHeaderNav={activeHeaderNav}
          userContext={userContext}
          pageType={pageType}
          pageData={pageData}
          currentUser= {pageData.userInfo} 
          accountData = {accountData}
          setAccountLoaderVisible = {setAccountLoaderVisible}
          isOpenSweetAlert = {isOpenSweetAlert}
          setIsOpenSweetAlert = {setIsOpenSweetAlert}
          setLoading = {setLoading}

          />
          
        );
      case ADMIN:
        return (
          <AdminHeader pageType={pageType} user= {pageData.userInfo} accountData = {data} setLoading = {setLoading} />
        );

      case LOGGED_OUT:
        return <LoggedOutHeader isMobile={isMobile} hideLogin={marketPlaceData.account && marketPlaceData.account.enable_sso__c }  setShowApplicantOnboarding={setShowApplicantOnboarding} pageType={pageType} isShowSightIn={pageType === "SPONSORSIGNUP" || pageType === "APPLY" || pageType === "PARENTALCONSENT" ? true : false || pageData?.scholarshipDetail?.scholarship && pageData.scholarshipDetail.scholarship?.enable_sso__c}  />

      default:
        return (
          <LoggedOutHeader setShowApplicantOnboarding={setShowApplicantOnboarding} pageType={pageType} isShowSightIn={pageType === "SPONSORSIGNUP" ? true : false} />
        );
    }
  }


  // if (!isMobile && (userContext === APPLICANT || userContext === TEAMMEMBER) && !hideSidebarApplicantList.includes(pageType))
  //   return null;

  if (userContext === LOGGED_OUT && pageType === 'SIGNUP') {
    return null;
  }

  return (
    <>
      {isMobile && userContext === "APPLICANT" ?
        <div id='header' className='logged-out is-mobile-applicant-logged-in'>
          <div id={`header__inner`} className={`header mobile ${userContext}`}>
            <ApplicantHeader pageType={pageType} isMobile={isMobile} setToggleHamburgerMenu={setToggleHamburgerMenu} toggleHamburgerMenu={toggleHamburgerMenu} />
          </div>
        </div>
        :
        <div id='header' className={`${(userContext === LOGGED_OUT || (hideSidebarApplicantList.includes(pageType) && (userContext === "APPLICANT" || userContext === TEAMMEMBER))) ? 'logged-out' : ''} ${userContext}`}>
          <div id={`header__inner`} className={`header ${userContext}`}>
            {renderHeaderContent()}
          </div>
        </div>
      }
    </>
  );
}
