import React from 'react';
import './status-cell-type.scss';

export default ({ value }) => {
    // TODO: Update color of dot with statuses when mapping is available.

    return (
        <span className='status-cell-type'>
            {value ?
             <>
                <span className={`status-cell-type__dot ${value}`}> </span>
                <span className='status-cell-type__text BodySmallMediumBlack'>{value}</span>
            </> : 'N/A'
            }
        </span>
    );
};
