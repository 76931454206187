import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "78", height = "78", iconColor = MAIN_CONFIG.COLORS.fillBlack }) => {
    return (
        <svg id={id} className={className} data-name="Elements / Brand Icon / Folder" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 78 78">
            <title>Folder</title>
            <desc>Folder</desc>
            <path id="Folder" d="M69.408,49.251H5.507a.45.45,0,0,1-.448-.5l.012-.111L.011,1.9A1.687,1.687,0,0,1,.427.571,1.746,1.746,0,0,1,1.746,0H21.672a2.061,2.061,0,0,1,2.037,1.785l.117,1.01H63.037a2.083,2.083,0,0,1,2.038,1.8l.015.134h7.43a1.721,1.721,0,0,1,1.736,1.936L69.844,48.643l.011.11a.45.45,0,0,1-.448.5Zm-58.33-39.3a1.2,1.2,0,0,0-1.143,1.037L6.007,48.351h62.9L73.36,6.571a.852.852,0,0,0-.2-.672.843.843,0,0,0-.637-.27H35.474A1.2,1.2,0,0,0,34.33,6.664l-.3,2.88a.45.45,0,0,1-.448.4Z" transform="translate(1.8 14.4)" fill={color} />
        </svg>
    );
};
