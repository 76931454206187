export const BREAKPOINTS = {
    tiny: 325,
    small: 480,
    medium: 769,
    large: 1024,
    xlarge: 1280,
    xxlarge: 1440,
    full: 1600
};

export const DESKTOP_WIDTH = BREAKPOINTS.medium;
