import ShortAnswer from '../Components/SVG/ShortAnswer';
import PaperClip from '../Components/SVG/PaperClip';
import RadioButton from '../Components/SVG/RadioButton';
import Essay from '../Components/SVG/Essay';
import Number from '../Components/SVG/Number';
import Chat from '../Components/SVG/Chat';
import Phone from '../Components/SVG/Phone';
import Email from '../Components/SVG/Email';
import Calendar from '../Components/SVG/Calendar';
import Date from '../Components/SVG/Date';
import Currency from '../Components/SVG/Currency';
import MultipleChoice from '../Components/SVG/MultipleChoice';
import Pencil from '../Components/SVG/PencilForDropDown';
import CheckBox from '../Components/SVG/CheckBox';
import Video from '../Components/SVG/Video';
import Folder from '../Components/SVG/Folder';
import DropDownList from '../Components/SVG/DropDown';
import Percent from '../Components/SVG/Percent';

import { MAIN_CONFIG } from '../../config/main';


export const getImage = (options) => {
    let image = ''
    switch(options) {


        case 'Text':
            image = <ShortAnswer className='dropdown-icons-with-text' width="21" height="10.5" />
            break;

        case 'Document':
            image = <Folder className='dropdown-icons-with-text' width="21"  height="21"/>
            break;

        case 'Radio Button':
            image = <MultipleChoice className='dropdown-icons-with-text' width="21" height="21" />
            break;

        case 'Picklist':
            image = <Essay className='dropdown-icons-with-text' width="21" height="16.5" />
            break;

        case 'Long Text':
            image = <Essay className='dropdown-icons-with-text' width="21" height="16.5" />
            break;
    
        case 'Number':
            image = <Number className='dropdown-icons-with-text' width="21" height="21" />
            break;

        case 'Output Message':
            image = <Chat className='dropdown-icons-with-text' width="21" height= "21" color = {MAIN_CONFIG.COLORS.fillGreen } />
            break;
    
        case 'Phone':
            image = <Phone className='dropdown-icons-with-text' width="21"  height="21"/>
            break;

        case 'Email':
            image = <Email className='dropdown-icons-with-text' width="18.764" height="21" />
            break;  

        case 'Date':
            image = <Date className='dropdown-icons-with-text' width="21"  height="21"/>
            break;  

        case 'Currency':
            image = <Currency className='dropdown-icons-with-text' width="21" height="21"/>
            break;  
            
        case 'Multiselect Picklist':
            image = <MultipleChoice className='dropdown-icons-with-text' width="21" height="21"/>
            break;

        case 'Signature':
            image = <Pencil className='dropdown-icons-with-text' width="21"  height="21" color = {MAIN_CONFIG.COLORS.fillGreen } />
            break;            

        case 'Checkbox':
            image = <CheckBox className='dropdown-icons-with-text' width="21" height="21" />
            break;

        case 'Date(Month-Year)':
            image = <Date className='dropdown-icons-with-text' width="21" height="21" />
            break;
    
        case 'Video':
            image = <Video className='dropdown-icons-with-text' width="21" />
            break;
    
        case 'Date(Year)':
            image = <Date className='dropdown-icons-with-text' width="21" height="21" />
            break;

        case 'Date(Date-Month-Year)':
            image = <Date className='dropdown-icons-with-text' width="21" height="21" />
            break;            

        case 'Signature With Timestamp':
            image = <Pencil className='dropdown-icons-with-text' width="21" height="21"  color = {MAIN_CONFIG.COLORS.fillGreen }  />
            break;          

        case 'Dropdown':
            image = <DropDownList className='dropdown-icons-with-text' width="21" />
            break;            
    
        case 'Percent':
            image = <Percent className='dropdown-icons-with-text' width="21" />
            break; 

        case 'Dependent Picklist':
            image = <Essay className='dropdown-icons-with-text' width="21" height="16.5" />
            break;

        case 'Hover Dependent Picklist':
            image = <Essay className='dropdown-icons-with-text' width="21" height="16.5" />
            break;

        case 'Answer Dependent Picklist':
            image = <Essay className='dropdown-icons-with-text' width="21" height="16.5" />
            break;

        default:
            image = <Pencil className='dropdown-icons-with-text' width="21" height="21"  color = {MAIN_CONFIG.COLORS.fillGreen } />
            break;
    }
    return image
}