import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "200", height = "200" }) => {
    return (

        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 200">
            <title>Password</title>
            <desc>Password</desc>
            <defs>
                <clipPath id="clip-Password">
                    <rect width="200" height="200" />
                </clipPath>
            </defs>
            <g id="Password" clipPath="url(#clip-Password)">
                <g id="noun_password_4149526" transform="translate(233 249)">
                    <path id="Path_7811" data-name="Path 7811" d="M147.75,74.25H63.375V40.5a11.247,11.247,0,0,1,11.25-11.25h45a11.247,11.247,0,0,1,11.25,11.25V51.75a5.625,5.625,0,0,0,11.25,0V40.5a22.53,22.53,0,0,0-22.5-22.5h-45a22.53,22.53,0,0,0-22.5,22.5V74.25H46.5A22.53,22.53,0,0,0,24,96.75V175.5A22.53,22.53,0,0,0,46.5,198H147.75a22.53,22.53,0,0,0,22.5-22.5V96.75A22.53,22.53,0,0,0,147.75,74.25ZM159,175.5a11.247,11.247,0,0,1-11.25,11.25H46.5A11.247,11.247,0,0,1,35.25,175.5V96.75A11.247,11.247,0,0,1,46.5,85.5H147.75A11.247,11.247,0,0,1,159,96.75ZM97.125,108A16.824,16.824,0,0,0,91.7,140.766a6.1,6.1,0,0,0-.2.984v16.875a5.625,5.625,0,0,0,11.25,0V141.75a6.1,6.1,0,0,0-.2-.984A16.824,16.824,0,0,0,97.125,108Zm0,22.5a5.625,5.625,0,1,1,5.625-5.625A5.642,5.642,0,0,1,97.125,130.5Z" transform="translate(-230 -257)" fill={color} />
                </g>
            </g>
        </svg>

    );
};
