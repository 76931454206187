import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "200", height = "200" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 200">
            <title>User Info</title>
            <desc>User Info</desc>
            <defs>
                <clipPath id="clip-Userinfo">
                    <rect width="200" height="200" />
                </clipPath>
            </defs>
            <g id="Userinfo" clipPath="url(#clip-Userinfo)">
                <g id="noun_Info_1174604" transform="translate(42 67)">
                    <g id="Group_301" data-name="Group 301" transform="translate(-33 -58)">
                        <g id="Group_300" data-name="Group 300" transform="translate(1 1)">
                            <g id="Group_299" data-name="Group 299" transform="translate(0 0)">
                                <g id="Group_298" data-name="Group 298" transform="translate(0 0)">
                                    <g id="Group_297" data-name="Group 297">
                                        <path id="Path_7791" data-name="Path 7791" d="M60.665,109.215l-1.553,6.355q-6.992,2.754-11.158,4.2a29.453,29.453,0,0,1-9.674,1.448q-8.474,0-13.17-4.131a13.357,13.357,0,0,1-4.7-10.487,37.1,37.1,0,0,1,.353-5.049q.353-2.577,1.13-5.826l5.79-20.62q.777-2.965,1.306-5.614a24.808,24.808,0,0,0,.53-4.837q0-3.955-1.624-5.508t-6.214-1.554a16.125,16.125,0,0,0-4.625.707q-2.366.705-4.06,1.341l1.554-6.355q5.72-2.331,10.945-3.99a32.7,32.7,0,0,1,9.887-1.66q8.4,0,12.958,4.06A13.482,13.482,0,0,1,52.9,62.256q0,1.341-.318,4.731A32,32,0,0,1,51.415,73.2L45.625,93.75A56.894,56.894,0,0,0,44.354,99.4a29.964,29.964,0,0,0-.565,4.8q0,4.1,1.836,5.579t6.355,1.482a18.249,18.249,0,0,0,4.8-.741A26.721,26.721,0,0,0,60.665,109.215Zm1.483-86.222a12.034,12.034,0,0,1-4.06,9.145,13.847,13.847,0,0,1-9.78,3.778,13.972,13.972,0,0,1-9.816-3.778,12.3,12.3,0,0,1,0-18.324A13.9,13.9,0,0,1,48.308,10a13.776,13.776,0,0,1,9.78,3.813A12.143,12.143,0,0,1,62.149,22.993Z" transform="translate(50.272 26.155)" fill={color} fillRule="evenodd" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="Ellipse_11" data-name="Ellipse 11" transform="translate(10 10)" fill="none" stroke={color} stroke-width="12">
                    <circle cx="90" cy="90" r="90" stroke="none" />
                    <circle cx="90" cy="90" r="84" fill="none" />
                </g>
            </g>
        </svg>
    );
};
