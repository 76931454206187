import React from 'react';
import './link-cell-type.scss';

export default ({ value }) => {
    return (
        <span className='link-cell-type'>
            <a href={value} className='date-cell-type__date BodySmallMediumBlack'>{ value }</a>
        </span>
    );
};
