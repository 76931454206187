import React from 'react';
import './tag-cell-type.scss';

export default ({ value }) => {
    return(
        <span className='tag-cell-type BodySmallMediumBlack'>
            { value }
        </span>
    );
};
