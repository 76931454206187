import React from 'react';

export default ({ id, className, ariaLabel = "Menu", ariaLabelDescription = "Menu" }) => {

    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width="28" height="6" viewBox="0 0 28 6">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <g fill="none" fillRule="evenodd">
                <g fill="#979797">
                    <g transform="translate(-1342 -649) translate(1342 649)">
                        <circle cx="3" cy="3" r="3" />
                        <circle cx="14" cy="3" r="3" />
                        <circle cx="25" cy="3" r="3" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

