import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "40", height = "40", ariaLabel = "Book", ariaLabelDescription = "Book" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 40 40">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-106 -2192) translate(106 2192)">
                        <g fill={color}>
                            <path d="M12.606 32.9v-2.866H25.81v-.462H4.066v.462h2.628V32.9H3.7c-1.75 0-3.178-1.398-3.233-3.136.055-1.738 1.482-3.136 3.233-3.136h23.67v6.272H12.606zM.462 3.7C.462 1.914 1.914.462 3.7.462h1.19v25.706H3.7c-1.393 0-2.607.774-3.238 1.914V3.7zM5.35 26.168h22.471V.462H5.351v25.706zM28.053 0H3.7C1.66 0 0 1.66 0 3.7v25.962c0 .035.004.068.005.103 0 .035-.005.068-.005.103h.01c.108 1.944 1.72 3.494 3.69 3.494h2.994v3.387l2.854-1.917 3.058 1.906v-3.376H27.6c.128 0 .231-.103.231-.23v-6.494l.23-.009c.125-.005.223-.106.223-.23V.23c0-.128-.103-.231-.23-.231z" transform="translate(5.846 1.538)" />
                            <path d="M8.555 10.271L24.524 10.271 24.524 4.078 8.555 4.078z" transform="translate(5.846 1.538)" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
