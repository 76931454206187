import React from 'react';
import './account-switcher.scss';
import BrandMark from '../SVG/BrandMark';
import { MAIN_CONFIG } from '../../../config/main';
import UserBadge from '../UserBadge/UserBadge';
import DrawnArrow from '../SVG/DrawnArrow';
import Loader from '../Loader/Loader';
import { checkMarketPlace } from '../../Utility/HelperFunctions';
import { useRouter } from 'next/router';
import { handleKeyDown } from '../../Utility/ApplicationFormUtility';


export default ({ userContext, roleData, setAccountSwitcherVisible = () => { }, setAccountLoaderVisible = () => { }, setActiveTab = () => { }, hideGoBack = false }) => {

    const { USER_ROLE_CONTEXT } = MAIN_CONFIG;
    const LOADING_SCREEN_SECONDS = 2;
    const [loading, setLoading] = React.useState(false);
    const router = useRouter();

    /**
     * Triggers an account switch by showing the loader
     * then changing the account context.
     * @param {object} account
     */
    const handleAccountSwitch = (userRole) => {
        setLoading(true)
        setAccountLoaderVisible(true, userRole);
        const roleContext = USER_ROLE_CONTEXT.find(i => i.role === userRole);
        document.cookie = `context=${roleContext ? roleContext.context : ''};path=/`;
        setTimeout(() => {
            if(roleContext.context === "APPLICANT"){
                window.location.href = "/applicant/applications"
            }else{
            window.location.href = checkMarketPlace();
            }
            // router.push(checkMarketPlace(), undefined, { shallow: false })
            setTimeout(() => {
                setAccountSwitcherVisible(false)
                // setLoading(false)
            }, LOADING_SCREEN_SECONDS * 2000);

        }, LOADING_SCREEN_SECONDS * 1000);
    };

    const roleContext = USER_ROLE_CONTEXT.find(i => i.context === userContext);
    /**
     * Renders the available accounts.
     * @param {object[]} accounts
     * @returns {*}
     */
    const renderAccounts = (roleData) => {
        return roleData.filter(item => (item !== roleContext.role && item !== 'Admin')).map((account, index) => {
            return (
                <li key={`account-switcher-${index}`} tabIndex={0} role='button' onKeyDown={(e)=>handleKeyDown(e,()=>handleAccountSwitch(account))}>
                    <a href={'#'} onClick={() => handleAccountSwitch(account)} tabIndex={-1}>
                        <div className='account-switcher__account' tabIndex={-1}        >
                            <div className='account-switcher__left'>
                                <div className='CTABlack account-switcher__company'>{(account === 'School Counsellor') ? 'School Counselor' : account}</div>
                            </div>

                            <DrawnArrow tabIndex={-1} className='account-switcher__arrow' color={MAIN_CONFIG.COLORS.fillBlue} ariaLabel="Right Arrow" ariaLabelDescription={`Click here to select ${account} role`} />
                        </div>
                    </a>
                </li>
            );
        });
    };

    const goBack = () => {
        setAccountSwitcherVisible(false);
        setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EXPLORE)
    }
    let currentUserRole = ['Applicant','Endorser User','University Staff']
    return (
        <div id='account-switcher'>
            <Loader loading={loading} />
            <div className="account-switcher__logo-inner-content-area">
                <div id='account-switcher__logo'>
                    <a href="/"><BrandMark color={MAIN_CONFIG.COLORS.fillBlue} /></a>
                </div>
                <div id='account-switcher__inner'>
                    <div id='account-switcher__title' className='H1DesktopGreen'>
                        Select a Role
                    </div>
                    <div id='account-switcher__copy' className='BodySmallRegularBlack'>
                    You're currently signed in as {!currentUserRole.includes(roleContext.role)? 'a' : 'an'}  <span className='CTAGreen'>{roleContext.role === 'School Counsellor' ? 'School Counselor' : roleContext.role}.</span> <span className='BodySmallRegularBlack'>Switch your role by clicking below, </span>
                        {hideGoBack &&
                            <>
                                or <a href={'#'} onClick={() => { setLoading(true);  router.back(); }}><span className='CTAGreen'><u>GO BACK.</u></span> </a>
                            </>
                        }
                    </div>

                    <ul id='account-switcher__accounts'>
                        {renderAccounts(roleData)}
                    </ul>
                </div>
            </div>
        </div>
    );
};
