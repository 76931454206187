import React from "react";
import { MAIN_CONFIG } from "../../../config/main";

export default ({
    id,
    className,
    color = MAIN_CONFIG.COLORS.fillBlue,
    width = 48,
    height = 48,
    ariaLabel = "Video",
    ariaLabelDescription = "Video",
}) => {
    return (
        <svg
            id={id}
            className={className}
            width={width}
            height={height}
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.625 13.9688H4.90625V11.25C4.90625 11.1469 4.82188 11.0625 4.71875 11.0625H3.59375C3.49062 11.0625 3.40625 11.1469 3.40625 11.25V13.9688H0.6875C0.584375 13.9688 0.5 14.0531 0.5 14.1562V15.2812C0.5 15.3844 0.584375 15.4688 0.6875 15.4688H3.40625V18.1875C3.40625 18.2906 3.49062 18.375 3.59375 18.375H4.71875C4.82188 18.375 4.90625 18.2906 4.90625 18.1875V15.4688H7.625C7.72813 15.4688 7.8125 15.3844 7.8125 15.2812V14.1562C7.8125 14.0531 7.72813 13.9688 7.625 13.9688Z"
                fill="#1C21DF"
            />
            <path
                d="M20.375 4.08516L17.375 5.8125V2.25C17.375 1.42266 16.7023 0.75 15.875 0.75H2C1.17266 0.75 0.5 1.42266 0.5 2.25V10.5H2.1875V2.4375H15.6875V15.5625H9.5V17.25H15.875C16.7023 17.25 17.375 16.5773 17.375 15.75V12.1875L20.375 13.9148C20.8742 14.2031 21.5 13.8422 21.5 13.268V4.73438C21.5 4.15781 20.8742 3.79687 20.375 4.08516ZM19.8125 11.6484L17.375 10.2469V7.75547L19.8125 6.35156V11.6484Z"
                fill="#1C21DF"
            />
            <path
                d="M6.5 5.4375C6.60313 5.4375 6.6875 5.35313 6.6875 5.25V4.125C6.6875 4.02187 6.60313 3.9375 6.5 3.9375H3.875C3.77187 3.9375 3.6875 4.02187 3.6875 4.125V5.25C3.6875 5.35313 3.77187 5.4375 3.875 5.4375H6.5Z"
                fill="#1C21DF"
            />
        </svg>
    );
};