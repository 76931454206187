import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "200", height = "200" }) => {
    return (

        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 200">
            <title>History</title>
            <desc>History</desc>
            <defs>
                <clipPath id="clip-History_">
                    <rect width="200" height="200" />
                </clipPath>
            </defs>
            <g id="History_" data-name="History " clipPath="url(#clip-History_)">
                <g id="noun_History_2129890" transform="translate(5 5)">
                    <path id="Path_7823" data-name="Path 7823" d="M52.975,169.008v-48L33.848,140.144A76,76,0,0,1,94.952,19V5A89.966,89.966,0,0,0,23.88,150.118L5,169.008Z" transform="translate(0 0)" fill={color} />
                    <path id="Path_7824" data-name="Path 7824" d="M64.844,21.107,68.311,7.552a90,90,0,0,0-12.784-2.3L54.051,19.167a76.136,76.136,0,0,1,10.793,1.94Z" transform="translate(49.055 0.252)" fill={color} />
                    <path id="Path_7825" data-name="Path 7825" d="M99.555,36.357,86.223,40.6a75.68,75.68,0,0,1,2.551,10.658L102.583,49a89.592,89.592,0,0,0-3.028-12.641Z" transform="translate(81.232 31.36)" fill={color} />
                    <path id="Path_7826" data-name="Path 7826" d="M50,102.384a90.812,90.812,0,0,0,12.954-.928L60.945,87.608A76.746,76.746,0,0,1,50,88.391Z" transform="translate(45.004 82.616)" fill={color} />
                    <path id="Path_7827" data-name="Path 7827" d="M82.884,15.14,74.029,25.974a76.93,76.93,0,0,1,7.978,7.538l10.315-9.454a90.867,90.867,0,0,0-9.438-8.918Z" transform="translate(69.051 10.129)" fill={color} />
                    <path id="Path_7828" data-name="Path 7828" d="M81.43,32.572a76.226,76.226,0,0,1,5.507,9.492l12.579-6.13a90.259,90.259,0,0,0-6.522-11.239Z" transform="translate(76.444 19.697)" fill={color} />
                    <path id="Path_7829" data-name="Path 7829" d="M70.046,8.468l-5.4,12.911a75.631,75.631,0,0,1,9.776,4.949l7.207-11.994A89.582,89.582,0,0,0,70.046,8.468Z" transform="translate(59.657 3.465)" fill={color} />
                    <path id="Path_7830" data-name="Path 7830" d="M88.471,60.144a75.5,75.5,0,0,1-3.68,10.326L97.6,76.112a90.025,90.025,0,0,0,4.363-12.243Z" transform="translate(79.802 55.149)" fill={color} />
                    <path id="Path_7831" data-name="Path 7831" d="M88.283,49.411c.008.478.012.957.012,1.439a77.261,77.261,0,0,1-.589,9.521l13.883,1.74a90.9,90.9,0,0,0,.7-11.261c0-.568,0-1.136-.016-1.7Z" transform="translate(82.713 44.153)" fill={color} />
                    <path id="Path_7832" data-name="Path 7832" d="M97.317,77.583,85.46,70.153A76.362,76.362,0,0,1,78.979,79l10.666,9.058a90.666,90.666,0,0,0,7.672-10.479Z" transform="translate(73.996 65.161)" fill={color} />
                    <path id="Path_7833" data-name="Path 7833" d="M60.836,88.371l3.978,13.415a89.6,89.6,0,0,0,12.165-4.6L71.092,84.494a75.7,75.7,0,0,1-10.256,3.877Z" transform="translate(55.842 79.505)" fill={color} />
                    <path id="Path_7834" data-name="Path 7834" d="M88.758,88.988,79.493,78.5a76.231,76.231,0,0,1-8.726,6.651l7.656,11.712a90.388,90.388,0,0,0,10.334-7.877Z" transform="translate(65.775 73.518)" fill={color} />
                    <path id="Path_7835" data-name="Path 7835" d="M36.766,31.113a4,4,0,1,0-5.653,5.653L60.691,66.343a10.085,10.085,0,1,0,19.238,1.945L105.4,51.446a4,4,0,1,0-4.41-6.669L75.527,61.613a9.921,9.921,0,0,0-9.184-.923Z" transform="translate(24.962 24.953)" fill={color} />
                </g>
            </g>
        </svg>
    );
};
