import React from 'react';
import './account-loading-screen.scss';
import '../../Core/Styles/variables.scss';
import WordMark from '../../Components/SVG/WordMark';
import { MAIN_CONFIG } from '../../../config/main';


export default ({ account }) => {

    var primaryColorConvert = account.primaryColor ? account.primaryColor.toLowerCase() : ''

    return (
        <div
            id='account-loading-screen'
            style={{ backgroundColor: primaryColorConvert == '#ffffff' ? '#ffffff' : account.primaryColor || "#008067" }}
        >
            <div id='account-loading-screen__logo'>
                {
                    account.companyLogo ? <img loading="lazy" src={account.companyLogo} alt='Logo' width="250px" /> : <WordMark color={MAIN_CONFIG.COLORS.fillWhite} width={200} />
                }
            </div>
        </div>
    );
};
