import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "200", height = "200", ariaLabel = "Logout", ariaLabelDescription = "Logout" }) => {
    return (

        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 200">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <defs>
                <clipPath id="clip-Logout">
                    <rect width="200" height="200" />
                </clipPath>
            </defs>
            <g id="Logout" clipPath="url(#clip-Logout)">
                <g id="noun_logout_1997053" transform="translate(46.25 374.6)">
                    <g id="Group_320" data-name="Group 320" transform="translate(-34.25 -364.6)">
                        <g id="Group_315" data-name="Group 315" transform="translate(0 0)">
                            <g id="Group_314" data-name="Group 314">
                                <path id="Path_7802" data-name="Path 7802" d="M178.53,196.886v36.655a48.253,48.253,0,0,1-.061,5.707c.1-.716.2-1.452.287-2.168a7.079,7.079,0,0,1-.369,1.33c.266-.655.553-1.309.819-1.964a7.461,7.461,0,0,1-.819,1.411l1.29-1.657a6.622,6.622,0,0,1-1.147,1.125l1.658-1.289a6.65,6.65,0,0,1-1.167.675c.655-.266,1.31-.552,1.965-.818a7.339,7.339,0,0,1-1.31.368c.717-.1,1.454-.2,2.17-.286a24.3,24.3,0,0,1-2.989.061H104.8c-3.44,0-6.879.061-10.319,0a7.276,7.276,0,0,1-.942-.061c.717.1,1.454.2,2.17.286a7.342,7.342,0,0,1-1.31-.368c.655.266,1.31.552,1.966.818a6.652,6.652,0,0,1-1.167-.675l1.658,1.289a5.971,5.971,0,0,1-1.147-1.125L97,237.857a7.46,7.46,0,0,1-.819-1.411c.266.655.553,1.309.819,1.964a7.532,7.532,0,0,1-.369-1.33c.1.716.2,1.452.287,2.168a40.722,40.722,0,0,1-.061-4.745V92.221c0-1.943-.02-3.866,0-5.809a8.913,8.913,0,0,1,.061-1.043c-.1.716-.2,1.452-.287,2.168A7.321,7.321,0,0,1,97,86.227c-.266.655-.553,1.309-.819,1.964A7.141,7.141,0,0,1,97,86.821l-1.29,1.657a6.674,6.674,0,0,1,.942-.92L95,88.846a6.653,6.653,0,0,1,1.392-.8c-.655.266-1.31.552-1.966.818a7.28,7.28,0,0,1,1.31-.348c-.717.1-1.454.2-2.17.286a24.223,24.223,0,0,1,2.989-.061h74.055c3.44,0,6.879-.061,10.319,0a7.281,7.281,0,0,1,.942.061c-.717-.1-1.454-.2-2.17-.286a7.28,7.28,0,0,1,1.31.348c-.655-.266-1.31-.552-1.965-.818a6.789,6.789,0,0,1,1.392.8l-1.658-1.289a6.674,6.674,0,0,1,.942.92l-1.29-1.657a6.568,6.568,0,0,1,.819,1.37c-.266-.655-.553-1.309-.819-1.964a7.323,7.323,0,0,1,.369,1.309c-.1-.716-.2-1.452-.287-2.168a40.021,40.021,0,0,1,.061,4.7v37.841c0,4.275,3.767,8.386,8.19,8.182a8.332,8.332,0,0,0,8.19-8.182V92.486c0-2.27.143-4.6-.02-6.852a13.943,13.943,0,0,0-4.341-9.47,14.559,14.559,0,0,0-9.541-3.764H94.627A13.862,13.862,0,0,0,80.5,86.35V238.266a14.369,14.369,0,0,0,4.177,10.1,14.218,14.218,0,0,0,9.991,4.03h86.4a14.236,14.236,0,0,0,13.922-14.011c.041-2.209,0-4.4,0-6.607V196.846c0-4.275-3.767-8.386-8.19-8.182-4.525.245-8.272,3.641-8.272,8.223Z" transform="translate(-80.5 -72.4)" fill={color} />
                            </g>
                        </g>
                        <g id="Group_317" data-name="Group 317" transform="translate(40.629 81.803)">
                            <g id="Group_316" data-name="Group 316">
                                <path id="Path_7803" data-name="Path 7803" d="M287.961,488.773H392.83c4.832,0,9.684.061,14.516,0h.2c4.279,0,8.394-3.767,8.19-8.19a8.336,8.336,0,0,0-8.19-8.19H302.682c-4.832,0-9.684-.061-14.516,0h-.2c-4.279,0-8.394,3.767-8.19,8.19a8.308,8.308,0,0,0,8.19,8.19Z" transform="translate(-279.764 -472.367)" fill={color} />
                            </g>
                        </g>
                        <g id="Group_319" data-name="Group 319" transform="translate(130.944 52.366)">
                            <g id="Group_318" data-name="Group 318">
                                <path id="Path_7804" data-name="Path 7804" d="M723.242,342.53l9.889,9.889L748.9,368.184l3.644,3.644V360.24l-9.889,9.889-15.765,15.765-3.644,3.644c-3.03,3.03-3.255,8.6,0,11.588a8.339,8.339,0,0,0,11.588,0l9.889-9.889,15.765-15.765,3.644-3.644a8.32,8.32,0,0,0,0-11.588l-9.889-9.889-15.765-15.765-3.644-3.644c-3.03-3.03-8.6-3.255-11.588,0a8.339,8.339,0,0,0,0,11.588Z" transform="translate(-720.884 -328.584)" fill={color} />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
