import React, { useEffect, useState } from 'react';
import "./DonorApplicationsSidebar.scss";
import DonorSidebar from '../DonorSidebar/DonorSidebar';
import PropTypes from 'prop-types';
import { MAIN_CONFIG } from '../../../config/main';
import { useRouter } from 'next/router';

const DonorApplicationsSidebar = ({ setLoading, scholarship, pageData, setActiveDonorSidebarTab, activeDonorSidebarTab}) => {
    const router = useRouter();
    const scholarshipId = router.query.scholarshipId;
    const { pageType, account } = pageData;
    const enableFeatureSetLevel = ["Pro", "Pro-Legacy", "Enterprise"]
    const disableFeatureSetLevel = ["Starter", "Starter-Legacy"]
    let isElementsLayout = scholarship?.elements_review_board__c
    const handleReviewerClickURL = (isElementsLayout) ? MAIN_CONFIG.ELEMENTS_URLS.DONOR.REVIEW_BOARD(scholarshipId) : MAIN_CONFIG.URLS.DONOR.SCHOLARSHIP_REVIEWERS(scholarshipId);

    const handleCheckDisable = () => {
        if (account?.elements_reporting_dashboard__c) {
            return false
        } else if (enableFeatureSetLevel.includes(account?.feature_set_level__c)) {
            return false
        } else if (disableFeatureSetLevel.includes(account?.feature_set_level__c)) {
            return true
        } else {
            return true
        }
    }

    const reportsMenuItems = [
        {
            label: "Dashboard",
            link: MAIN_CONFIG.ELEMENTS_URLS.DONOR.REPORT_DASHBOARD(account?.sfid),
            isExternal: true,
            disable: handleCheckDisable()
        },
        {
            label: "Reports List",
            link: MAIN_CONFIG.ELEMENTS_URLS.DONOR.REPORT_List(account?.sfid),
            isExternal: true,
            disable: handleCheckDisable()
        },
        {
            label: "Exported Files",
            link: MAIN_CONFIG.URLS.DONOR.REPORTS,
            isExternal: false,
            disable: false
        }
    ];

    const ApplicationsMenuItems = [
        {
            label: "Applications",
            link: MAIN_CONFIG.URLS.DONOR.SINGLE_SCHOLARSHIP(scholarshipId),
            isExternal: true,
            disable: false
        },
        {
            label: "Forms",
            link: MAIN_CONFIG.URLS.DONOR.EDIT_SCHOLARSHIP(scholarshipId),
            isExternal: true,
            disable: false
        },
        {
            label: "Review Board",
            link: handleReviewerClickURL,
            isExternal: isElementsLayout,
            disable: false
        }
    ];

    const sidebarMenuItems = () => {
        if (pageType === "REPORTS") {
            return reportsMenuItems
        } else {
            return ApplicationsMenuItems
        }
    }

    return (
        <DonorSidebar
            title={pageType}
            menuItems={sidebarMenuItems()}
            activeDonorSidebarTab={activeDonorSidebarTab}
            setActiveDonorSidebarTab={setActiveDonorSidebarTab}
            setLoading={setLoading}
        />
    );
};

DonorApplicationsSidebar.propTypes = {
    scholarship: PropTypes.shape({
        elements_review_board__c: PropTypes.bool,
    }),
    pageData: PropTypes.shape({
        pageType: PropTypes.string,
        account: PropTypes.shape({
            sfid: PropTypes.string,
        }),
    }),
    activeDonorSidebarTab: PropTypes.string,
    setActiveDonorSidebarTab: PropTypes.func,
    setLoading: PropTypes.func,
};

DonorApplicationsSidebar.defaultProps = {
    // setIsApplicationClicked: () => { },
};

export default DonorApplicationsSidebar