/**
 * Sets the value of a dropdown based on the target value.
 * @param list
 * @param target
 * @param fallbackIndex
 * @returns {*}
 */
export const loadSelectedListValue = (list, target, fallbackIndex = 0) => {
    const stringTarget = target ? target.toString() : '';
    const selected = list.filter(i => {
        return i.value.toString() === stringTarget
    });
    return selected[0] || list[fallbackIndex];
}

/**
 * Sets the value of a multiselect based on the target value.
 * @param list
 * @param targetValueString
 * @returns {*}
 */
export const loadSelectedMultiListValue = (list, targetValueString, symbol = ';') => {
    const valueString = targetValueString || '';
    const values = valueString.split(symbol);

    return list.filter(i => values.includes(i.value));
}


/**
 * TODO  	
 * Create array of object for Dropdown list.
 * its contain label and value, passing Array
 */
export const CreateOptions = (arg, answerOptions = []) => {
    if (answerOptions.length > 0) {
      return arg?.filter(option => answerOptions.includes(option)).map(option => {
        return {
          label: option,
          value: option
        };
      }) || [];
    } else {
      let options = [];
      for (let i = 0; i < arg?.length; i++) {
        options?.push({
          label: `${arg[i]}`,
          value: arg[i]
        });
      }
      return options;
    }
  };