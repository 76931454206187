import React from 'react';
import PropTypes from 'prop-types';
import "./InnerPagesHeader.scss";

const InnerPageHeader = ({ pageType, pageTitle, subtitle='' }) => {

  return (
    <header className="applications-page-title-header" id={pageType}>
      <div className="applications-page-title-header__section CTABlack">
        <span>{pageTitle} / {subtitle}</span>
      </div>
    </header>
  );
};

InnerPageHeader.propTypes = {
  pageTitle: PropTypes.string,
  pageType: PropTypes.string,
  subtitle: PropTypes.string
}
export default InnerPageHeader;
