import { isEmpty, filter, intersection, constant, isUndefined } from 'lodash';
import { MAIN_CONFIG, dataTypeJoin, docDataType, videoDataType, dataTypeReadOnly, dataTypeReadOnlyWithoutLogin, dateDataType, allDocType, autoFillAddressFields, validateSelectTypeFields } from '../../config/main';
import { CreateOptions } from './SelectListLoader';
import { parseHtml, convertDataFormat, formattedAnswer, formatCurrency, checkDateFormats, handleRedirectHrefUrl, validateEmailRegex } from './HelperFunctions';
import FormatDate from './FormatDate';
import cookies from 'next-cookies';
import { ToastContainer, toast } from 'react-toastify';
import FlashMessage from '../Components/FlashMessage/Message';
import KaleidoscopeAPI from '../Core/KaleidoscopeAPI';
import ReactPlayer from 'react-player'
import SweetAlert from 'react-bootstrap-sweetalert';
import Alert from '../Components/SVG/Alert';



const API = new KaleidoscopeAPI({});

  
// TODO: Validate,
export const applicantValidation = (data, dependentConfigsForVariousSteps, applicationDependentConfigs, showFlashmessage) => {



  const dependentConfigs = (applicationDependentConfigs && applicationDependentConfigs || [])
  let errorArg = []
  let error = false
  data.map((row) => {
    if (dateDataType.indexOf(row.data_type__c) > -1 && row.answer) {
      if (!checkDateFormats(row.answer, row.data_type__c)) {
        errorArg.push({
          id: row.id,
          message: 'Please enter a valid date.'
        })
      }
    }
    if (row.required__c && !row.answer && row.config_type__c !== "Message" && !allDocType.includes(row.data_type__c)) {
      errorArg.push({
        id: row.id,
        message: row.error_message__c ? row.error_message__c : MAIN_CONFIG.DEFAULT_ERROR
      })
    }
    if (row.required__c && row.data_type__c == "Email") {
      var emailValidError = validateEmailRegex(row.answer)
        ? ""
        : "Email is not valid!";
      if (emailValidError) {
        errorArg.push({
          id: row.id,
          message: emailValidError
        })
      } else {
        var temp = errorArg.filter(obj => obj.id != row.id)
        errorArg = temp
      }

    }

    if (!row.exit_config__c && row.answer && (row.max_date__c && row.min_date__c)) {
      var currentDate = new Date(row.answer).setHours(0, 0, 0, 0);
      var minDate = new Date(row.min_date__c).setHours(0, 0, 0, 0);
      var maxDate = new Date(row.max_date__c).setHours(0, 0, 0, 0);
      var finalDate = currentDate >= minDate && currentDate <= maxDate;
      if (!finalDate) {
        errorArg.push({
          id: row.id,
          message: row.error_message__c ? row.error_message__c : "Please Enter valid date!"
        })
      }
    }

    // Check for Minimum document Upload
    if ((row.document || []).length < row.minimum_doc_upload_required__c) {
      toast.error(<FlashMessage message={row.minimum_doc_error_message__c || MAIN_CONFIG.DEFAULT_ERROR} />)
      errorArg.push({
        id: row.id,
        message: row.error_message__c ? row.error_message__c : row.minimum_doc_error_message__c ? row.minimum_doc_error_message__c : MAIN_CONFIG.DEFAULT_ERROR
      })
    }

    if ((row.required__c && allDocType.includes(row.data_type__c)) || row.doc_upload_required__c) {
      if ((isEmpty(row.document) || isEmpty(row.document.url)) && (row.document || []).length < 1) {
        errorArg.push({
          id: row.id,
          message: row.error_message__c ? row.error_message__c : MAIN_CONFIG.DEFAULT_ERROR
        })
      }
    }
    if (row.required__c && !row.answer && !allDocType.includes(row.data_type__c)) {
      errorArg.push({
        id: row.id,
        message: row.error_message__c ? row.error_message__c : MAIN_CONFIG.DEFAULT_ERROR
      })
    }


    if ((row.data_type__c === 'Long Text') && row.word_count_enable__c) {
      const minWords = row.minimum_word_required__c || 0
      var words;
      words = ((row.answer || "").match(/\S+/g) || []).length || 0;
      if (words < minWords) {
        // errors[row.name] = `Please enter minimum ${minWords} words.`
        errorArg.push({
          id: row.id,
          message: `Please enter minimum ${minWords} words.`
          // message: row.error_message__c ? row.error_message__c : MAIN_CONFIG.DEFAULT_ERROR
        })
      }
    }


    if (row.data_type__c === 'Number' && row.answer && (row.max_value__c || row.min_value__c)) {
      if (row.min_value__c && (row.answer < row.min_value__c)) {
        if (showFlashmessage) {
          toast.error(<FlashMessage message={`Please enter number equal or greater than ${row.min_value__c}.`} />)
        }
        errorArg.push({
          id: row.id,
          message: row.error_message__c ? row.error_message__c : MAIN_CONFIG.DEFAULT_ERROR
        })
      }
      if ((row.max_value__c && (row.answer > row.max_value__c))) {
        if (showFlashmessage) {
          toast.error(<FlashMessage message={`Please enter number equal or less than ${row.max_value__c}.`} />)
        }
        errorArg.push({
          id: row.id,
          message: row.error_message__c ? row.error_message__c : MAIN_CONFIG.DEFAULT_ERROR
        })
      }
    }

    if ((row.data_type__c === 'Currency') && row.answer && (row.max_value__c || row.min_value__c)) {
      let num = parseInt(row.answer.replace(/,/g, ''));
      if (row.min_value__c && (num < row.min_value__c)) {
        if (showFlashmessage) {
          toast.error(<FlashMessage message={`Please enter value equal or greater than $${row.min_value__c}.`} />)
        }
        errorArg.push({
          id: row.id,
          message: row.error_message__c ? row.error_message__c : MAIN_CONFIG.DEFAULT_ERROR
        })
      }
      else if ((row.max_value__c && (num > row.max_value__c))) {
        if (showFlashmessage) {
          toast.error(<FlashMessage message={`Please enter value equal or less than $${row.max_value__c}.`} />)
        }
        errorArg.push({
          id: row.id,
          message: row.error_message__c ? row.error_message__c : MAIN_CONFIG.DEFAULT_ERROR
        })
      }
    }


    if (dataTypeJoin.includes(row.data_type__c) && row?.answer?.length > 0 && (row.max_value__c || row.min_value__c)) {
      if (row.min_value__c && (row.answer.length < row.min_value__c)) {
        if (showFlashmessage) {
          toast.error(<FlashMessage message={`Please select atleast ${row.min_value__c} options.`} />)
        }
        errorArg.push({
          id: row.id,
          message: `Please select atleast ${row.min_value__c} options.` || MAIN_CONFIG.DEFAULT_ERROR
        })
      }
      if ((row.max_value__c && (row.answer.length > row.max_value__c))) {
        if (showFlashmessage) {
          toast.error(<FlashMessage message={`You can select ${row.max_value__c} options only.`} />)
        }
        errorArg.push({
          id: row.id,
          message: `You can select ${row.max_value__c} options only.` || MAIN_CONFIG.DEFAULT_ERROR
          // message: row.error_message__c ? row.error_message__c : MAIN_CONFIG.DEFAULT_ERROR
        })
      }
    }

    if (row.answer && (row.number_input_required_max_length__c || row.number_input_required_min_length__c)) {
      if (row.number_input_required_min_length__c && (row.answer.length < row.number_input_required_min_length__c)) {
        if (showFlashmessage) {
          toast.error(<FlashMessage message={`Please enter at least ${row.number_input_required_min_length__c} characters.`} />)
        }
        errorArg.push({
          id: row.id,
          message: row.error_message__c ? row.error_message__c : MAIN_CONFIG.DEFAULT_ERROR
        })
      }
      if ((row.number_input_required_max_length__c && (row.answer.length > row.number_input_required_max_length__c))) {
        if (showFlashmessage) {
          toast.error(<FlashMessage message={`Please enter ${row.number_input_required_max_length__c} characters only.`} />)
        }
        errorArg.push({
          id: row.id,
          message: row.error_message__c ? row.error_message__c : MAIN_CONFIG.DEFAULT_ERROR
        })
      }
    }

    if (row.exit_config_name__c && row.answer) {
      const operator = row.exit_operator__c
      const totalInputs = (data.concat(dependentConfigs))
      const parentConfig = totalInputs.find(obj => { return (obj.name === row.exit_config_name__c) })
      let Errored = false
      if (parentConfig) {
        var numberPattern = /\d+/g;
        const formattedInputAnswer = ((row.answer || "0").toString().match(numberPattern) || []).join('')
        const formattedParentAnswer = ((parentConfig.answer || "0").toString().match(numberPattern) || []).join('')
        switch (operator) {
          case 'Less': {
            // eslint-disable-next-line
            Errored = !(parseFloat(formattedInputAnswer) >= parseFloat(formattedParentAnswer, 10))
            break;
          }
          case 'Greater':
            // eslint-disable-next-line
            Errored = !(parseFloat(formattedInputAnswer, 10) <= parseFloat(formattedParentAnswer, 10))
            break;
          case 'equals':
            Errored = !(row.answer === parentConfig.answer)
            break;
          case 'contains':
            Errored = !(row.answer.includes(parentConfig.answer))
            break;
          default:
            Errored = false;
        }
      }
      if (Errored) {
        errorArg.push(row.id)

      }
    }

    if (row.required_when__c && checkRequiredWhen(row)) {
      const parent = data.concat(dependentConfigsForVariousSteps).find(obj => (obj.label__c && (obj.label__c.toString() === row.config_name__c)))
      const configValues = (row.config_value__c || '').split(';')
      if (parent) {
        if ((row.operator__c === 'contains') && containsVal(parent.answer, configValues)) {
          // errors[row.name] = 'Please enter answer.'
          errorArg.push({
            id: row.id,
            message: row.error_message__c ? row.error_message__c : MAIN_CONFIG.DEFAULT_ERROR
          })
        } else if ((row.operator__c === 'equals') && hasEqualVal(parent, configValues)) {
          // errors[row.name] = 'Please enter answer.'
          errorArg.push({
            id: row.id,
            message: row.error_message__c ? row.error_message__c : MAIN_CONFIG.DEFAULT_ERROR
          })
        }
      }
    }
  })

  if (!isEmpty(errorArg) || error) {
    return errorArg
  } else {
    return 'true'
  }
}


/**
 * Fetch answer from state
 * @returns string
 */
export const getAnswer = (question) => {
  if (!question) {
    return '';
  }

  const { data_type__c, answer_options__c, answer } = question;

  if (data_type__c === 'Picklist' || data_type__c === "Answer Dependent Picklist") {
    if (answer_options__c && answer_options__c?.split(';').includes(answer)) {
      return { label: answer, value: answer }
    }
    return { label: '', value: '' };
  } else if (data_type__c === "Dependent Picklist") {
    return answer && Array.isArray(answer) ? answer : { label: answer, value: answer };
  } else if (data_type__c === 'Multiselect Picklist' && answer) {
    return Array.isArray(answer) ? CreateOptions(answer, answer_options__c) : formattedAnswer(answer, data_type__c, ';', answer_options__c);
  } else if (data_type__c === "Checkbox" && answer) {
    return Array.isArray(answer) ? answer : (answer ? answer.toString().split(';') : []);
  } else {
    return answer !== null ? answer : '';
  }
};


// export const getAnswer = (question) => {

//   return question
//     ? (question.data_type__c === 'Picklist' || question.data_type__c === "Dependent Picklist" || question.data_type__c === "Answer Dependent Picklist")
//       ? (question.answer && Array.isArray(question.answer))
//         ? question.answer
//         : { label: question.answer, value: question.answer }
//       : (question.data_type__c === 'Multiselect Picklist' && question.answer) ? Array.isArray(question.answer) ? CreateOptions(question.answer) : formattedAnswer(question.answer, question.data_type__c)
//         : (question.data_type__c === "Checkbox" && question.answer) ? Array.isArray(question.answer) ? question.answer : (Array.isArray(question.answer) ? question.answer : (question.answer ? question.answer.toString().split(';') : []))
//           : question.answer !== null ? question.answer : ''
//     : ''
//   // question
// }

/**
 * Fetch answer from state
 * @returns string
 */
export const getQuestionAnswer = (question) => {

  return question
    ? (question.answer_type__c === 'Dropdown' || question.answer_type__c === "Dependent Picklist" || question.answer_type__c === "Answer Dependent Picklist")
      ? (question.question_answer__c && Array.isArray(question.question_answer__c))
        ? question.question_answer__c
        : { label: question.question_answer__c, value: question.question_answer__c }
      : (question.answer_type__c === 'Multiselect Picklist' && question.question_answer__c) ? Array.isArray(question.question_answer__c) ? question.question_answer__c : formattedAnswer(question.question_answer__c, question.answer_type__c)
        : (question.answer_type__c === "Checkbox" && question.question_answer__c) ? Array.isArray(question.question_answer__c) ? question.question_answer__c : question.question_answer__c.split(';')
          : question.question_answer__c !== null ? question.question_answer__c : ''
    : ''
  // question
}

// onchange common
export const onChangeQuestionAnswer = (question, value, isDocument) => {
  if (isDocument) {
    const { url } = value?._response?.request
    let uploadedFileName=url.split("/")[6]
    let fileName=uploadedFileName.split("?").shift()
    // question.question_answer__c = dataTypeDoc.includes(question.answer_type__c) ? `${fileName}` : question.question_answer__c
    // test this change 
    } else {
    if (question.answer_type__c === 'Dropdown' || question.answer_type__c === "Dependent Picklist" || question.answer_type__c === "Answer Dependent Picklist") {
      if (Array.isArray(value)) {
        question.question_answer__c = value[0].value
      } else {
        question.question_answer__c = value.value
      }

      // }else if(){
      //   let multiAnswer = []
      //   value && value.filter(r => multiAnswer.push(r.value))
      //   question.question_answer__c = multiAnswer
    } else if (question.answer_type__c === 'Multiselect Picklist' || question.answer_type__c === 'Checkbox') {
      question.question_answer__c = value
    } else {
      question.question_answer__c = value
    }
  }
  if (isDocument) {
    const { url } = value?._response?.request;
    const { file_Name } = value?._response || '';
    const videodurationInSeconds = value?._response?.video_duration__c || ''
    question.document = {
      doc_url__c: url.split("?").shift(),
      doc_name__c: file_Name,
      ...(videodurationInSeconds ? { video_duration__c: videodurationInSeconds } : {}),
    }
  }
  return question
}
/**
 * Fetch question document from state
 * @returns string
 */
export const getDocumentObject = (question) => {
  return question && question.document ? question.document.length > 0 ? question.document[0] : question.document : question.document_name__c ? { 'doc_name__c': question.document_name__c, 'doc_url__c': question.document_url__c } : ''
}
/**
 * Fetch placeholder
 * @returns string
 */
export const getPlaceholder= (type) => {
  return type==='Formula' ? '0' : 'Please answer.';
}

/**
 * Fetch question document from state
 * @returns string
 */
export const setParamResponse = (fieldData) => {
  let data = []
  fieldData.map((row) => {
    if (row.answer && row.data_type__c === "Currency") {
      row.answer = (row.config_type__c === 'Field') ? row.answer.toString().split(',').join('') : formatCurrency(row.answer, row?.currency_symbol__c || "USD")
    }
    if (row.answer && (Array.isArray(row.answer) || row.answer.value)) {
      if (row.data_type__c === 'Picklist' || row.data_type__c === "Dependent Picklist" || row.data_type__c === "Answer Dependent Picklist") {
        if (Array.isArray(row.answer)) {
          data.push({ sfid: row.sfid, answer: row.answer[0].value })
        } else {
          data.push({ sfid: row.sfid, answer: row.answer.value })
        }
      } else if (row.data_type__c === 'Multiselect Picklist' || row.data_type__c === 'Checkbox') {
        data.push({ sfid: row.sfid, answer: row.answer.join(';') })
      }
    } else {
      data.push({ sfid: row.sfid, answer: row.answer || '' })
    }
  })
  return data
}

// Financial Information
export const externalFinancialInformation = (iteration, references) => {
  var newfields = []
  var externalRoleList = []

  if (iteration && iteration.length > 0) {
    iteration.filter((item, i) => {
      newfields.push(item.filter(row => row.config_type__c !== "Field"))
    })
  }

  if (references.length > 0) {
    references.map((row, i) => {
      if (newfields.length > 0 && !isUndefined(newfields[i])) {
        externalRoleList.push(row.concat([...newfields[i]]))
      } else {
        externalRoleList.push(row)
      }
    })
  }
  return externalRoleList
}

// onchange common
export const onChangeAnswer = (question, value, isDocument, deleteDocument = false, videoDuration) => {
  if(deleteDocument){
   delete question.document
  }
  if (isDocument) {
    const { url } = value?._response?.request
    let uploadedFileName=url.split("/")[6]
    let fileName=uploadedFileName.split("?").shift()
    // question.answer = dataTypeDoc.includes(question.data_type__c) ? `${fileName}` : question.answer
    } else {
    if (question.data_type__c === 'Picklist' || question.data_type__c === "Dependent Picklist" || question.data_type__c === "Answer Dependent Picklist") {
      if (Array.isArray(value)) {
        question.answer = value[0].value
      } else {
        question.answer = value.value
      }

    } else if (question.data_type__c === 'Multiselect Picklist') {
      let multiAnswer = []
      value && value.filter(r => multiAnswer.push(r.value))
      question.answer = multiAnswer.length > 0 ? multiAnswer : ''
    } else if (question.data_type__c === 'Checkbox') {
      question.answer = value ? value.length > 0 ? value : '' : ''
    } else {
      question.answer = value
    }
  }
  if (isDocument) {
    const { url } = value?._response?.request
    const { file_Name } = value?._response || '';
   question.document = {
    doc_url__c: url.split("?").shift(),
    doc_name__c: file_Name,
    ...(videoDuration ? { video_duration__c: videoDuration } : {}),
    }
  }
  return question
}
// Generate All Question array
export const mergeArrays = (arr1 = [], arr2 = []) => {
  let res = [];
  res = arr1.map(obj => {
    const index = arr2.findIndex(el => el["sfid"] == obj["sfid"]);
    const { answer } = index !== -1 ? arr2[index] : "";
    if (answer) {
      return {
        ...obj,
        answer
      };
    } else {
      return obj
    }
  });
  return res;
};

// use for video and document
export const findLabel = (question) => {
  if (docDataType.includes(question.data_type__c)) {
    return "Document"
  } else if (videoDataType.includes(question.data_type__c)) {
    return "Video"
  } else if (question.doc_upload_required__c && question.data_type__c === "Text") {
    return "Document"
  } else if(question.data_type__c === "Audio"){
    return "Audio"
  }
  return 'Document'
}

// read only first name last name and email
export const readOnly = (question, step) => {
  let read = false
  if (question.read_only__c) {
    return read = question.read_only__c
  } else if(question.encryptable__c ){
    return read = question.answer && question.encryptable__c && question.is_encrypted
  } else  {
    let check = question.salesforce_field_value__c || ''
    return read = check.split(";").some(c => dataTypeReadOnly.includes(c))
  }
  return read
}

// read only first name last name and email
export const readOnlyWihtoutLogin = (question, step) => {
  let read = false
  if (question.read_only__c) {
    return read = question.read_only__c
  } else {
    let check = question.salesforce_field_value__c || ''
    return read = check.split(";").some(c => dataTypeReadOnlyWithoutLogin.includes(c))
  }
  return read
}

export const dateFormat = (format) => {
  if (format === 'Date(Date-Month-Year)') {
    return "d MM yyyy"
  } else if (format === 'Date(Month-Date-Year)') {
    return "MMM d, yyyy"
  }
}

// use for resume page data showing
export const getResumeAnswer = (dataType, data, recommendation = false) => {
  let configAnswer = ''

  const getconfigAnswer = () => {
    if (data.answer === 0) {
      return data.answer.toString();
    }

    if (allDocType.includes(dataType) && (!data.document || data.document.length === 0)) {
      return data.answer__c;
    }

    return data.answer || data.answer__c || '';
  }
  configAnswer = getconfigAnswer()
  if (recommendation) {
    if (allDocType.includes(dataType)) {
     configAnswer= ''
    }
    else{
      configAnswer = data.question_answer__c == 0 ? data.question_answer__c.toString() : data.question_answer__c ? data.question_answer__c.toString() : ""
    }
  }
  if (validateSelectTypeFields.includes(dataType) && data.answer_options__c && data.config_type__c === "Field" && !data.answer_options__c.includes(data.answer)) {
   let answerValue = ''
    if (data.data_type__c == 'Multiselect Picklist') {
      const answers = data.answer || []
      // Check each answer against the answer_options__c array
      const isAnswerInclude = (answer) => data.answer_options__c?.split(';').includes(answer.toString())
      const isValid = typeof answers == 'string' ? isAnswerInclude(answers) : answers?.every(answer =>
        isAnswerInclude(answer)
      );
      if (!isValid) {
        return answerValue;
      }
    } else {
      return answerValue;
    }
  }
  configAnswer = (configAnswer && (['number', 'boolean'].indexOf(typeof configAnswer) > -1)) ? configAnswer.toString() : configAnswer;
  if (data && configAnswer) {
    if (dataTypeJoin.includes(dataType)) {
      if (Array.isArray(configAnswer)) {
        return configAnswer.join(', ');
      } else {
        return data ? configAnswer.split(';').join(', ') : configAnswer
      }
    }
    if (dataType === 'Signature With Timestamp' || dataType === "Signature" || data.answer_type__c === "Signature") {
      return data ? <img loading="lazy" src={configAnswer} /> : ''
    }
    if (dataType === 'Phone') {
      return data ? `${configAnswer}` : ''
    }

    if (isValidHttpUrl(data.answer) && (dataType === 'Video')) {
      return <div className="application-review-attachment" key={data.sfid}>
                <div className="application-review-attachment__main">
                  <h4 className="application-review-attachment__heading">
                    <div className="application-review-attachment__preview">
                      <div className="application-review-attachment__preview-video">
                        <ReactPlayer
                          className={"react-player "}
                          url={data.answer}
                          width='100%'
                          onError={(error) => console.log("error", error)}
                          controls={true}
                        />
                      </div>
                    </div>
                  </h4>
                </div>
              </div>  
    }
    
    if (isValidURL(data.answer) && (dataType === 'Text')) {
      return <div className="application-review-attachment">
        <a href={stringStartWith(data.answer)} target="_blank" alt={data?.answer}> {data.answer} </a>
      </div>

    }
    if (dataType === 'Long Text') {
        return (
          data
            ?
            <pre>{parseHtml(isLongTextContainValidHttpUrl(configAnswer))}</pre>
            :
            ''
        )
    }


    if (dateDataType.includes(dataType)) {
      return convertDataFormat(configAnswer, dataType)
    }
  }
  // if(dataType === "Output Message") {
  //    return data ? data.description__c : ''
  // }
  return parseHtml(data && configAnswer || '')
}


export const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

const stringStartWith = (string) => string.startsWith('http') ? string : `http://${string}`

const isValidURL = (string) => {
  const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
  if (!regex.test(string)) return false;

  try {
    new URL(stringStartWith(string));
    return true;
  } catch (e) {
    return false;
  }
}

export const isLongTextContainValidHttpUrl = (string) => {
  let textWithUrl= string.split(' ').map((word)=>{
    return word.split('\n').map((val)=>{
      return isValidURL(val) ? `<a style='line-break: anywhere;' href=${stringStartWith(val)} target="_blank" alt="Company">${val}</a>` : val
    }).join('\n')
  })
  return textWithUrl.join(' ');
}

var operators = {
  'greater': function (a, b) { return (parseFloat(a) || a) > (parseFloat(b) || 0) },
  'less': function (a, b) { return (parseFloat(a) || a) < (parseFloat(b) || 0) },
  'equals': function (a, b) { return (parseFloat(a) || a) === (parseFloat(b) || 0) },
  'Does Not Contain': function (a, b) { 
    if (!a || a.length < 2) {      
      return false;
    }
    return !a?.includes(b);
  }
};

export const skipConfigIds = (fields, dependent_configs_for_various_steps) => {
  const skipParentField = fields.concat(dependent_configs_for_various_steps).filter(input => input.skip_config_ids__c)

  if (skipParentField) {
    let skipIds = []
    skipParentField.map(skipField => {
      const inputIds = skipField.skip_config_ids__c.split(';')
      let childInputs = filter(fields, function (field) {
        return inputIds.includes(field.name)
      });

      (childInputs || []).map(input => {
        const ansAry = (input.skip_config_answer__c || '').split(';')
        const hideInput = hideInputValue(skipField, ansAry, input)
        if (hideInput) {
          skipIds.push(input.name)
        }
        return null
      })
      return null
    })
    return fields = fields.filter(a => !skipIds.includes(a.name))
  }
  return fields
}

export const splitAnswers = (answer) => {
  return answer ? answer.toString().split(';') : [];
}

export function hideInputValue(skipField, ansAry, child) {
  const skipFieldAnswers = Array.isArray(skipField.answer) ? skipField.answer : splitAnswers(skipField.answer)
  let ishide = false
  if (skipField.data_type__c === 'Multiselect Picklist' || skipField.data_type__c === 'Checkbox') {
    ishide = (intersection((skipFieldAnswers || []), ansAry).length < 1)
  } else {
    if (child.operator_for_skip_config__c) {
      ansAry.map(answer => {
        const val = !(operators[child.operator_for_skip_config__c](skipField.answer, answer))
        ishide = val ? val : ishide
        return null
      })
    } else {
      ishide = !(ansAry.includes(skipField.answer ? skipField.answer : skipField.question_answer__c))
    }
  }
  return ishide
}


export function skipConfigData(skipConfig) {
  if (skipConfig) {
    const skipDep = skipConfig.split(/\r?\n/)
    let anserOptions = {};
    skipDep.map(c => {
      const data = c.split(':')
      const name = data[0]
      anserOptions[name] = data[1] ? data[1] : ''
      return null
    })
    return anserOptions
  } else {
    return {}
  }
}

export const isAnd = (skipConfig) => {
  return (!skipConfig.operator_for_skip_config__c || (skipConfig.operator_for_skip_config__c === "AND"))
}

export const applicationFormAutoSuggestHiddenConfigs = (data = [], filtered = []) => {
  const filteredIds = filtered.map(a => a.sfid)
  let filteredQuestions = [];
  data.map(record => {
    if ((filteredIds.indexOf(record.sfid) < 0) && (record.address_auto_fill_type__c || record.auto_suggest_data__c)) {
      filteredQuestions.push(record)
    }
  })
  filteredQuestions = filteredQuestions.map(record => {
    return {
      sfid: record.sfid,
      answer: record.answer || record.question_answer__c
    }
  })
  return [...filtered, ...filteredQuestions]
}

// skipConfigValue
export const skipConfigValue = (fields, dependent_configs_for_various_steps) => {
  const skipConfigs = fields.filter(input => input.skip_config_value__c)
  if (skipConfigs) {
    let skipIds = []
    skipConfigs.map(skipConfig => {
      let hideSkipConfig = !isAnd(skipConfig);
      const configVal1 = skipConfigData(skipConfig.skip_config_value__c)
      const configIds = Object.keys(configVal1);
      let childInputs = fields.concat(dependent_configs_for_various_steps).filter(input => configIds.includes(input.name))
      let matchAry = [];
      (childInputs || []).map(child => {
        const skipAnsValues = (configVal1[child.name] || '').split(';')
        if (isAnd(skipConfig)) {
          if (child.data_type__c === 'Multiselect Picklist' || child.data_type__c === 'Checkbox') {
            hideSkipConfig = hideSkipConfig ? hideSkipConfig : (intersection((skipAnsValues || []), (child.answer && child.answer.length > 0) ? child.answer : (child.answer || '').split(';')).length < 1)
          } else {
            matchAry.push(skipAnsValues.includes(child.answer))
            hideSkipConfig = hideSkipConfig ? hideSkipConfig : !skipAnsValues.includes(child.answer)
          }
        } else if ((skipConfig.operator_for_skip_config__c === "OR")) {
          if (child.data_type__c === 'Multiselect Picklist' || child.data_type__c === 'Checkbox') {
            hideSkipConfig = hideSkipConfig ? (intersection((skipAnsValues || []), (child.answer && child.answer.length > 0) ? child.answer : (child.answer || '').split(';')).length < 1) : hideSkipConfig
          } else {
            matchAry.push(skipAnsValues.includes(child.answer))
            hideSkipConfig = hideSkipConfig ? !skipAnsValues.includes(child.answer) : hideSkipConfig
          }
        }

        return null
      })
      if (hideSkipConfig) {
        skipIds.push(skipConfig.name)
      }
      return null
    })
    return fields = fields.filter(a => !skipIds.includes(a.name))
  }
  return fields
}

// required_when__c senerio
export const uploadDataTypes = ["Video", "Document", "Audio"];
export const isUpload = (type) => {
  return uploadDataTypes.includes(type)
}


export const checkRequiredWhen = (input) => {
  return (!isUpload(input.data_type__c)) ? !input.answer : ((input.document || []).length < 1)
  // || (input.document && !input.document.doc_url__c)
}

// required_when__c senerio
export const containsVal = (answer, values) => {
  let containVal = false
  values.map(value => {
    containVal = answer && answer.includes(value) ? true : containVal
    return null
  })
  return containVal
}
// required_when__c senerio
export const hasEqualVal = (parent, values) => {
  let hasVal = false
  if (parent.data_type__c === "Multiselect Picklist") {
    const pickAnswer = Array.isArray(parent.answer) ? parent.answer : (parent.answer || '').toString().split(';')
    values.map(value => {
      hasVal = hasVal ? hasVal : (intersection((values), pickAnswer).length > 0)
      return null
    })
  } else {
    values.map(value => {
      hasVal = (parent.answer === value) ? true : hasVal
      return null
    })
  }
  return hasVal
}


// for recommender
export const checkRequiredWhenRecommender = (input) => {
  return (!isUpload(input.answer_type__c)) ? !input.question_answer__c : ((input.document || []).length < 1)
  // || (input.document && !input.document.doc_url__c)
}

// required_when__c senerio
export const containsValRecommender = (question_answer__c, values) => {
  let containVal = false
  values.map(value => {
    containVal = question_answer__c && question_answer__c.includes(value) ? true : containVal
    return null
  })
  return containVal
}
// required_when__c senerio
export const hasEqualValRecommender = (parent, values) => {
  let hasVal = false

  if (parent.answer_type__c === "Multiselect Picklist") {
    let multiAnswer = []
    parent.question_answer__c && parent.question_answer__c.filter(r => multiAnswer.push(r.value))

    values.map(value => {
      hasVal = hasVal ? hasVal : (intersection((values), multiAnswer).length > 0)
      return null
    })
  } else if (parent.answer_type__c === "Checkbox") {
    values.map(value => {
      hasVal = hasVal ? hasVal : parent.question_answer__c.includes(value)
      return null
    })
  } else if (parent.answer_type__c === "Dropdown") {
    values.map(value => {
      hasVal = hasVal ? hasVal : parent.question_answer__c && parent.question_answer__c.value.includes(value)
      return null
    })
  } else {
    values.map(value => {
      hasVal = (parent.question_answer__c === value) ? true : hasVal
      return null
    })
  }
  return hasVal
}



// Formula Field Answer for Applicant
export const updateFormulaFieldAnswer = (fields, dependentConfigs) => {
  const formulaObjs = fields.filter(input => input.data_type__c === 'Formula')
  const ansFormulaObjs = fields.filter(obj => obj.data_type__c === "Answer Dependent Formula")
  const updatedFields = fields.concat(dependentConfigs).filter(a => a)

  formulaObjs.map(formulaObj => {
    if (formulaObj) {
      var str2 = formulaObj.formula_editor__c
      let isCurrency = false
      let currencyName= ''
      updatedFields?.map(a => {
        if (str2?.indexOf(a?.name) > -1) {
          isCurrency = isCurrency ? isCurrency : (a?.data_type__c === 'Currency')
        }
        currencyName = formulaObj?.currency_symbol__c || 'USD'
        var replace = a?.name;
        var re = new RegExp(replace, "g");
        var numberPattern = /\d+/g;
        // const formattedAnswer = ((a.data_type__c === 'Currency') || (a.answer || "0").toString().includes(',')) ? ((a.answer || "0").toString().match(numberPattern) || []).join('') : (a.answer || "0").toString()
        let answer = 0
        if (a.answer && (formulaObj?.currency_symbol__c === 'USD' || !formulaObj?.currency_symbol__c) && (a.data_type__c === "Currency" || a.data_type__c === "Formula")) {
          answer = a.answer.toString().split(',').join('')
        } else if (a.answer) {
          answer = a.answer
        }
        const formattedAnswer = answer

        str2 = str2.replace(re, parseFloat(formattedAnswer));
        return null
      })
      // eslint-disable-next-line
      str2 = str2.includes('Step Config-') ? '0' : str2
      // eslint-disable-next-line
      var finalAnswer = eval(str2).toFixed(2)
      formulaObj.answer = isNaN(finalAnswer) ? 0 : (isCurrency ? formatCurrency(parseFloat(finalAnswer, 10), currencyName) : finalAnswer)
    }
    return null
  }, this)

  ansFormulaObjs.map(formulaObj => {
    if (formulaObj) {
      const isEditor = true
      if (isEditor) {
        var str2 = formulaObj.formula_editor__c
        var formulaObjData = skipConfigData(str2)
        var hasAnswer = false;
        for (var key in formulaObjData) {
          if (formulaObjData.hasOwnProperty(key)) {
            var val = formulaObjData[key];
            updatedFields.map(a => {
              var replace = a?.name;
              var re = new RegExp(replace, "g");
              var numberPattern = /\d+/g;
              const formattedAnswer = (a.answer || a.name).toString()
              val = val.replace(re, formattedAnswer);
              return null
            })
            // eslint-disable-next-line
            if (eval(val)) {
              formulaObj.answer = key
              hasAnswer = true;
              break;
            }
          }
        }
        if (!hasAnswer) {
          formulaObj.answer = '';
        }
      }
    }
    return null
  })
}

// Formula Field Answer For RCQ
export const updateFormulaFieldAnswerRCQ = (fields, dependentConfigs) => {
  const formulaObjs = fields.filter(input => input.answer_type__c === 'Formula')
  const ansFormulaObjs = fields.filter(obj => obj.answer_type__c === "Answer Dependent Formula")
  const updatedFields = fields.concat(dependentConfigs).filter(a => a)

  formulaObjs.map(formulaObj => {
    if (formulaObj) {
      var str2 = formulaObj.formula_editor__c
      let isCurrency = false
      updatedFields.map(a => {
        if (str2?.indexOf(a.name) > -1) {
          isCurrency = isCurrency ? isCurrency : (a.answer_type__c === 'Currency')
        }
        var replace = a?.name;
        var re = new RegExp(replace, "g");
        var numberPattern = /\d+/g;
        // const formattedAnswer = ((a.answer_type__c === 'Currency') || (a.answer || "0").toString().includes(',')) ? ((a.answer || "0").toString().match(numberPattern) || []).join('') : (a.answer || "0").toString()
        let answer = 0
        if (a.question_answer__c && (a.answer_type__c === "Currency" || a.answer_type__c === "Formula")) {
          answer = a.question_answer__c.toString().split(',').join('')
        } else if (a.question_answer__c) {
          answer = a.question_answer__c
        }
        const formattedAnswer = answer

        str2 = str2?.replace(re, parseFloat(formattedAnswer));
        return null
      })
      // eslint-disable-next-line
      str2 = str2?.includes('Step Config-') ? '0' : str2
      // eslint-disable-next-line
      var finalAnswer = eval(str2)?.toFixed(2)
      formulaObj.question_answer__c = isNaN(finalAnswer) ? 0 : (isCurrency ? formatCurrency(parseFloat(finalAnswer, 10)) : finalAnswer)
    }
    return null
  }, this)

  ansFormulaObjs.map(formulaObj => {
    if (formulaObj) {
      const isEditor = true
      if (isEditor) {
        var str2 = formulaObj.formula_editor__c
        var formulaObjData = skipConfigData(str2)
        var hasAnswer = false;
        for (var key in formulaObjData) {
          if (formulaObjData.hasOwnProperty(key)) {
            var val = formulaObjData[key];
            updatedFields.map(a => {
              var replace = a?.name;
              var re = new RegExp(replace, "g");
              var numberPattern = /\d+/g;
              const formattedAnswer = (a.question_answer__c || a.name).toString()
              val = val.replace(re, formattedAnswer);
              return null
            })
            // eslint-disable-next-line
            if (eval(val)) {
              formulaObj.question_answer__c = key
              hasAnswer = true;
              break;
            }
          }
        }
        if (!hasAnswer) {
          formulaObj.question_answer__c = '';
        }
      }
    }
    return null
  })
}

// RollUp Config
export const updateRollUpConfig = (data) => {
  let { fields, iteration_data } = data;
  const rollUpObjs = (fields || []).filter(input => input.data_type__c === 'Roll Up Iterations')

  rollUpObjs.map(formulaObj => {
    if (formulaObj) {
      var str2 = 0
      let questions = iteration_data ? iteration_data.added_questions : []
      let allQuestions = ([].concat.apply([], questions))
      let answerQuestions = allQuestions.filter(a => (a.name === formulaObj.roll_up_iterable_config__c))
      answerQuestions.map(a => {
        var numberPattern = /\d+/g;
        // const formattedAnswer = (((a.data_type__c || a.answer_type) === 'Currency') || (a.answer || "0").toString().includes(',')) ? ((a.answer || "0").toString().match(numberPattern) || []).join('') : (a.answer || "0").toString()

        let answer = 0
        if (a.answer && (a.data_type__c === "Currency" || a.data_type__c === "Formula")) {
          answer = a.answer.toString().split(',').join('')
        } else if (a.answer) {
          answer = a.answer
        }
        const formattedAnswer = answer
        str2 += parseFloat(formattedAnswer);
        return null
      })
      var finalAnswer = parseFloat(str2).toFixed(2)
      formulaObj.answer = finalAnswer
    }
    return null
  })
  return rollUpObjs
}

// recommenderSkip
export const recommenderSkip = (data, fields) => {
  let skip = false;
  data = isEmpty(data) ? {} : data;
  if (data.skip_recommender_config_value__c && data.skip_recommender_operator__c && data.skip_recommender_config_id__c) {
    const allConfigs = fields.concat(data.dependent_configs_for_various_steps)
    const parentConfig = allConfigs.find(obj => (obj.name === data.skip_recommender_config_id__c))

    if (parentConfig) {
      var numberPattern = /\d+/g;
      const formattedInputAnswer = data.skip_recommender_config_value__c.split(';')

      const parentAnswer = (parentConfig.answer)
      const formattedParentAnswer = ((parentAnswer || "0").toString().match(numberPattern) || []).join('')
      switch (data.skip_recommender_operator__c) {
        case 'Less': {
          // eslint-disable-next-line
          formattedInputAnswer.map(answer => {
            skip = skip ? skip : (parseInt(answer) >= parseInt(formattedParentAnswer, 10))
          })
          break;
        }
        case 'Greater':
          // eslint-disable-next-line
          formattedInputAnswer.map(answer => {
            skip = skip ? skip : (parseInt(answer, 10) <= parseInt(formattedParentAnswer, 10))
          })
          break;
        case 'equals':
          formattedInputAnswer.map(answer => {
            skip = skip ? skip : (answer === parentAnswer)
          })
          break;
        case 'contains':
          formattedInputAnswer.map(answer => {
            skip = skip ? skip : (answer.includes(parentAnswer))
          })

          break;
        default:
          skip = false;
      }
    }
  }
  return skip;
}

export const addressFieldsautofill = (newState, data) => {
  const types = ['Country', 'Zip', 'State', 'City']
  let obj, fieldVal;

  types.map((type) => {
    obj = newState.find(input => input.address_auto_fill_type__c === type)
    if (obj) {
      fieldVal = autoFillAddressFields[type]
      obj.answer = data[fieldVal]
    }
    return null
  })
  return newState
}



export const autoPopulateAnswersFromConfig = (field, autoPopulateFromConfigAnswers, questionNum = '') => {
  if (autoPopulateFromConfigAnswers.hasOwnProperty(field)) {
    let str = autoPopulateFromConfigAnswers[field]
    if (Array.isArray(str)) {
      return str[questionNum]
    } else {
      return str || ''
    }
  }
}


export const previewDonor = async (scholarshipId, secondaryColor, token, setSubmitted, user) => {
  let roles = []
  const isApplicant = 'APPLICANT'
  // let user = cookies('context').user
  roles = user.role
  if (roles.includes("Applicant")) {
    setSubmitted(true)
    document.cookie = `context=${isApplicant};path=/`;
    // localStorage.setItem('secondaryColor', secondaryColor)
    setTimeout(() => {
      handleRedirectHrefUrl(`/scholarships/${scholarshipId}`)
    }, 2000);
  } else {
    let dataObj = {
      user_email: user.email,
      user_role: 'Applicant',
    }
    let result = await API.promoteUser({ token: token, data: dataObj, context: 'DONOR' })
    if (result.success) {
      setSubmitted(true)
      user.role.push('Applicant')
      document.cookie = `user=${JSON.stringify(user)};path=/`;
      document.cookie = `context=${isApplicant};path=/`;
      // localStorage.setItem('secondaryColor', secondaryColor)
      setTimeout(() => {
        handleRedirectHrefUrl(`/scholarships/${scholarshipId}`)
      }, 2000);
    } else {
      toast.error(<FlashMessage type="error" message={result.message} />)
    }
  }
}

export const roleSwitch = async (scholarshipId, token, user, switchRole) => {

  if (switchRole) {
    document.cookie = `context=APPLICANT;path=/`;
    // localStorage.setItem('secondaryColor', secondaryColor)
    setTimeout(() => {
      handleRedirectHrefUrl(`/scholarships/${scholarshipId}`)
    }, 2000);
  } else {
    let dataObj = {
      user_email: user.email,
      user_role: 'Applicant',
    }
    let result = await API.promoteUser({ token: token, data: dataObj, context: 'ANY' })
    if (result.success) {
      user.role.push('Applicant')
      document.cookie = `user=${JSON.stringify(user)};path=/`;
      document.cookie = `context=APPLICANT;path=/`;
      // localStorage.setItem('secondaryColor', secondaryColor)
      setTimeout(() => {
        handleRedirectHrefUrl(`/scholarships/${scholarshipId}`)
      }, 2000);
    } else {
      toast.error(<FlashMessage type="error" message={result.message} />)
    }
  }
}

export const hasIncompletedFields = (data) => {
  let flag = false;
  const skipForm = recommenderSkip(data, data.fields);
  let dataObj = skipConfigIds(data.fields, data.dependent_configs_for_various_steps)
  let skipConfigValueField = skipConfigValue(dataObj, data.dependent_configs_for_various_steps)

  const userContext = cookies('context').context
  dataObj = hideQuestionFiltered(skipConfigValueField, userContext)

  if (data.enable_iteration__c && !data.heroku_role__c && data.minimum_record_required__c) {
    skipConfigIds(data.iteration_data.added_questions, data.dependent_configs_for_various_steps).concat(...dataObj).map((field) => {
      if (((isUpload(field.data_type__c)) && (field.doc_upload_required__c || field.is_required__c || field.minimum_doc_upload_required__c)) ? ((isEmpty(field.document) || isEmpty(field.document.url)) && (field.document || []).length < 1) : ((field.required__c) && isEmpty((field.answer || '').toString()) && ((isEmpty(field.document) || isEmpty(field.document.url)) && (field.document || []).length < 1))) {
        flag = true
      }
      return null
    })

    if (data.iteration_data && data.iteration_data.added_questions) {
      flag = flag ? flag : data.iteration_data.added_questions.length < 1
    }
  } else if (data.enable_iteration__c && data.heroku_role__c && data.minimum_record_required__c && !skipForm) {
    if ((data.references || []).length < data.minimum_record_required__c) {
      flag = flag ? flag : true
    }
  } else if (data.payment_step__c) {
    flag = flag ? flag : isEmpty(data.payment_step_info)
  } else {
    dataObj.map((field) => {
      if (field.required_when__c && checkRequiredWhen(field)) {
        const parent = dataObj.concat(data.dependent_configs_for_various_steps || []).find(obj => (obj.label__c && (obj.label__c.toString() === field.config_name__c)))
        const configValues = (field.config_value__c || '').split(';')
        if (parent) {
          if ((field.operator__c === 'contains') && containsVal(parent.answer, configValues)) {
            flag = true
          } else if ((field.operator__c === 'equals') && hasEqualVal(parent, configValues)) {
            flag = true
          }
        }
      } 
      else
        if (((isUpload(field.data_type__c)) && (field.doc_upload_required__c || field.is_required__c || field.minimum_doc_upload_required__c)) ? ((isEmpty(field.document) || isEmpty(field.document.url)) && (field.document || []).length < 1) : ((field.required__c) && isEmpty((field.answer == 0 ? field.answer : field.answer || '').toString()) && ((isEmpty(field.document) || isEmpty(field.document.url)) && (field.document || []).length < 1) && field.config_type__c !== "Message")) {
          flag = true
        }
        else if(allDocType.includes(field.data_type__c) && (field?.document || []).length < field?.minimum_doc_upload_required__c){
          flag = true
        }
        else if(field.data_type__c ===  "Number" && field.number_input_required_min_length__c && !!field.answer){
          if ( field.answer.length < field.number_input_required_min_length__c){
            flag = true
          }
        }else if(!field.exit_config__c && field.answer && (field.max_date__c && field.min_date__c)){
            let currentDate = new Date(field.answer).setHours(0, 0, 0, 0);
            let minDate = new Date(field.min_date__c).setHours(0, 0, 0, 0);
            let maxDate = new Date(field.max_date__c).setHours(0, 0, 0, 0);
            let finalDate = currentDate >= minDate && currentDate <= maxDate;
            if (!finalDate) {
              flag = true
          }
        } else if (field.required__c && field.config_type__c === "Field" && field.salesforce_field_value__c && validateSelectTypeFields.includes(field.data_type__c) && field.answer_options__c && !field.answer_options__c?.split(';').includes(field.answer)) {
          if (field.data_type__c == 'Multiselect Picklist') {
            const answers = field.answer || []
            // Check each answer against the answer_options__c array
            const isAnswerInclude = (answer) => field.answer_options__c?.split(';').includes(answer.toString())
            const isValid = typeof answers == 'string' ? isAnswerInclude(answers) : answers?.every(answer =>
              isAnswerInclude(answer)
            );
            if (!isValid) {
              flag = true
            }
          } else {
            flag = true
          }
        } else if ((field.data_type__c === 'Long Text') && field.word_count_enable__c) {
          const minWords = field.minimum_word_required__c || 0
          const words = ((field.answer || "").match(/\S+/g) || []).length || 0;
          if (words < minWords) {
            flag = true
          }
        }
      return null
    })
  }

  return flag
}

export const getUrlExtension = (url) => {
  return url ? url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase() : "";
}

export const allowApplicantUploadsAfterDeadlineFunction = (type) => {
  return !allDocType.includes(type)
}


export const hideQuestionFiltered = (data, userContext) => {
  return data.filter(a => (a.hide_config_for_roles__c || '').split(';').map(a => a.toLowerCase()).indexOf(userContext ? userContext.toLowerCase() : userContext) < 0)
}

export const blankSpaceCheck = (val1, val2) => {
  if (val1.startsWith(" ") || val2.startsWith(" ")) {
    return true
  } else {
    return false
  }
}

export const checkFieldLength = (field_value, field_length) => {
  return field_value.length > field_length
}

export const clearShowBackButtonSessionStorage = () => {
  return sessionStorage.removeItem('showBackButton');
}
export const handleKeyDown = (event,callBack) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    callBack(); // Call the onClick function when Enter is pressed
  }
};
export const alretMsg = (val = '', setIsOpenSweetAlert, setDataLoad, isModalOpen, pageType, callBack = () => { }, title = 'Are you sure you want to exit?', unsubscribeModal = false, showCancel = true) => {
  function getMessage() {
    if (
      pageType === MAIN_CONFIG.PAGE_TYPES.APPLICANT.RESUME &&
      window.location?.search?.split('=')?.includes('submit')
    ) {
      return <p className="CTAGrey">Your application will not be considered until you click Submit.</p>;
    } else if (isModalOpen) {
      return <p className="CTAGrey">{iterationModalCloseMsg}</p>;
    } else if (unsubscribeModal) {
      return (<>
      <p className="CTAGrey unsubscribed-message">You have successfully unsubscribed and will no longer receive emails from Kaleidoscope. This includes any emails related to a program, application, or recommendation.</p>
      <p className="CTAGrey">These settings can be updated at any time from your profile.</p>
      </>)
    }
    else {
      return <p className="CTAGrey">All changes will be lost if you leave this page without saving.</p>;
    }
  }
  const getAlert = () => (
        <div className="save-changes-confirmation-alert modal-utility">
            <SweetAlert
                showConfirm={true}
                confirmBtnText="Okay"
                showCancel={showCancel}
                cancelBtnText={showCancel ? "Cancel" : ''}
                customButtons={
                  <div className='action-footer'>
                    {showCancel && <button onClick={(e) => setIsOpenSweetAlert(false)} className='cta-button CTABlack cta-button__text'>Cancel</button>}
                    <button onClick={(e) => {
                    localStorage.removeItem("isModalOpen")
                    localStorage.removeItem('hasAccessCode')
                    localStorage.removeItem('accessCode')
                    callBack()
                    setIsOpenSweetAlert(false)
                    setDataLoad(true)
                }} className='cta-button CTAWhite tertiary cta-button__text'>Ok</button>
                  </div>
                }
                className=""
            >
              <div style={{display: 'flex'}}>
                <div>
                  <Alert />
                </div>
                <div className='content'>
                  <p className='title'>{title}</p>
                  { getMessage() }
                </div>
              </div>
            </SweetAlert>
        </div>
);
  
  setIsOpenSweetAlert(getAlert)
}

export const getDynamicClassName = (getTrue, firstClass, secondClass) => {
  if(getTrue){
      return firstClass;
  }else{
     return secondClass
  }
}

export const renderNoDataDescription = (isNoDataExist, isSearching, emptyTableMessage) => {
  const noDataMessage = isSearching ? "Not found" : emptyTableMessage;
  return isNoDataExist ? noDataMessage : "Nothing To Show";
};
export const clearSessionFilters= (clear) =>{
 if(clear){
  sessionStorage.removeItem("applyFilters")
  sessionStorage.removeItem("appliedFiltersTab")
 }
}

export const filterClassicPrograms = (data, filterClassic = false) => {
  return data
    .filter(item => filterClassic ? !item.elements_program__c : true)
    .map(({ value, label }) => ({ value, label }));
};
