import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import './AddressSearchField.scss';


const isObject = val => {
  return typeof val === 'object' && val !== null;
};

export const classnames = (...args) => {
  const classes = [];
  args.forEach(arg => {
    if (typeof arg === 'string') {
      classes.push(arg);
    } else if (isObject(arg)) {
      Object.keys(arg).forEach(key => {
        if (arg[key]) {
          classes.push(key);
        }
      });
    } else {
      throw new Error(
        '`classnames` only accepts string or object as arguments'
      );
    }
  });

  return classes.join(' ');
};

class AddressSearchField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      address: props.value || '',
      errorMessage: '',
      latitude: null,
      longitude: null,
      isGeocoding: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ address: nextProps.value })
  }

  handleChange = address => {
    // this.props.controlAddressFunc(this.props.fieldObj, address)
    this.setState({
      address,
      latitude: null,
      longitude: null,
      errorMessage: '',
    });
  };

  handleSelect = selected => {
    const { fieldObj } = this.props;
    this.setState({ isGeocoding: true, address: selected });
    geocodeByAddress(selected)
      .then(res => getLatLng(res[0]))
      .then(({ lat, lng }) => {
        // this.props.controlAddressFunc(this.props.fieldObj, selected)
        this.props.handleAddressSelection(lat, lng, fieldObj, selected)
        this.setState({
          latitude: lat,
          longitude: lng,
          isGeocoding: false,
        });
      })
      .catch(error => {
        this.setState({ isGeocoding: false });
      });
  };

  handleCloseClick = () => {
    this.setState({
      address: '',
      latitude: null,
      longitude: null,
    });
  };

  handleError = (status, clearSuggestions) => {
    this.setState({ errorMessage: status }, () => {
      clearSuggestions();
    });
  };

  render() {
    const {
      address,
      errorMessage,
      latitude,
      longitude,
      isGeocoding
    } = this.state;
    const { name = '' } = this.props;

    return (
      <div>
        <PlacesAutocomplete
          onChange={this.handleChange}
          value={address}
          onSelect={this.handleSelect}
          onError={this.handleError}
        // shouldFetchSuggestions={address.length > 2}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            return (
              <div className="address__search-bar-container">
                <div className="address__search-input-container">
                  <input
                    {...getInputProps({
                      placeholder: 'Search Places',
                      className: 'address__search-input',
                      name: name,
                      disabled: this.props.isDisabled
                    })}
                  />
                </div>
                {suggestions.length > 0 && (
                  <div className="address__autocomplete-container address-selector">
                    {suggestions.map(suggestion => {
                      const className = classnames('address__suggestion-item', {
                        'address__suggestion-item--active': suggestion.active,
                        'suggest-val': true,
                      });

                      return (
                        /* eslint-disable react/jsx-key */
                        <div
                          {...getSuggestionItemProps(suggestion, { className })}
                        >
                          {/* <i className="fa fa-map-marker"></i> */}
                          <div className="address__suggestion-item-big-text">
                            {suggestion.formattedSuggestion.mainText}
                          </div>{' '}
                          <div className="address__suggestion-item-small-text">
                            {suggestion.formattedSuggestion.secondaryText}
                          </div>
                        </div>
                      );
                      /* eslint-enable react/jsx-key */
                    })}
                    <div className="address__dropdown-footer">
                      <div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        </PlacesAutocomplete>
        {errorMessage.length > 0 && (
          <div className="address__error-message">{this.state.errorMessage}</div>
        )}

        {((latitude && longitude) || isGeocoding) && (
          <div>
            {isGeocoding ? (
              <div>
                <i className="fa fa-spinner fa-pulse fa-3x fa-fw address__spinner" />
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default AddressSearchField;