import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "18", height = "18", iconColor = MAIN_CONFIG.COLORS.fillBlack }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 18">
            <title>Percent</title>
            <desc>Percent</desc>
            <g id="Icon_feather-percent" data-name="Icon feather-percent" transform="translate(-3 -3)">
                <path id="Path_1" data-name="Path 1" d="M19,5,5,19" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <path id="Path_2" data-name="Path 2" d="M9,6.5A2.5,2.5,0,1,1,6.5,4,2.5,2.5,0,0,1,9,6.5Z" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <path id="Path_3" data-name="Path 3" d="M20,17.5A2.5,2.5,0,1,1,17.5,15,2.5,2.5,0,0,1,20,17.5Z" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </g>
        </svg>
    );
};
