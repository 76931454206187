import React, { useState, useEffect } from 'react';
import './logged-out-header.scss';
import WordMark from '../../../Components/SVG/WordMark';
import Hamburger from '../../../Components/SVG/Hamburger';
import { MAIN_CONFIG } from '../../../../config/main';
import FullScreenMenu from '../../../Components/FullScreenMenu/FullScreenMenu';
import cookies from 'next-cookies';
import { useRouter } from 'next/router';
import { isEmpty } from 'lodash';


// TODO: Update this with logged-out style once available
export default ({ pageType, isMobile, hideLogin = false, setShowApplicantOnboarding, isShowSightIn = false }) => {
    
    const [menuOpen, setMenuOpen] = useState(false);
    const router = useRouter();
    const [isAdminLogin, setIsAdminLogin] = useState(false);
    const token = cookies('context').token;
    const toggleMenu = () => setMenuOpen(!menuOpen);

    const handleClick = (e) => {
        e.preventDefault()
        router.push('/', undefined, { shallow: true })
        document.cookie = 'marketPlace=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    }

    useEffect(() => {
        if (window.location.pathname === '/admin' || window.location.pathname === "/sponsor-signup") {
            setIsAdminLogin(true)
        }
    }, [])

    /**
     * Show the right side of the menu based on page type.
     * @returns {JSX.Element}
     */
    const renderMenuRight = () => {
        let hideMarketplace = ['SALESFORCE_CALLBACKL', 'HOME', 'signup']
        let hideLoginButton = ['SALESFORCE_CALLBACKL', 'LOGIN']
        return (
            <>
                {
                    !isMobile &&
                    !isAdminLogin &&
                    !hideMarketplace.includes(pageType) &&
                    <a href={MAIN_CONFIG.URLS.BASE} id='marketplace-icon' className='logged-out-header__sign-in-cta CTABlack sign-in-text'>
                        <img src={MAIN_CONFIG.STATIC_IMAGE_URL.Marketplace_Icon} />
                        Marketplace
                    </a>
                }
                {
                    !hideLogin &&
                    !isAdminLogin &&
                    !isShowSightIn &&
                    !hideLoginButton.includes(pageType) &&
                    <a href={MAIN_CONFIG.URLS.LOGIN} className='logged-out-header__sign-in-cta CTABlack sign-in-text'>{`Sign in ${pageType == 'signup' ? '' : '/ Sign up'}`}</a>
                }
            </>
        )
    }
    return (
        <>
            <div id='logged-out-header__inner'>
                <div id='logged-out-header__left'>
                </div>
                <div id="logged-out-header__middle">
                    <a className="logo-pointer" onClick={(e) => handleClick(e)}>
                        <WordMark id='logged-out-header__brand-mark' ariaLabel="Kaleidoscope Logo" ariaLabelDescription="" color={MAIN_CONFIG.COLORS.fillWhite} width={180} height={50}/>
                    </a>
                </div>
                <nav id='logged-out-header__right' role="navigation" aria-label="Signin">
                    {isEmpty(token) ? renderMenuRight() : ''}
                </nav>
            </div>
            {menuOpen ? <FullScreenMenu items={MAIN_CONFIG.HEADER.LINKS} closeBtnClick={toggleMenu} /> : null}
        </>
    );
}
