import React from 'react';
import './percent-arrow.scss';
import DrawnArrow from '../SVG/DrawnArrow';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ value, label = '' }) => {
    const isPositive = value >= 0;
    const { fillGreen, fillRed } = MAIN_CONFIG.COLORS;

    return (
        <span className='percent-arrow'>
            <DrawnArrow color={isPositive ? fillGreen : fillRed} className={`percent-arrow__icon ${isPositive ? 'up' : 'down'}`} />
            <span className={`percent-arrow__text ${isPositive ? 'CTAGreen' : 'CTARed'}`}>{`${value ? Math.abs(value) : 0}% ${label}`}</span>
        </span>
    );
}
