import React, { Component } from 'react';
import Head from 'next/head';
import AppLayout from '../Layout/AppLayout/AppLayout';
import { MAIN_CONFIG } from '../../config/main';
import cookies from 'next-cookies';
import PageUserData from './PageUserData';
import jwtdecode from 'jwt-decode';
import 'react-toastify/dist/ReactToastify.css';
import Error from "next/error"
import { isEmpty } from 'lodash';



const AppPage = (ContentComponent) => {
    return class P extends Component {

        static async getInitialProps(context, pageType) {
            // try {
                var authToken = cookies(context).token || '';
                var authAdminToken = cookies(context).admintoken || '';
                if (authToken) {
                    const data = jwtdecode(authToken)
                    // get in milliseconds
                    var now = (new Date()).getTime();
                    if ((now - parseInt(`${data.exp}000`, 10)) >= 0) {
                        authToken = null;
                    }
                }
                if (authAdminToken) {
                    const data = jwtdecode(authAdminToken)
                    // get in milliseconds
                    var now = (new Date()).getTime();

                    if ((now - parseInt(`${data.exp}000`, 10)) >= 0) {
                        authAdminToken = null;
                    }
                }
                // const user = cookies(context).user;
                const currentAccountId = cookies(context).currentAccount || '';
                let userContext = cookies(context).context || '';
                let userAdminContext = cookies(context).admincontext || '';
                let pageData = {};
                let accountData = {};

                var authPage = ['NEW_SCHOLARSHIP', 'ACCOUNT', 'EDIT_SCHOLARSHIP', 'ACCOUNT_SWITCH', 'ROLE_SWITCH', 'SCHOLARSHIP_REVIEWERS']
                var checkPAge = ['LOGIN', "SIGNUP", "RESET_PASSWORD", "SUPER_ADMIN", "SUPER_ADMIN_LOGIN", "SALESFORCE_CALLBACKL", "SPONSORSIGNUP", "PARENTALCONSENT"]
                if (authToken) {
                    // User is logged in
                    var checkCommomPage = ['APPLICATIONS', 'HOME', 'CALLBACK', 'ADMIN']
                    if (checkPAge.includes(pageType)) {
                        pageData = await PageUserData({ pageType : 'HOME', userContext, routerContext: context });
                        accountData = {
                            token: authToken,
                            availableAccounts: [],
                            currentAccount: null,
                            sectionVisibilityOptions: null,
                            branding: null,
                            user: pageData.userInfo
                        };
                    } else{
                        pageData = await PageUserData({ authToken, pageType, userContext, routerContext: context, currentAccountId });
                        if(pageData?.statusCode === 503){
                            accountData = {
                                token: null,
                                availableAccounts: [],
                                currentAccount: null,
                                sectionVisibilityOptions: null,
                                branding: null,
                                user: null
                            };
                        }else{
                            accountData = {
                                token: authToken,
                                availableAccounts: pageData?.accounts,
                                currentAccount: pageData?.account,
                                sectionVisibilityOptions: pageData?.section_visibility_options,
                                branding: pageData?.persona_branding || '',
                                user: pageData?.userInfo
                            };
                        }
                    }

                } else if(authAdminToken && userAdminContext === MAIN_CONFIG.USER_CONTEXT.ADMIN){
                    pageData = await PageUserData({ pageType: 'HOME', userContext: userAdminContext, routerContext: context, authAdminToken });
                    accountData = {
                        token: authAdminToken,
                        availableAccounts: [],
                        currentAccount: null,
                        sectionVisibilityOptions: null,
                        branding: null,
                        user: pageData.userInfo
                    };
                } else {
                    if(authPage.includes(pageType)){
                        pageData = { statusCode: 503, notifications: [] };
                    }else{
                        // User is not logged in
                        const { res } = context;
                        userContext = MAIN_CONFIG.USER_CONTEXT.LOGGED_OUT;
                        userAdminContext = MAIN_CONFIG.USER_CONTEXT.LOGGED_OUT;
                        const marketPlaceData = await PageUserData({ pageType, userContext, routerContext: context });
                        pageData = (pageType === MAIN_CONFIG.PAGE_TYPES.SSO_AUTHENTICATION) ? await PageUserData({ pageType, userContext, routerContext: context }) : { ...pageData, notifications: [], marketPlaceData,  ...marketPlaceData };
                    }
                    accountData = {
                        token: null,
                        availableAccounts: [],
                        currentAccount: pageData?.marketPlaceData?.account || null,
                        sectionVisibilityOptions: null,
                        branding: null,
                        user: null
                    };
                }

                return {
                    currentPath: context.pathname,
                    accountData,
                    pageData: { ...pageData, pageType },
                    userContext,
                    userAdminContext,
                    pageType
                };

            // } catch (e) {
            //     console.log("---------------------------",e.message)
            //     return {
            //         statusCode: ''
            //     };
            //     // throw new Error(e);
            // }
        }

        render() {
            // <Error {...this.props} />
            const { noLayout, pageData = { heroku_role: [], notifications: [] }, accountData, currentPath, userContext, userAdminContext, pageType, url } = this.props;
            let updatedProps={...this.props}
            let pathName
            if(typeof window !== 'undefined'){
                pathName = window.location.pathname
            }
            let redirectUrl = url && url?.asPath || pathName 
            var checkPage = ['LOGIN', "SIGNUP", "RESET_PASSWORD", "SSO_AUTHENTICATION", "SUPER_ADMIN", "SUPER_ADMIN_LOGIN", "SALESFORCE_CALLBACKL", "SPONSORSIGNUP", "PARENTALCONSENT","HOME", "CALLBACK",'LOGOUT_CONFIRM',"ROLE_SWITCH"]
            if((userContext===MAIN_CONFIG.USER_CONTEXT.LOGGED_OUT || !userContext ) && (!checkPage.includes(pageType))){
                if(typeof sessionStorage !== 'undefined'){
                    sessionStorage.setItem('previousPath', redirectUrl)
                }
                updatedProps={...updatedProps,previousPath:redirectUrl}
            }
            if(pageData.statusCode === 200){
                if (noLayout) {
                    return(
                        <>
                            <ContentComponent {...updatedProps} />;
                        </>
                    )
                } else {
                    return (
                        <AppLayout userContext={userContext} userAdminContext={userAdminContext} pageData={pageData} accountData={accountData} currentPath={currentPath} pageType={pageType} previousPath={updatedProps?.previousPath || ''}>
                            <ContentComponent {...updatedProps} />
                        </AppLayout>
                    );
                }
            }else{
                return (<AppLayout userContext={userContext || MAIN_CONFIG.USER_CONTEXT.LOGGED_OUT} userAdminContext={null} pageData={pageData} accountData={accountData} currentPath={currentPath} pageType={pageType || MAIN_CONFIG.PAGE_TYPES.LOGIN} previousPath={updatedProps?.previousPath || ''}>
                            <ContentComponent {...updatedProps} />
                        </AppLayout>
                    )
            }
        }
    }
};

export default AppPage;
