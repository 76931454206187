import React, { useState } from 'react';
import router, { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import "./DonorSidebar.scss";
import { handleRedirectHrefUrl } from '../../Utility/HelperFunctions';

const DonorSidebar = ({ 
    menuItems,
    activeDonorSidebarTab,
    setActiveDonorSidebarTab 
    
    }) => {

    const handleMenuItemClick = (item) => {
        if (item.disable) {
            return;
        }
        if (item.isExternal) {
            setActiveDonorSidebarTab(item.label)
            handleRedirectHrefUrl(item.link);
        } else {
            setActiveDonorSidebarTab(item.label)
            router.push(item.link)
        }
    };

    return (
        <div className="donor-sidebar">
            <div className="donor-sidebar-left-section">
            <div className='icon-with-heading'>
            <img src="/static/images/image59.svg" alt="manage" width="25" height="25" />
            <h1>Manage</h1>
            </div>
                <ul>
                    {menuItems.map((item, index) => (
                       <li
                            key={index}
                            className={`CTAWhite ${item.label === activeDonorSidebarTab ? "active-current-page" : ""} ${item.disable ? "disable" : ""}`}
                            onClick={() => handleMenuItemClick(item, index)}
                            tabIndex={0}
                            aria-label={item.label}
                            disabled={item.disable}
                            title={`${item.disable ? `You do not have permission to access the ${item.label} page` : ""} `}
                        >
                            {item.label} {item.disable}
                       </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

DonorSidebar.propTypes = {
    title: PropTypes.string,
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string,
        isExternal: PropTypes.bool,
        disable: PropTypes.bool
    })),
    activeDonorSidebarTab: PropTypes.string,
    setActiveDonorSidebarTab: PropTypes.func
};

export default DonorSidebar;
