import React, { useEffect, useRef } from 'react';
import './modal.scss';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

/**
 * Global modal.
 * @param {string} id
 * @param {string} className
 * @param {object[]} children
 * @param {function} setShowModalBackground - This is required. Passed from AppLayout.
 * @returns {JSX.Element}
 */
export default ({ id, className, children, isMobile, isIpad }) => {
    const modal = useRef(null);

    useEffect(() => {
        modal.current.focus()
        if (!isMobile || !isIpad) {
            // disableBodyScroll(modal);
            enableBodyScroll(modal);
        }
        // else {
        //     enableBodyScroll(modal);
        // }
        return () => {
            enableBodyScroll(modal);
        }
    }, []);


    return (
        <>
            <div ref={modal} id={id} className={`modal ${className}`} tabIndex={1}>
                <div className='modal__inner'>
                    {children}
                </div>
            </div>
        </>
    );
};
