export function currentTabIndexAction(index = 0) {
    return {
        type: "CURRENT_TAB_INDEX_ACTION",
        index: index
    }
}


export function currentItemPerPage(itemPerPage) {
    return {
        type: "CURRENT_ITEM_PER_PAGE",
        itemPerPage: itemPerPage
    }
}

export function backButtonClicked(isClicked){
    return {
        type : "BACK_BUTTON_CLICKED",
        isClicked : isClicked
    }
}

export function manageFilters(filters){
    return {
        type : "MANAGE_FILTER_DATA",
        filters : filters
    }
}

export function uploadPercentage(percentage){
    return {
        type : "UPLOAD_PERCENT",
        percentage : percentage
    }
}
export function updateModalState(payload){
    return {
        type : "UPDATE_SECONDARY_MODAL_STATE",
        payload : payload
    }
}