import React from 'react';

export default ({ id, className, color = '#FFF', backgroundColor = 'none', height = 24, width = 24, ariaLabel = "Arrow Down", ariaLabelDescription = "Arrow Down" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <g fill={backgroundColor} fillRule="evenodd">
                <g fill={color}>
                    <g>
                        <path d="M5 9L12.029 15 19 9z" transform="translate(-1366 -28) translate(1366 28)" />
                    </g>
                </g>
            </g>
        </svg>

    );
}
