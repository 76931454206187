import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "32", height = "32", ariaLabel = "Globe", ariaLabelDescription = "Globe" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-16 -12) translate(16 12)">
                        <g fill={color}>
                            <path d="M17.2 4.237c.63.317 1.213.7 1.748 1.133l-3.57 1.177c-1.263-1.751-2.725-2.917-4.1-3.216.527-.088 1.056-.133 1.585-.133 1.49 0 2.968.349 4.336 1.039zm4.757 5.422l-4.295 1.416c-.542-1.594-1.256-3.024-2.064-4.212l3.68-1.213c1.21 1.076 2.131 2.445 2.68 4.01zm.338 5.17l-3.669 1.209c-.06-1.463-.348-3.037-.85-4.612l4.297-1.416c.498 1.615.55 3.267.222 4.819zm-5.021 6.603c.616-.704 1.045-1.751 1.245-3.077.091-.608.126-1.258.116-1.932l3.561-1.173c-.674 2.612-2.428 4.899-4.922 6.182zM6.765 20.335l3.557-1.172c.394.548.81 1.047 1.247 1.482.95.949 1.917 1.535 2.833 1.734-2.77.452-5.54-.344-7.637-2.044zM3.75 16.047l4.295-1.415c.532 1.564 1.236 3.002 2.057 4.215l-3.666 1.208C5.249 19 4.309 17.643 3.75 16.047zm-.338-5.17l3.674-1.21c.056 1.436.332 3.011.844 4.615l-4.296 1.415c-.498-1.615-.55-3.267-.222-4.82zm5.046-6.615c-.927 1.057-1.409 2.863-1.383 5.02l-3.564 1.175c.677-2.62 2.439-4.914 4.947-6.195zm2.944 3.595L10.029 3.69c.192-.048.39-.071.592-.071 1.421 0 3.023 1.152 4.4 3.045L11.4 7.857zm1.57 4.764l-1.455-4.413 3.726-1.228c.806 1.177 1.524 2.61 2.068 4.21l-4.34 1.43zm1.569 4.763l-1.455-4.413 4.339-1.43c.504 1.582.786 3.16.835 4.618l-3.72 1.225zm-.235.466l1.372 4.166c-1.148.28-2.506-.291-3.848-1.632-.397-.397-.778-.848-1.14-1.342l3.616-1.192zm-1.57-4.764L14.19 17.5l-3.724 1.226c-.826-1.201-1.536-2.64-2.07-4.21l4.34-1.43zm-1.57-4.763l1.455 4.413-4.34 1.43c-.515-1.611-.79-3.19-.84-4.615l3.725-1.228zM9.678 3.807l1.373 4.165-3.618 1.193c-.02-2.672.768-4.74 2.245-5.358zm8.476 14.494c-.283 1.876-1.035 3.143-2.125 3.6l-1.373-4.167 3.613-1.19c.004.612-.032 1.202-.115 1.757zM9.718 3.337c-5.247 1.73-8.11 7.405-6.38 12.652 1.386 4.21 5.314 6.885 9.523 6.885 1.037 0 2.091-.162 3.128-.504 5.248-1.729 8.11-7.405 6.381-12.652-.837-2.542-2.615-4.606-5.004-5.811-2.39-1.205-5.106-1.408-7.648-.57z" transform="translate(4.677 .492)" />
                            <path d="M20.562 21.32c-2.086 1.901-4.79 2.96-7.617 2.983-.06-.01-.122-.01-.181 0-6.266-.049-11.361-5.182-11.361-11.45 0-6.313 5.137-11.45 11.45-11.45.387 0 .702-.315.702-.702 0-.386-.315-.701-.702-.701C5.766 0 0 5.766 0 12.853c0 6.862 5.323 12.465 12.152 12.833v2.202h-1.91c-.634 0-1.15.515-1.15 1.149v1.767c0 .102.083.184.185.184h7.153c.102 0 .185-.082.185-.184v-1.767c0-.634-.516-1.149-1.15-1.149h-1.91v-2.201c2.95-.16 5.767-1.339 7.952-3.33.286-.26.307-.704.046-.99-.26-.286-.704-.306-.99-.046" transform="translate(4.677 .492)" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}


