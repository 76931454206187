import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "22.5", height = "22.5", iconColor = MAIN_CONFIG.COLORS.fillBlack }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22.5 22.5">
            <title>Multiple Choice</title>
            <desc>Multiple Choice</desc>
            <g id="Multiple_Choice" data-name="Multiple Choice" transform="translate(0.75 0.75)">
                <circle id="Oval" cx="10.5" cy="10.5" r="10.5" fill="none" stroke={color} stroke-miterlimit="10" stroke-width="1.5" />
                <g id="Icon_System_Checkmark" data-name="Icon/System/Checkmark" transform="translate(5 6.5)">
                    <path id="Path_31_Copy_2" data-name="Path 31 Copy 2" d="M3.7,5.5,1.248,3.062,0,4.264,3.572,7.822l8.106-6.5L10.57,0Z" transform="translate(0.376 0.337)" fill={color} />
                </g>
            </g>
        </svg>
    );
};
