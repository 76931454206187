import React, { useState } from 'react';
import './AdminSidebar.scss';
import { MAIN_CONFIG } from '../../../../config/main';
import WordMark from '../../../Components/SVG/WordMark';
import BrandMark from '../../../Components/SVG/BrandMark';
import MenuIcon from '../../../Components/SVG/MenuIcon';
import Dashboard from '../../../Components/SVG/Dashboard';
import Person from "../../../Components/SVG/Person"
import Userinfo from '../../../Components/SVG/Userinfo';
import Promote from '../../../Components/SVG/Promote';
import Emailhistory from '../../../Components/SVG/Emailhistory';
import Update from '../../../Components/SVG/Update';
import Duplicate from '../../../Components/SVG/Duplicate';
import Password from '../../../Components/SVG/Password';
import History from '../../../Components/SVG/History';
import Review from '../../../Components/SVG/Review';
import Client from '../../../Components/SVG/Client';
import User from '../../../Components/SVG/User';
import Logout from '../../../Components/SVG/Logout';
import SsoIcon from '../../SVG/SsoIcon';
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import Loader from '../../Loader/Loader';
import Book from '../../SVG/Book';

export default ({ accountData, activeTab, setActiveTab, userContext, setToggleHamburgerMenu, toggleHamburgerMenu }) => {

    const API = new KaleidoscopeAPI({});

    const activeColor = (type) => {
        return type === activeTab ? 'CTABlue' : 'CTAWhite';
    }
    const activeBackground = (type) => {
        return type === activeTab ? 'admin-sidebar__menu__item active' : 'admin-sidebar__menu__item ';
    }
    const iconColor = (type) => {
        return type === activeTab ? MAIN_CONFIG.COLORS.fillBlue : MAIN_CONFIG.COLORS.fillWhite;
    }
const [loader,setLoader]=useState(false)

    /**
     * Delete token cookie to logout and redirect to login page.
     */
    const handleLogout = async () => {
        setLoader(true)
        await API.signOut(accountData?.token);

        document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'admintoken=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'context=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'admincontext=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'user=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'adminuser=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'currentAccount=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        localStorage.clear();
        setTimeout(() => {
        setLoader(false)
            window.location.href = MAIN_CONFIG.URLS.LOGIN;;
        }, 2000);

    }
    return (
        <>
        <Loader loading={loader}/>
            <input type="checkbox" id="nav-toggle" />
            <div className="admin-sidebar">
                <div className="admin-sidebar__brand-container">
                    <div className="admin-sidebar__brand-container_welcome-message">
                        <div className="admin-sidebar__brand-container_welcome-message__logo">
                            <a href="/"><WordMark color={MAIN_CONFIG.COLORS.fillWhite} className="full-logo" /></a>
                            <a href="/"><BrandMark color={MAIN_CONFIG.COLORS.fillWhite} className="only-icon-logo" /></a>
                        </div>
                        <div className="admin-sidebar__brand-container_welcome-message__toggle-icon">
                            <h2>
                                <label for="nav-toggle" onClick={() => setToggleHamburgerMenu(!toggleHamburgerMenu)}>
                                    <MenuIcon color={MAIN_CONFIG.COLORS.fillWhite} width="25" height="25" />
                                </label>
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="admin-sidebar__menu-container">
                    <ul className="admin-sidebar__menu">

                        <li className={activeBackground(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.ADMINLOGIN)} onClick={(e) => setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.ADMINLOGIN)}>
                            <Userinfo className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.ADMINLOGIN)} width="32" height="32" color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.ADMINLOGIN)} />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.ADMINLOGIN)}>
                                    {MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.ADMINLOGIN}
                                </div>
                            </div>
                        </li>

                        <li className={activeBackground(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DASHBOARD)} onClick={(e) => setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DASHBOARD)}>
                            <Person className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DASHBOARD)} width="32" height="32" color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DASHBOARD)} />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DASHBOARD)}>
                                    {MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DASHBOARD}
                                </div>
                            </div>
                        </li>

                        <li className={activeBackground(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PROMOTEUSER)} onClick={(e) => setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PROMOTEUSER)}>
                            <Promote className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PROMOTEUSER)} width="32" height="32" color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PROMOTEUSER)} />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PROMOTEUSER)}>
                                    {MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PROMOTEUSER}
                                </div>
                            </div>
                        </li>

                        <li className={activeBackground(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.UPDATERECOMMENDER)} onClick={(e) => setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.UPDATERECOMMENDER)}>
                            <Update className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.UPDATERECOMMENDER)} width="32" height="32" color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.UPDATERECOMMENDER)} />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.UPDATERECOMMENDER)}>
                                    {MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.UPDATERECOMMENDER}
                                </div>
                            </div>
                        </li>

                        <li className={activeBackground(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.UPDATEAPPLICATION)} onClick={(e) => setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.UPDATEAPPLICATION)}>
                            <Update className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.UPDATEAPPLICATION)} width="32" height="32" color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.UPDATEAPPLICATION)} />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.UPDATEAPPLICATION)}>
                                    {MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.UPDATEAPPLICATION}
                                </div>
                            </div>
                        </li>

                        <li className={activeBackground(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.USERPASSWORDRESET)} onClick={(e) => setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.USERPASSWORDRESET)}>
                            <Password className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.USERPASSWORDRESET)} width="32" height="32" color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.USERPASSWORDRESET)} />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.USERPASSWORDRESET)}>
                                    {MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.USERPASSWORDRESET}
                                </div>
                            </div>
                        </li>

                        <li className={activeBackground(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.USERACTIVITIES)} onClick={(e) => setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.USERACTIVITIES)}>
                            <User className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.USERACTIVITIES)} width="32" height="32" color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.USERACTIVITIES)} />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.USERACTIVITIES)}>
                                    {MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.USERACTIVITIES}
                                </div>
                            </div>
                        </li>

                        {/* <li className={activeBackground(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.CLIENTUSERS)} onClick={(e) => setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.CLIENTUSERS)}>
                            <Client className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.CLIENTUSERS)} width="32" height="32" color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.CLIENTUSERS)} />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.CLIENTUSERS)}>
                                    {MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.CLIENTUSERS}
                                </div>
                            </div>
                        </li>


                        <li className={activeBackground(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.REVIEWBOARDUSERS)} onClick={(e) => setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.REVIEWBOARDUSERS)}>
                            <Review className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.REVIEWBOARDUSERS)} width="32" height="32" color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.REVIEWBOARDUSERS)} />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.REVIEWBOARDUSERS)}>
                                    {MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.REVIEWBOARDUSERS}
                                </div>
                            </div>
                        </li> */}

                        <li className={activeBackground(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.LOGINHISTORY)} onClick={(e) => setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.LOGINHISTORY)}>
                            <History className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.LOGINHISTORY)} width="32" height="32" color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.LOGINHISTORY)} />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.LOGINHISTORY)}>
                                    {MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.LOGINHISTORY}
                                </div>
                            </div>
                        </li>

                        <li className={activeBackground(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EMAILHISTORY)} onClick={(e) => setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EMAILHISTORY)}>
                            <Emailhistory className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EMAILHISTORY)} width="32" height="32" color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EMAILHISTORY)} />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EMAILHISTORY)}>
                                    {MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EMAILHISTORY}
                                </div>
                            </div>
                        </li>

                        {/* <li className={activeBackground(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DUPLICATECONTACTS)} onClick={(e) => setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DUPLICATECONTACTS)}>
                            <Duplicate className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DUPLICATECONTACTS)} width="32" height="32" color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DUPLICATECONTACTS)} />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DUPLICATECONTACTS)}>
                                    {MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DUPLICATECONTACTS}
                                </div>
                            </div>
                        </li> */}

                        <li className={activeBackground(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.FRONTENDTOSALESFORCESYNC)} onClick={(e) => {
                            setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.FRONTENDTOSALESFORCESYNC)
                            localStorage.removeItem('adminTriggerlogFilters')
                        }}>
                            <History className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.FRONTENDTOSALESFORCESYNC)} width="32" height="32" color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.FRONTENDTOSALESFORCESYNC)} />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.FRONTENDTOSALESFORCESYNC)}>
                                    {MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.FRONTENDTOSALESFORCESYNC}
                                </div>
                            </div>
                        </li>

                        <li className={activeBackground(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.SSODETAILS)} onClick={(e) => setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.SSODETAILS)}>
                        <SsoIcon className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.SSODETAILS)} width="32" height="32" color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.SSODETAILS)} />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.SSODETAILS)}>
                                    {MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.SSODETAILS}
                                </div>
                            </div>
                        </li>

                        <li className={activeBackground(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.COPYLEAKSSCAN)} onClick={(e) => setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.COPYLEAKSSCAN)}>
                        <img src="/static/images/CopyleaksScan.png" alt="CopyleaksScan" title='CopyleaksScan' id='copyleaks-scan-logo' className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.COPYLEAKSSCAN)} width="32" height="32" color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.COPYLEAKSSCAN)} />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.COPYLEAKSSCAN)}>
                                    {MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.COPYLEAKSSCAN}
                                </div>
                            </div>
                        </li>
                        <li className={activeBackground(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PORTFOLIOPROGRAM)} onClick={(e) => {
                            setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PORTFOLIOPROGRAM)
                        }}>
                            <Book className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PORTFOLIOPROGRAM)} width="32" height="32" color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PORTFOLIOPROGRAM)} />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PORTFOLIOPROGRAM)}>
                                    {MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PORTFOLIOPROGRAM}
                                </div>
                            </div>
                        </li>
                        <li className={activeBackground(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.ACHINFO)} onClick={(e) => {
                            setActiveTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.ACHINFO)
                        }}>
                            <Book className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.ACHINFO)} width="32" height="32" color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.ACHINFO)} />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.ACHINFO)}>
                                    {MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.ACHINFO}
                                </div>
                            </div>
                        </li>

                        <li className="admin-sidebar__menu__item" onClick={handleLogout}>
                            <Logout color={MAIN_CONFIG.COLORS.fillWhite} width="32" height="32" />
                            <div className='side-drawer-menu-list__item-copy'>
                                <div className="CTAWhite">
                                    Logout
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}
