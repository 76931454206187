import { useRef, useEffect } from 'react';
import './menu.scss';

export default ({ id, className, items, visible = false, handleSelect = () => { }, trigger, stateHandler, buttonType= false }) => {
    const container = useRef()
    const handleKeyDown = (event,item) => {
        if (event.key === 'Enter' && !item.buttonType) {
          event.preventDefault();
          item.onClick(event); // Call the onClick function when Enter is pressed
        }
     };
    const renderItems = (items) => {
        return items?.map((item, index) => {
        return (
                <li tabIndex={0}
                    aria-label={item?.label}
                    key={`menu-item-${index}-${item?.label}-${item?.link}`}
                    className={'CTABlack menu__item'}
                    onKeyDown={(e) => handleKeyDown(e,item)}>
                      
                   {item.buttonType ?
                    <a tabIndex={0} role='link' className={'menu__link'} href={item?.link} download="file">{item?.label}</a>
                    :
                    <a tabIndex={-1} role='link' className={'menu__link'} href={item?.link} onClick={item?.onClick} target={item?.target && "_blank"}>{item?.label}</a>
                    }
                </li>
            );
        });
    };

    const deactivate = event => {
        if (!trigger || !container) {
            return
        }

        if (
            event.target !== trigger.current &&
            (trigger.current && !trigger.current.contains(event.target)) &&
            event.target !== container.current &&
            !container.current.contains(event.target)
        ) {
            stateHandler(false)
        }
    }

    useEffect(() => {
        window.addEventListener('click', deactivate)

        return () => window.removeEventListener('click', deactivate)
    }, [])

    return (
        <nav tabIndex={-1}>
            <ul
                id={id}
                className={`menu ${className} ${visible ? 'open' : ''}`}
                ref={container}
                tabIndex={-1}
                area-hidden={true}
                disabled={true}
            >
                {renderItems(items)}
            </ul>
         </nav>
    );
}
