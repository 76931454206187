import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { isEmpty, debounce, isString } from 'lodash';
import Autosuggest from 'react-autosuggest';
import KaleidoscopeAPI from '../../Core/KaleidoscopeAPI';
import './suggestive-field.scss'
import sanitizeHtml from 'sanitize-html';

const QUESTION_CONFIG = 'Question';

function getSuggestionValue(suggestion) {
  return suggestion[1]
}

function getAddressSuggestionValue(suggestion) {
  return suggestion
}

function renderSuggestion(suggestion) {
  const { autoSuggestData } = this.props;
  const kfcAddressSearch = ["KFC Store Address", "KFC Restaurant Id"]
  switch (true) {
    case kfcAddressSearch.includes(autoSuggestData):
      return (
        <div>
          <p className="auto-suggest-name">{suggestion["KFC Franchisee Name"]}</p>
          {suggestion["KFC Store Address"] && (<p className="auto-suggest-address">{suggestion["KFC Store Address"]}</p>)}
        </div>
      )
      case (autoSuggestData === 'PCUSA Church Address'):
        return (
          <div>
            <p className="auto-suggest-name">{suggestion["PCUSA Name"]}</p>
            {suggestion["PCUSA Street Address"] && (<p className="auto-suggest-address">{suggestion["PCUSA Street Address"]}, {suggestion["PCUSA State"] ? suggestion["PCUSA State"] : ''} {suggestion["PCUSA Zip Code"] ? suggestion["PCUSA Zip Code"] : ''}</p>)}
          </div>
        )
      case ("Taco Bell Address 1" === autoSuggestData):
        return (
          <div>
            <p className="auto-suggest-name">{suggestion["Taco Bell Address 1"]}</p>
    
          </div>
        )
      case ("Pizza Hut Store Address" === autoSuggestData):
        return (
          <div>
            {suggestion["Pizza Hut Address 1"] && (<p className="auto-suggest-address">{suggestion["Pizza Hut Address 1"]}, {suggestion["Pizza Hut Address 2"]} {suggestion["Pizza Hut City"]} {suggestion["Pizza Hut State"]} {suggestion["Pizza Hut County"]} {suggestion["Pizza Hut Postal"]} </p>)}
          </div>
        )
      case ("Financial Aid Office" === autoSuggestData):
        return (
          <div>
            <p className="auto-suggest-name">{suggestion["Financial Aid Office Payee"]}</p>
            {suggestion["Financial Aid Office Payee"] && (<p className="auto-suggest-address">{suggestion["Financial Aid Office Address 1"]} {suggestion["Financial Aid Office Address 2"]} {suggestion["Financial Aid Office City"]} {suggestion["Financial Aid Office State"]} {suggestion["Financial Aid Office Postal"]}</p>)}
          </div>
        )
      case ("ACH Details" === autoSuggestData):
        return (
          <div>
            <p className="auto-suggest-name">{suggestion["ACH Payee"]}</p>
            {suggestion["ACH Payee"]&& (
            <p className="auto-suggest-address">
            {suggestion["Institution City"] && `${suggestion["Institution City"]}, `}
            {suggestion["Institution State"] && `${suggestion["Institution State"]}, `}
            {suggestion["Institution Zipcode"]}
            </p>
          )}
          </div>
        );
      default:
        return (
          <div>
            <p className="auto-suggest-name">{suggestion.name}</p>
            {suggestion.billingcity && (<p className="auto-suggest-address">{suggestion.billingcity}, {suggestion.billingstate} {suggestion.billingpostalcode}</p>)}
          </div>
        );
  }
}

function renderAddressSuggestion(suggestion) {
  return (
    <div>
      <p style={this.props.inputConfig}>{suggestion}</p>
    </div>
  );
}

const AddressSuggestions = ['Country', 'State', 'Zip', 'City']
export const SuggestAction = Object.freeze({
  'Combined': 'getMatchingSchools',
  'Secondary School': 'getMatchingSchools',
  'Post Secondary School': 'getMatchingSchools',
  'KFC Store Address': 'getKfcRestaurent',
  'KFC Restaurant Id': 'getKfcRestaurent',
  'Taco Bell Address 1': 'getTacoBellAddress',
  'PCUSA Church Address': 'getPCUDetails',
  'Pizza Hut Store Address': 'getPizzaHutStore',
  'Financial Aid Office': 'getFinancialAid',
  'ACH Details': 'getAchDetails',
  School: 'getMatchingSchools',
  Zip: 'getZipList',
  Country: 'getCountryList',
  State: 'getStateList',
  City: 'getCityList'
});


const API = new KaleidoscopeAPI({});
class SuggestiveField extends Component {


  constructor(props) {
    super(props);


    this.state = {
      value: (!isEmpty(props.content)) ? props.content : '',
      suggestions: [],
      selected: {},
      isLoading: false,
      optionSelected : false
    };

    // https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js
    this.changeSearch = debounce(this.loadSuggestions, 250)

    // Setup correct fields
    this.checkForAddress([null, this.state.value])

    this.lastRequestId = null;
    this.checkForValueUpdate = this.checkForValueUpdate.bind(this);
  }


  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ value: newProps.content || '' })
  }

  getSuggestionAPI = (value) => {
    let { salesforce_field_value__c, allQuestionsData } = this.props;
    allQuestionsData = allQuestionsData || [];
    const suggestType = this.props.autoSuggestData || 'Secondary School'
    const countryInput = allQuestionsData.find(input => input.auto_suggest_data__c === 'Country') || {}
    const cityInput = allQuestionsData.find(input => input.auto_suggest_data__c === 'City') || {}
    const stateInput = allQuestionsData.find(input => input.auto_suggest_data__c === 'State') || {}


    let url = ''
    switch (suggestType) {
      case 'Zip':
        url = `/auto_suggest/zip_codes?city=${cityInput.answer}&zip_code=${value}`;
        break;
      case 'City':
        url = `/auto_suggest/cities?country=${countryInput.answer}&state=${stateInput.answer}&city=${value}`;
        break;
      case 'State':
        url = `/auto_suggest/states?country=${countryInput.answer}&state=${value}`;
        break;
      case 'Country':
        url = `/auto_suggest/countries?country=${value}`;
        break;
      case 'KFC Store Address':
        url = `/auto_suggest/kfc_details?location=${value}`;
        break;
      case 'KFC Restaurant Id':
        url = `/auto_suggest/kfc_details?restaurant_id=${value}`;
        break
      case 'Taco Bell Address 1':
        url = `/auto_suggest/taco_bell_stores?location=${value}`;
        break;
      case 'PCUSA Church Address':
        url = `/auto_suggest/pcusa_church_details?church_name=${value}`;
        break;
      case 'Secondary School':
      case 'Post Secondary School':
        url = `/auto_suggest/institutions?name=${value}&auto_suggest_data=${suggestType}&salesforce_field_value=${salesforce_field_value__c}`;
        break;
      case 'Pizza Hut Store Address':
        url = `/auto_suggest/pizza_hut_store_locations?location=${value}`;
        break;
      case 'Financial Aid Office':
          url = `/auto_suggest/financial_aid_offices?payee=${value}`;
          break;
      case 'ACH Details':
          url = `/auto_suggest/kscope_verified_schools?payee_institution__c=${value}`
          break;
      default:
        url = ''
    }
    return url
  }

  loadSuggestions = async (value) => {
    this.setState({
      isLoading: true
    });
    const suggestType = this.props.autoSuggestData || 'Secondary School'
    const url = this.getSuggestionAPI(value)

    const response = await API[SuggestAction[suggestType]]({ url: url, suggestType: suggestType })
    if (response.kfc_details) {
      this.setState({
        isLoading: false,
        suggestions: response.kfc_details
      });
    } else if (response.taco_bell_stores) {
      this.setState({
        isLoading: false,
        suggestions: response.taco_bell_stores
      });
    } else if (response.pcusa_church_details) {
      this.setState({
        isLoading: false,
        suggestions: response.pcusa_church_details
      });
    } else if (response.institutions) {
      this.setState({
        isLoading: false,
        suggestions: response.institutions
      });
    } else if (response.countries) {
      this.setState({
        isLoading: false,
        suggestions: response.countries
      });
    } else if (response.states) {
      this.setState({
        isLoading: false,
        suggestions: response.states
      });
    } else if (response.cities) {
      this.setState({
        isLoading: false,
        suggestions: response.cities
      });
    } else if (response.zip_codes) {
      this.setState({
        isLoading: false,
        suggestions: response.zip_codes
      });
    } else if (response.pizza_hut_store_locations) {
      this.setState({
        isLoading: false,
        suggestions: response.pizza_hut_store_locations
      });
    } else if (response.financial_aid_offices) {
      this.setState({
        isLoading: false,
        suggestions: response.financial_aid_offices
      });
    } else if (response.kscope_verified_schools) {
      this.setState({
        isLoading: false,
        suggestions: response.kscope_verified_schools
      });
    }
  }



  onBlur = (event) => {
    if (this.props.auto_suggest_restriction && !this.state.optionSelected) {
      // let inputEvent = Object.assign({}, event);
      // const value = event.target.value;
      // const suggestType = this.props.autoSuggestData || 'Secondary School'
      // const url = this.getSuggestionAPI(value)

      // this.props[SuggestAction[suggestType]](url, value, suggestType).then((res) => {
      //   let suggestions = Array.isArray(res) ? res : Object.values(res)
      //   const formattedSuggestion = (Array.isArray(suggestions[0])) ? suggestions.map(a => a[1]) : suggestions
      //   const val = formattedSuggestion.find(v => v === value)

      //   if (!val) {
          // inputEvent.target.value = ''
          this.setState({ value: '' })
          this.setState({optionSelected : false})
          this.props.onQuestionSuggestiveSelect(this.props.name, '');
          // this.props.controlFunc(inputEvent)
    //     }

    //   })
    //     .catch(function (err) {

    //     })
    }
  }

  onChange = (event, { newValue }) => {
    let value = newValue;

    const { onQuestionSuggestiveSelect, name } = this.props;

    let arr = ["<", ">"]
    if(!value) {
      this.props.onQuestionSuggestiveSelect(this.props.name, '');
    }
    if (!arr.includes(newValue)) {
      if (this.props.checkRestrictedKeywords(value) && isString(value)) {
        let newStr = newValue.replace(/<>/g, "");
        value = sanitizeHtml(newStr, { allowedTags: [] })
      }
      this.setState({
        value: value,
        optionSelected: false
      });
      if (event.target.value) {
        onQuestionSuggestiveSelect(name, event.target.value)
      }
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    // this.loadSuggestions(value);
    this.changeSearch(value)
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  checkForValueUpdate = (value) => {
    const { onSuggestiveSelect, onQuestionSuggestiveSelect, salesforce_field_value__c, name, config_type } = this.props;
    if (config_type !== QUESTION_CONFIG) {
      onSuggestiveSelect(salesforce_field_value__c, value)
    } else {
      onQuestionSuggestiveSelect(name, value)
    }
  }


  checkForAddress = (value) => {
    let id = value[0]
    let school = value[1]
    if (id) {
      this.props.getNewSchoolAddressAction(id, this.props.name).then((res) => {
        this.props.onSuggestiveSelect(res)
      })
        .catch(function (err) {
        })
    }
  }

  onSuggestionSelected = (_event, value) => {
    
    this.setState({ optionSelected: true })
    const suggestType = this.props.autoSuggestData || 'Secondary School'
    const { config_type } = this.props;
    //  && QUESTION_CONFIG !== config_type
    if (QUESTION_CONFIG !== config_type && ['Secondary School', 'Post Secondary School'].includes(suggestType)) {
      this.props.onSuggestiveSelect(value.suggestion)
      // this.props.onSuggestiveSelect(value.suggestion)
    } else if (suggestType === 'KFC Store Address') {
      this.props.onSuggestiveSelect(value.suggestion)
    } else if (suggestType === "KFC Restaurant Id") {
      this.props.onSuggestiveSelect(value.suggestion)
    } else if (suggestType === 'Taco Bell Address 1') {
      this.props.onSuggestiveSelect(value.suggestion)
    } else if (suggestType === 'PCUSA Church Address') {
      this.props.onSuggestiveSelect(value.suggestion)
    } else if (suggestType === 'Pizza Hut Store Address') {
      this.props.onSuggestiveSelect(value.suggestion)
    } else if (suggestType === 'Financial Aid Office') {
      this.props.onSuggestiveSelect(value.suggestion)
    } else if (suggestType === 'ACH Details') {
      this.props.onSuggestiveSelect(value.suggestion)
    } else {
      // this.props.onSuggestiveSelect(value.suggestion)
      this.checkForValueUpdate(value.suggestion)
    }
  }

  render() {

    const { value, suggestions } = this.state;
    // const { scholarship } = this.props;
    const { inputConfig, name, autoSuggestData } = this.props;
    let placeholderText = autoSuggestData
    if(autoSuggestData === "ACH Details") {
      placeholderText = "School Name"
    } else if(autoSuggestData !== "Financial Aid Office") {
      placeholderText=  autoSuggestData
    } else {
      placeholderText = "Please answer."
    }
    const inputProps = {
      placeholder: placeholderText,
      name: name,
      value: value ? value : '',
      onChange: this.onChange,
      onBlur: this.onBlur,
      disabled: this.props.isDisabled
      // style: inputConfig
    };

    // const isScholarshipFormPage = window.location.pathname.indexOf("/apply") >= 0
    return (
      <div className="config_input" id="config_input">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={AddressSuggestions.includes(autoSuggestData) ? getAddressSuggestionValue : getSuggestionValue}
          renderSuggestion={AddressSuggestions.includes(autoSuggestData) ? renderAddressSuggestion.bind(this) : renderSuggestion.bind(this)}
          inputProps={inputProps}
        />


      </div>
    );
  }
}

SuggestiveField.propTypes = {
  onSuggestiveSelect: PropTypes.func
}


export default SuggestiveField