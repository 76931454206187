import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ color = MAIN_CONFIG.COLORS.fillWhite, ariaLabel = "Close", ariaLabelDescription = "Close",tabIndex=0 }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" tabIndex={tabIndex}>
        <title>{ariaLabel}</title>
        <desc>{ariaLabelDescription}</desc>
        <g fill="none" fillRule="evenodd">
            <g fill={color}>
                <g>
                    <path
                        d="M12.75 4L12.75 11.25 20 11.25 20 12.75 12.75 12.75 12.75 20 11.25 20 11.25 12.75 4 12.75 4 11.25 11.25 11.25 11.25 4z"
                        transform="translate(-59 -28) translate(59 28) rotate(45 12 12)" />
                </g>
            </g>
        </g>
    </svg>
);
