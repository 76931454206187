import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "18", height = "13.5", iconColor = MAIN_CONFIG.COLORS.fillBlack }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 13.5">
            <title>Essay</title>
            <desc>Essay</desc>
            <g id="Essay" transform="translate(0 0.75)">
                <path id="Path_5_Copy_2" data-name="Path 5 Copy 2" d="M0,.75H10.5" transform="translate(0 -0.75)" fill="none" stroke={color} stroke-miterlimit="10" stroke-width="1.5" />
                <path id="Path_5_Copy_3" data-name="Path 5 Copy 3" d="M0,.75H18" transform="translate(0 5.25)" fill="none" stroke={color} stroke-miterlimit="10" stroke-width="1.5" />
                <path id="Path_5_Copy_4" data-name="Path 5 Copy 4" d="M0,.75H18" transform="translate(0 11.25)" fill="none" stroke={color} stroke-miterlimit="10" stroke-width="1.5" />
            </g>
        </svg>
    );
};
