import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "24", height = "24" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="-4.5 -4.5 24 24" fill={color} width={width} height={height} focusable="false">
            <title>Menu Icon</title>
            <desc>Menu Icon</desc>
            <path d="M5 1h2v6H1V5h2.59L0 1.41 1.41 0 5 3.59zm7.41 10H15V9H9v6h2v-2.59L14.59 16 16 14.59z"></path>
        </svg>
    );
};