import React from 'react';
import Proptype from 'prop-types'
import './cta-button.scss';
import { MAIN_CONFIG } from '../../../config/main';
import DrawnArrow from '../SVG/DrawnArrow';


/**
 * NOTE: To use a CTA button with an icon and arrow use the 'tertiary' and add the icon and arrow
 * @param {string} id
 * @param {JSX.Element[]} children
 * @param {JSX.Element} IconComponent
 * @param {string} type
 * @param {boolean} outline
 * @param {boolean} small
 * @param {boolean} arrow
 * @param {boolean} reverse
 * @param {boolean} dark
 * @param {function} onClick
 * @param {string} className
 * @returns {JSX.Element}
 */
const CTAButton = ({
    id,
    children,
    IconComponent,
    type = 'primary',
    outline = false,
    small = false,
    arrow = false,
    reverse = false,
    dark = false,
    disabled = false,
    onClick = () => { },
    className,
    style,
    buttonType,
    onKeyDown=()=>{ },
    arrowAriaLabelDescription = ""
}) => {

    /**
     * Determine color to use for SVG icon.
     * @param {string} type
     * @param {boolean} outline
     * @param {boolean} arrow
     * @returns {string}
     */
    const getIconColor = (type, outline, arrow) => {
        switch (type) {
            case 'secondary':
            case 'tertiary':
                return outline || arrow ? MAIN_CONFIG.COLORS.fillWhite : MAIN_CONFIG.COLORS.fillBlack;
            case 'inverse':
                return outline || arrow ? MAIN_CONFIG.COLORS.fillBlack : MAIN_CONFIG.COLORS.fillWhite;
            case 'success':
                return outline || arrow ? MAIN_CONFIG.COLORS.fillBlack : MAIN_CONFIG.COLORS.fillGreen;
            default:
                return outline || arrow ? MAIN_CONFIG.COLORS.fillBlack : MAIN_CONFIG.COLORS.fillWhite;
        }
    };

    /**
     * Build class string for current button configuration.
     * @returns {string}
     */
    const getButtonClass = () => {
        const iconClass = IconComponent ? 'with-icon' : '';
        const outlineClass = outline ? 'outline' : '';
        const smallClass = small ? 'small' : '';
        const arrowClass = arrow ? 'arrow' : '';
        const reverseClass = reverse ? 'reverse' : '';
        const darkClass = dark ? 'dark' : '';

        return `cta-button CTAWhite ${type} ${iconClass} ${outlineClass} ${smallClass} ${arrowClass} ${reverseClass} ${darkClass} ${className}`;
    }

    return (
        <button id={id} className={getButtonClass()} onClick={onClick} disabled={disabled} style={style} aria-label={`${children}`} aria-describedby={`${children} button`} type={buttonType} onKeyDown={onkeydown}>
            {IconComponent && <IconComponent color={getIconColor(type, outline, arrow)} className='cta-button__icon' />}
            <span className='cta-button__text'>{children}</span>
            {arrow && <DrawnArrow className='cta-button__arrow' color={getIconColor(type, outline, arrow)} ariaLabelDescription={arrowAriaLabelDescription} />}
        </button>
    );
};

CTAButton.propTypes = {
    arrowAriaLabelDescription: Proptype.string
}

export default CTAButton