import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillWhite, ariaLabel = "Arrow", ariaLabelDescription = "Arrow", tabIndex=-1 }) => {
    return (
            <svg id={id} className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" tabIndex={tabIndex}>
                <title>{ariaLabel}</title>
                <desc>{ariaLabelDescription}</desc>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2144 11.8432L9.16 6.38081C9.02913 6.25234 8.92574 6.09862 8.85609 5.92897C8.78644 5.75933 8.75199 5.5773 8.75482 5.39394C8.75766 5.21057 8.79773 5.02969 8.8726 4.86229C8.94746 4.69488 9.05556 4.54443 9.19034 4.42007C9.32512 4.2957 9.48376 4.20003 9.65664 4.13884C9.82952 4.07766 10.013 4.05224 10.196 4.06413C10.379 4.07602 10.5577 4.12497 10.7212 4.20801C10.8847 4.29106 11.0296 4.40646 11.1472 4.54721L16.672 10.5232C17.0057 10.8827 17.1911 11.3551 17.1911 11.8456C17.1911 12.3361 17.0057 12.8085 16.672 13.168L11.1568 19.144C11.0396 19.2844 10.8953 19.3996 10.7323 19.4827C10.5694 19.5658 10.3914 19.615 10.2089 19.6273C10.0265 19.6397 9.84341 19.615 9.67078 19.5546C9.49816 19.4943 9.33955 19.3996 9.20453 19.2762C9.06951 19.1529 8.96089 19.0035 8.88521 18.837C8.80953 18.6706 8.76836 18.4905 8.7642 18.3076C8.76004 18.1248 8.79296 17.9431 8.86099 17.7733C8.92902 17.6036 9.03073 17.4494 9.16 17.32L14.2144 11.8432Z" fill={color} />
            </svg>
    );
}
