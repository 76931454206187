import React from 'react';
import './user-badge.scss';
import { MAIN_CONFIG } from '../../../config/main';
import CTAImageTag from '../CTAImageTag/CTAImageTag';

export default ({
                    id,
                    className,
                    firstName,
                    lastName,
                    profileImage,
                    color = MAIN_CONFIG.COLORS.fillWhite,
                    backgroundColor = MAIN_CONFIG.COLORS.fillGreen
                }) => {
    /**
     * Renders either the profile image (if present) or the initials.
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} profileImage
     * @returns {JSX.Element}
     */
    const renderIcon = (firstName, lastName, profileImage) => {
        if (profileImage) {
            return (
                <div className='profile-image'>
                    <CTAImageTag data={profileImage} title={`Profile image for ${ firstName } ${ lastName }`} />
                </div>
            );
        } else  {
            const initials = `${firstName ? firstName[0] : ''}${lastName ? lastName[0] : ''}`;
            return (
                <div className='initials H4DesktopWhite' style={{ color: color, backgroundColor: backgroundColor }}>{ initials }</div>
            );
        }
    };

    return (
        <div id={id} className={`user-badge ${className}`}>
            { renderIcon(firstName, lastName, profileImage) }
        </div>
    );
};
