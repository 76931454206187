import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './reviewers-screen.scss';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import ReviewBoardModalContent from '../ReviewBoardModalContent/ReviewBoardModalContent';
import Table from '../Table/Table';
import KaleidoscopeAPI from '../../Core/KaleidoscopeAPI';
import FlashMessage from '../../Components/FlashMessage/Message';
import { ToastContainer, toast } from 'react-toastify';
import InnerPageHeader from '../InnerPagesHeader/InnerPagesHeader';


/**
 *
 * @param {function} handleClose
 * @param {function} setModalState
 * @param {object} modalState
 * @param {object} reviewersScreenState
 * @param {function} setShowTableActionBar
 * @param {function} setTableActionBarNumSelected
 * @param {function} setTableActionBarNumTotal
 * @returns {JSX.Element}
 */
const ReviewersScreen = ({
    setModalState,
    modalState,
    reviewersScreenState,
    accountData,
    setShowTableActionBar,
    setTableActionBarNumSelected,
    setTableActionBarNumTotal,
    setReviewerCount,
    loading=false,
    setLoading,
    setShowApplicationsSidebar,
    setActiveDonorSidebarTab = () => {},
    pageData
}) => {
    const screen = useRef(null);
    const { data, data: { associated_rb_members }, scholarshipId } = reviewersScreenState;
    const { scholarship } = pageData
    const rawTableData = associated_rb_members;
    const API = new KaleidoscopeAPI({});

    useEffect(() => {
        setShowApplicationsSidebar(true)
        setActiveDonorSidebarTab("Review Board")
    }, [])

    // Update the modal content to reflect member changes
    useEffect(() => {
        setModalContent(
            <ReviewBoardModalContent
                data={data}
                handleSave={() => closeModal()}
                handleClose={closeModal}
                handleAdd={handleAdd}
                handleRemove={handleRemove}
                token={accountData.token}
                accountId={accountData.currentAccount.sfid}
                scholarshipId={scholarshipId}
                setLoading={setLoading}
                loading={loading}
            />
        );
        
        setReviewerCount(associated_rb_members && associated_rb_members.length)

    }, [reviewersScreenState]);

    // TODO: Remove this once it is available from the API
    const columnData = {
        table_headers: [
            { field_api_name__c: 'FirstName', column_name__c: 'First Name' },
            { field_api_name__c: 'LastName', column_name__c: 'Last Name' },
            { field_api_name__c: 'Email', column_name__c: 'Email' },
        ]
    };

    /**
     * Add member to review board.
     * @param {object} member
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    const handleAdd = async (member) => {
        const result = await API.addReviewBoardMember({
            token: accountData.token,
            accountId: accountData.currentAccount.sfid,
            reviewBoardMemberId: member.sfid,
            scholarshipId: scholarshipId
        });

        if (result.success) {
            toast.success(<FlashMessage message={result.message} />)
            reviewersScreenState.populateReviewers();
        }else{
            toast.error(<FlashMessage message={result.message} />)
        }

        return result;
    }

    /**
     * Remove member from review board.
     * @param {object} member
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    const handleRemove = async (member) => {
        const result =  await API.removeReviewBoardMember({
            token: accountData.token,
            accountId: accountData.currentAccount.sfid,
            reviewBoardMemberId: member.sfid,
            scholarshipId: scholarshipId
        });

        if (result.success) {
            toast.success(<FlashMessage message={result.message} />)
            reviewersScreenState.populateReviewers();
        }else{
            toast.error(<FlashMessage message={result.message} />)
        }

        return result;
    }

    useEffect(() => {
        disableBodyScroll(screen.current);

        return () => {
            enableBodyScroll(screen.current);
        }
    }, []);

    /**
     * Adds the modal content and shows the modal.
     */
    const showModal = () => {
        setModalState({
            visible: true,
            content: modalContent
        });
    }

    /**
     * Closes the modal.
     */
    const closeModal = () => {
        setModalState({ visible: false });
    }

    const [modalContent, setModalContent] = useState(
        <ReviewBoardModalContent
            data={data}
            handleSave={() => closeModal()}
            handleClose={closeModal}
            handleAdd={handleAdd}
            handleRemove={handleRemove}
            token={accountData.token}
            accountId={accountData.currentAccount.sfid}
            scholarshipId={scholarshipId}
            setLoading={setLoading}
            loading={loading}
        />
    );

    // Pass updated modal content to modal
    useEffect(() => {
        if (modalState.visible) {
            showModal();
        }
    }, [modalContent])



    return (
      <div ref={screen} id='reviewers-screen' className='reviewers-screen-client'>
            <InnerPageHeader pageType={pageType} pageTitle={scholarship?.name} subtitle={'Review Board'}/>

            <div id='reviewers-screen__inner'>
                <div id='reviewers-screen__top'>
                    <h1 className='H1DesktopWhite'>Review Board</h1>
                </div>

              { rawTableData &&
                  <Table
                      id={'reviewers-screen__reviewer-table'}
                      showSelect={false}
                      columnData={columnData}
                      tableData={rawTableData}
                      setShowTableActionBar={setShowTableActionBar}
                      setTableActionBarNumSelected={setTableActionBarNumSelected}
                      setTableActionBarNumTotal={setTableActionBarNumTotal}
                  />
              }
          </div>
          <ToastContainer />
      </div>
    );
};

ReviewersScreen.propTypes = {
    setModalState: PropTypes.func,
    modalState: PropTypes.object,
    reviewersScreenState: PropTypes.shape({
        data: PropTypes.object,
        scholarshipId: PropTypes.string,
        populateReviewers: PropTypes.func,
    }),
    accountData: PropTypes.shape({
        token: PropTypes.string,
        currentAccount: PropTypes.shape({
            sfid: PropTypes.string,
        }),
    }),
    setShowTableActionBar: PropTypes.func,
    setTableActionBarNumSelected: PropTypes.func,
    setTableActionBarNumTotal: PropTypes.func,
    setReviewerCount: PropTypes.func,
    loading: PropTypes.bool,
    setLoading: PropTypes.func,
    setShowApplicationsSidebar: PropTypes.func,
    setActiveDonorSidebarTab: PropTypes.func,
    pageData: PropTypes.shape({
        scholarship: PropTypes.object,
    }),
};

export default ReviewersScreen;