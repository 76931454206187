import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "40px", height = "40px", ariaLabel = "Phone", ariaLabelDescription = "" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 40 40">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-108 -1110) translate(108 1110)">
                        <g fill={color}>
                            <path d="M.462 29.114h19.436V5.132H.462v23.982zm8.376 3.123c0-.74.602-1.342 1.342-1.342.74 0 1.342.602 1.342 1.342 0 .74-.602 1.343-1.342 1.343-.74 0-1.342-.602-1.342-1.343zM17.992 0H2.367C1.062 0 0 1.062 0 2.367v31.158c0 1.305 1.062 2.367 2.367 2.367h15.625c1.306 0 2.368-1.062 2.368-2.367V2.367C20.36 1.062 19.298 0 17.992 0z" transform="translate(9.846 2.154)" />
                            <path d="M4.186 10.567c-.656 0-1.19.534-1.19 1.19 0 .656.534 1.19 1.19 1.19.656 0 1.19-.534 1.19-1.19 0-.656-.534-1.19-1.19-1.19M4.186 16.144c-.656 0-1.19.534-1.19 1.19 0 .656.534 1.19 1.19 1.19.656 0 1.19-.534 1.19-1.19 0-.656-.534-1.19-1.19-1.19M4.186 23.64c-.401 0-.728-.327-.728-.729 0-.401.327-.728.728-.728.402 0 .729.327.729.728 0 .402-.327.728-.729.728m0-1.917c-.656 0-1.19.533-1.19 1.19 0 .655.534 1.189 1.19 1.189.656 0 1.19-.534 1.19-1.19 0-.656-.534-1.19-1.19-1.19M8.42 11.988L17.606 11.988 17.606 11.526 8.42 11.526zM8.42 17.565L17.606 17.565 17.606 17.103 8.42 17.103zM8.42 23.142L17.606 23.142 17.606 22.681 8.42 22.681z" transform="translate(9.846 2.154)" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
