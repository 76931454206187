import React, { useState, useRef, useEffect } from 'react';
import './account-menu.scss';
import { useRouter } from 'next/router';
import ArrowDown from '../SVG/ArrowDown';
import Menu from '../Menu/Menu';
import UserBadge from '../UserBadge/UserBadge';
import { MAIN_CONFIG } from '../../../config/main';
import Loader from '../Loader/Loader';
const { USER_CONTEXT, USER_ROLE_CONTEXT } = MAIN_CONFIG;

export default ({ currentAccount, setAccountSwitcherVisible, setRoleSwitcherVisible, userContext, user, herokuRole = [], setShowChatBox = () => { }, pageData} ) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [loading,setLoading]= useState(false)
    const menuTrigger = useRef()
    const router = useRouter();

    const { heroku_role } = pageData;
    /**
     * Delete token cookie to logout and redirect to login page.
     */
    const handleLogout = () => {
        document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'context=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'user=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'currentAccount=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        localStorage.clear();
        setTimeout(() => {
            window.location.href = MAIN_CONFIG.URLS.BASE;
        }, 2000);
    }
    const firstName = user ? user.first_name : '';
    const lastName = user ? user.last_name : '';
    const companyName = currentAccount ? currentAccount.name : '';
    const profileImage = user ? user.avatar : '';
    const primaryColor = currentAccount ? currentAccount.primary_color__c : MAIN_CONFIG.COLORS.fillGreen;
    const secondaryColor = currentAccount ? currentAccount.secondary_color__c : MAIN_CONFIG.COLORS.fillBeige;

    const handleMenu = () => {
        setShowChatBox(false)
        setMenuOpen(!menuOpen)
        sessionStorage.removeItem('isBackButtonClick');
    }

    /**
     * Gets the menu for the current user type.
     * return { object[] }
     */
    const getMenuItems = () => {
        let menu = []
        switch (userContext) {
            case USER_CONTEXT.DONOR:
            case USER_CONTEXT.SUPERDONOR:
                menu.push(

                    { label: 'Account Preferences', link: '#', onClick: () => { router.push('/client/account', undefined, { shallow: true }) } },
                    { label: 'Profile', link: '#', onClick: () => { router.push('/profile', undefined, { shallow: true }) } },
                    // { label: 'Switch Account', link: '#', onClick: () => { router.push(MAIN_CONFIG.URLS.SWITCH, undefined, { shallow: true })} },
                    { label: 'Switch Account', link: '#', onClick: () => { router.push(MAIN_CONFIG.URLS.ACCOUNT_SWITCH, undefined, { shallow: true }) } }
                );
                break
            case USER_CONTEXT.REVIEWER:
                menu = [
                    // { label: 'Account Preferences', link: '#', onClick: () => { router.push('/reviewer/account', undefined, { shallow: true }) } },
                    { label: 'Profile', link: '#', onClick: () => { router.push('/profile', undefined, { shallow: true }) } },
                    // { label: 'Switch Account', link: '#', onClick: () => { router.push(MAIN_CONFIG.URLS.SWITCH, undefined, { shallow: true })} },
                    { label: 'Switch Account', link: '#', onClick: () => { router.push(MAIN_CONFIG.URLS.ACCOUNT_SWITCH, undefined, { shallow: true }) } }
                ];
                break
            case USER_CONTEXT.APPLICANT:
                if (heroku_role?.length > 1) {
                    menu = [
                        { label: 'Profile', link: '#', onClick: () => { router.push('/profile', undefined, { shallow: true }) } },
                        { label: 'Switch Role', link: '#', onClick: () => { router.push(MAIN_CONFIG.URLS.ROLE_SWITCH, undefined, { shallow: true }) } },
                    ]
                }else{
                    menu = [
                        { label: 'Profile', link: '#', onClick: () => { router.push('/profile', undefined, { shallow: true }) } },
                    ]
                }
                break
            default:
                menu = [
                    // { label: 'Account Preferences', link: '#', onClick: () => { router.push('/recommender/account', undefined, { shallow: true }) } },
                    { label: 'Profile', link: '#', onClick: () => { router.push('/profile', undefined, { shallow: true }) } }
                ]
        }

        const roleContext = USER_ROLE_CONTEXT.find(i => i.context === userContext);
        let role = herokuRole && herokuRole.filter(item => item !== roleContext.role)
        if (role?.length > 0) {
            menu.push({ label: 'Switch Role', link: '#', onClick: () => { router.push(MAIN_CONFIG.URLS.ROLE_SWITCH, undefined, { shallow: true }) } })
            // menu.push({ label: 'Switch Role', link: '#', onClick: () => { setRoleSwitcherVisible(true); } })
        }
        // menu.push({ label: 'Log Out', link: '#', onClick: handleLogout })
        menu.push({ label: 'Log Out', link: '#', onClick: () => {setLoading(true), router.push(MAIN_CONFIG.URLS.LOGOUT, undefined, { shallow: true }) } })

        return menu
    }

    return (
        <nav id='account-menu' role='navigation' aria-label='right manu'> 
            <Loader loading={loading}/>
            <div id='account-menu__profile-icon'>
                <UserBadge
                    firstName={firstName}
                    lastName={lastName}
                    profileImage={profileImage}
                    backgroundColor={primaryColor}
                    color={secondaryColor}
                />
            </div>
            <div id='account-menu__info'>
                <div id='account-menu__first-name' className='BodySmallMediumWhite'>{firstName}</div>
                <div id='account-menu__company-name' className='BodySmallRegularWhite'>{companyName}</div>
            </div>
            <button
                id={'account-menu__button'}
                onClick={() => handleMenu()}
                ref={menuTrigger}
                aria-label={`Account menu toggle ${menuOpen ? "button active" : "button inactive"}`}
            >
                <ArrowDown className={menuOpen ? "" : ""} ariaLabel="Account menu" ariaLabelDescription="Click to expand" />
                <Menu
                    id={'account-menu__menu'}
                    items={getMenuItems()}
                    visible={menuOpen}
                    trigger={menuTrigger}
                    stateHandler={setMenuOpen}
                />
            </button>
        </nav>
    );
}
