import CircleInfo from '../SVG/CircleInfo';
import CircleCheck from '../SVG/CircleCheck';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ type = 'success', message = '' }) => {
	return(
		<>
			{ type === 'success' ? <CircleCheck className="flash-icon"  secondaryColor={MAIN_CONFIG.COLORS.fillGreen}/> : <CircleInfo className="flash-icon" />}
			{message}
		</>
	)
}