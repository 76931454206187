import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "24", height = "24", ariaLabel = "Person", ariaLabelDescription = "Person" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g stroke={color} strokeWidth=".939" transform="translate(-736 -424) translate(736 424) translate(1 1)" id="person-svg-icon" fill={color}>
                            <circle cx="11.739" cy="5.165" r="5.165" fill={color} />
                            <path fill={color} d="M1.878 21.6v-3.354c0-3.334 2.671-6.037 5.967-6.037h7.789c3.295 0 5.966 2.703 5.966 6.037V21.6h0" />
                            <circle cx="9.861" cy="5.165" r="5.165" fill="#FFF" />
                            <path fill="#FFF" d="M0 21.6v-3.354c0-3.334 2.671-6.037 5.966-6.037h7.79c3.295 0 5.966 2.703 5.966 6.037V21.6h0"  />
                        </g>
                        <path fill="#FFF" d="M2 22H20.25V23H2z" transform="translate(-736 -424) translate(736 424)"  />
                    </g>
                </g>
            </g>
        </svg>

    );
}
