import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGrey, ariaLabel = "Search", ariaLabelDescription = "Search" }) => {

    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <g stroke={color} strokeWidth="1.5" transform="translate(4 4)">
                <circle cx="6.5" cy="6.5" r="6.5" fill="none" />
                <path d="M11.429 11.429L16.571 16.571" />
            </g>
        </svg>
    );
};

