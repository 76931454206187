import React from 'react';
import './people-count-cell-type.scss';
import Person from '../../../SVG/Person';

export default ({ value }) => {

	return (
		<span className='people-count-cell-type'>
			<span className='people-count-cell-type__icon'><Person /></span>
			<span className='people-count-cell-type__date BodySmallRegularBlack'>{ value ? value : 0 }</span>
		</span>
	);
};
