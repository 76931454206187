import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "200", height = "200" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 200">
            <title>Email history</title>
            <desc>Email history</desc>
            <defs>
                <clipPath id="clip-Emailhistory">
                    <rect width="200" height="200" />
                </clipPath>
            </defs>
            <g id="Emailhistory" clipPath="url(#clip-Emailhistory)">
                <g id="noun_Email_1002247" transform="translate(393 174.75)">
                    <path id="Path_7821" data-name="Path 7821" d="M20.981,183.25H164.15a17,17,0,0,0,16.981-17V75.335a4.813,4.813,0,0,0-1.714-3.677l-23.7-20.024V16.727A13.477,13.477,0,0,0,142.239,3.25h-98.4A13.477,13.477,0,0,0,30.358,16.727V50.9L5.714,71.657A4.813,4.813,0,0,0,4,75.335v90.915a17,17,0,0,0,16.981,17Zm143.149-9.627H20.981a7.374,7.374,0,0,1-7.355-7.374V84.519L68.5,123.333l-38.95,29.8a4.813,4.813,0,0,0,5.776,7.7l41.414-31.691,13.477,9.627a4.813,4.813,0,0,0,5.564,0l13.477-9.627,41.433,31.633a4.813,4.813,0,1,0,5.776-7.7L117.6,123.333,171.5,85.212v81.057a7.374,7.374,0,0,1-7.355,7.355Zm4.679-98.308-13.092,9.261V64.225ZM43.835,12.9h98.423a3.851,3.851,0,0,1,3.851,3.851V91.334L93.047,128.9,40,91.334V16.727A3.851,3.851,0,0,1,43.835,12.9ZM30.358,84.576,16.726,74.95,30.358,63.4Zm62.208,19.6H93.8a4.813,4.813,0,0,0,0-9.627H92.566a30.69,30.69,0,1,1,30.69-30.69v8.221a5.372,5.372,0,0,1-10.724,0V63.86a20.024,20.024,0,1,0-7.7,15.653,14.979,14.979,0,0,0,28.052-7.432V63.86a40.317,40.317,0,1,0-40.317,40.317Zm0-29.978A10.339,10.339,0,1,1,102.9,63.86,10.339,10.339,0,0,1,92.566,74.2Z" transform="translate(-386 -168)" fill={color} />
                </g>
            </g>
        </svg>


    );
};
