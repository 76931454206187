import BrokenPencil from '../SVG/BrokenPencil'
import CTAButton from '../CTAButton/CTAButton'

import './no-data-to-display.scss'

/**
 * Returns No Data To Display component
 *
 * @param {object} props
 * @param {string} props.description
 * @param {string} props.ctaLabel
 * @param {function} props.ctaOnClick
 *
 * @returns {ReactElement}
 */

export default function NoDataToDisplay(props) {
  const {
    description = 'Nothing to show',
    ctaLabel,
    ctaOnClick,
  } = props

  return (
    <div className="no-data-to-display">
      <div className="no-data-to-display__content">
        {/* <BrokenPencil ariaLabel={`${description}`} ariaLabelDescription={`${description}`} /> */}
        <p className="no-data-to-display__description">
          {description}
        </p>
        {ctaLabel && ctaOnClick &&
          <CTAButton
            className="no-data-to-display__cta"
            onClick={ctaOnClick}
            type="tertiary"
          >
            {ctaLabel}
          </CTAButton>
        }
      </div>
    </div>
  )
}
