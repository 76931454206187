import { combineReducers } from 'redux';
import { applicationIsLoading, conversations, metaTitle , handleCurrentTabIndex , handleperPageCount , backButtonClicked, manageFilters, uploadPercentage, secondaryModalReducer} from '../Reducer/commonReducer';


export default combineReducers({
  applicationIsLoading,
  conversations,
  metaTitle,
  handleCurrentTabIndex,
  handleperPageCount,
  backButtonClicked,
  uploadPercentage,
  manageFilters,
  secondaryModalReducer
});
