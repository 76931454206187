import React from 'react';

export default ({ id, className, direction = '', ariaLabel = "Short Arrow", ariaLabelDescription = "Short Arrow" }) => {
    const currentDirection = direction.toUpperCase();
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <g fill="none" fillRule="evenodd">
                <g fill="#737373">
                    <g>
                        <g opacity={currentDirection === 'ASC' ? '1' : '.3'}>
                            <path d="M0 0L6.025 6 12 0z" transform="translate(-557 -368) translate(557 368) matrix(0 -1 -1 0 12 6) rotate(-90 6 6)" />
                        </g>
                        <g opacity={currentDirection === 'DESC' ? '1' : '.3'}>
                            <path d="M0 0L6.025 6 12 0z" transform="translate(-557 -368) translate(557 368) rotate(90 1 11) rotate(-90 6 6)" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

