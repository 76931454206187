import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "24", height = "24", iconColor = MAIN_CONFIG.COLORS.fillBlack }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
            <title>Currency</title>
            <desc>Currency</desc>
            <g id={id} data-name="Elements / System / Dollar Sign" transform="translate(0 0)">
                <path id="_" data-name="$" d="M5.118,23.9l-.085-2.276h.6A3.456,3.456,0,0,0,9,17.845L8.978,15.3A4.061,4.061,0,0,0,7.55,12.1L3.306,8.9c0-.818.021-2.166.043-3.116h2.3c-.021.685-.043,1.5-.043,2.276-.021.42-.021.818-.021,1.149H8.893V7.5c0-.51,0-.994.021-1.343A3.59,3.59,0,0,0,5.524,2.376h-.49L5.118.1A1.907,1.907,0,0,0,3.9.1l.085,2.254H3.37A3.574,3.574,0,0,0,0,6.133V8.674a4.032,4.032,0,0,0,1.45,3.182l4.244,3.227c0,.818-.021,2.387-.043,3.293-.64.022-1.642.022-2.3,0a6,6,0,0,0,.021-.641c.021-.309.043-2.011.043-2.166v-.818H.107v1.171c0,.685,0,1.414-.021,1.923A3.515,3.515,0,0,0,3.476,21.6h.512L3.9,23.9A1.907,1.907,0,0,0,5.118,23.9Z" transform="translate(8)" fill={color} />
            </g>
        </svg>
    );
};