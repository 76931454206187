import React from 'react'
import PropTypes from 'prop-types';
import DrawnArrow from '../SVG/DrawnArrow'

function CTAAnchorTag({handleClick, children, showArrow = false, href='#', className='CTA-Anchor-Tag', target='', ariaLabel=""}) {
    return (
        <a className={className} onClick={handleClick} href={href} target={target} aria-label={ariaLabel}>
            <span className={`${className}__Text`}>
                {showArrow && <DrawnArrow />}
                {children}
            </span>
        </a>
    )
}

CTAAnchorTag.propTypes = {
    handleClick: PropTypes.func,
    children: PropTypes.any,
    showArrow: PropTypes.bool,
    href: PropTypes.string,
    className: PropTypes.string,
    target: PropTypes.string,
    ariaLabel: PropTypes.string
};

export default CTAAnchorTag;