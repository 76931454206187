import React from 'react';
import Globe from '../../../SVG/Globe';
import { DateTime } from 'luxon';
import './scholarship-name-cell-type.scss';

export default ({ value, globeHide=false }) => {
    return (
        <span className='scholarship-name-cell-type'>
            {!globeHide && <span className='scholarship-name-cell-type__icon'><Globe /></span> }
            <span className='scholarship-name-cell-type__value'>{ value }</span>
        </span>
    );
};
