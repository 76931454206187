import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "200", height = "200", ariaLabel = "Switch Profile", ariaLabelDescription = "Switch Profile" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 200">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <defs>
                <clipPath id="clip-switch-profile">
                    <rect width="200" height="200" />
                </clipPath>
            </defs>
            <g id="switch-profile" clipPath="url(#clip-switch-profile)">
                <g id="noun_switch_939466" transform="translate(-17.861 349.298)">
                    <g id="Group_1886" data-name="Group 1886" transform="translate(23 -343)">
                        <g id="Group_1885" data-name="Group 1885" transform="translate(4.861 5.702)">
                            <path id="Path_7896" data-name="Path 7896" d="M54.723,83.962a22.4,22.4,0,1,0-18.271,18.279A22.424,22.424,0,0,0,54.723,83.962ZM18.54,82.981A14.388,14.388,0,1,1,29.827,94.276,14.407,14.407,0,0,1,18.54,82.981Z" transform="translate(0.551 45.909)" fill={color} />
                            <path id="Path_7897" data-name="Path 7897" d="M45.981,81.671H30.143A25.311,25.311,0,0,0,4.861,106.953H12.87A17.293,17.293,0,0,1,30.143,89.68H45.981a17.272,17.272,0,0,1,17.273,17.273h8.009A25.311,25.311,0,0,0,45.981,81.671Z" transform="translate(-4.861 69.694)" fill={color} />
                            <path id="Path_7898" data-name="Path 7898" d="M85.969,50.162A22.4,22.4,0,1,0,67.691,31.89,22.425,22.425,0,0,0,85.969,50.162Zm.969-36.181A14.388,14.388,0,1,1,75.652,25.276,14.407,14.407,0,0,1,86.938,13.982Z" transform="translate(57.035 -5.702)" fill={color} />
                            <path id="Path_7899" data-name="Path 7899" d="M103.1,29.591H87.257A25.311,25.311,0,0,0,61.975,54.873h8.009A17.293,17.293,0,0,1,87.257,37.6H103.1a17.293,17.293,0,0,1,17.273,17.273h8.009A25.31,25.31,0,0,0,103.1,29.591Z" transform="translate(51.622 18.007)" fill={color} />
                            <path id="Path_7900" data-name="Path 7900" d="M90.831,67.453,96.275,72.9l.218.218L106.5,63.108V92.387a19.322,19.322,0,0,1-19.322,19.322H59.051L71.3,99.459,65.637,93.8,45.53,113.9l19.242,19.24,5.662-5.662-7.765-7.765H87.177a27.33,27.33,0,0,0,27.331-27.331V63.975l10.007,10.007,5.662-5.662-20.1-20.109Z" transform="translate(35.14 36.159)" fill={color} />
                            <path id="Path_7901" data-name="Path 7901" d="M75.022,47.582,95.129,27.474,75.889,8.234,70.227,13.9l7.765,7.765H53.482A27.33,27.33,0,0,0,26.152,48.992V77.4L16.144,67.4l-5.662,5.662L30.589,93.167l19.24-19.24-5.444-5.444-.218-.218L34.159,78.273V48.992A19.322,19.322,0,0,1,53.48,29.67H81.606L69.358,41.92Z" transform="translate(0.773 -3.163)" fill={color} />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    );
};
