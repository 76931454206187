import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, height = "200px", width = "200px" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 200">
            <title>Menu Icon</title>
            <desc>Menu Icon</desc>
            <defs>
                <clipPath id="clip-menu-icon">
                    <rect width="200" height="200" />
                </clipPath>
            </defs>
            <g id="menu-icon" clipPath="url(#clip-menu-icon)">
                <path id="Icon_open-menu" data-name="Icon open-menu" d="M0,0V22.5H180V0ZM0,66.825v22.5H180v-22.5Zm0,67.5v22.5H180v-22.5Z" transform="translate(10 21.588)" fill={color} />
            </g>
        </svg>
    );
}
