import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "200", height = "200" }) => {
    return (

        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 200">
            <title>Update</title>
            <desc>Update</desc>
            <defs>
                <clipPath id="clip-Update">
                    <rect width="200" height="200" />
                </clipPath>
            </defs>
            <g id="Update" clipPath="url(#clip-Update)">
                <g id="noun_update_128118" transform="translate(-4 6)">
                    <g id="Group_325" data-name="Group 325" transform="translate(95.539 93.031)">
                        <g id="Group_321" data-name="Group 321" transform="translate(14.101 0)">
                            <path id="Path_7805" data-name="Path 7805" d="M57.529,77.971H45.138c0-21.684,16.409-36.875,36.578-36.875a36.185,36.185,0,0,1,31.349,17.577l-10.613,6.319A23.794,23.794,0,0,0,81.716,53.524C68.38,53.524,57.529,65.58,57.529,77.971Z" transform="translate(-45.138 -41.096)" fill={color} />
                        </g>
                        <g id="Group_322" data-name="Group 322" transform="translate(0 21.771)">
                            <path id="Path_7806" data-name="Path 7806" d="M62.326,78.627,40.586,56.884l8.76-8.76L62.326,61.1,75.308,48.124l8.76,8.76Z" transform="translate(-40.586 -48.124)" fill={color} />
                        </g>
                        <g id="Group_323" data-name="Group 323" transform="translate(16.437 43.071)">
                            <path id="Path_7807" data-name="Path 7807" d="M77.241,91.076A37.028,37.028,0,0,1,45.892,73.1L56.5,66.582A24.447,24.447,0,0,0,77.241,78.747c13.336,0,24.187-11.356,24.187-23.747h12.391C113.816,73.586,97.407,91.076,77.241,91.076Z" transform="translate(-45.892 -55)" fill={color} />
                        </g>
                        <g id="Group_324" data-name="Group 324" transform="translate(54.978 26.074)">
                            <path id="Path_7808" data-name="Path 7808" d="M93.056,80.013,80.077,67.034,67.094,80.013l-8.76-8.76,21.743-21.74,21.74,21.74Z" transform="translate(-58.334 -49.513)" fill={color} />
                        </g>
                    </g>
                    <g id="Group_328" data-name="Group 328" transform="translate(14 16)">
                        <g id="Group_326" data-name="Group 326">
                            <path id="Path_7809" data-name="Path 7809" d="M85.248,143.007H26.391A12.4,12.4,0,0,1,14,130.616V28.391A12.4,12.4,0,0,1,26.391,16h62.5l15.489,27.88H153.4A12.4,12.4,0,0,1,165.789,56.27V81.052H153.4V56.27H97.09L81.6,28.391H26.391V130.616H85.248Z" transform="translate(-14 -16)" fill={color} />
                        </g>
                        <g id="Group_327" data-name="Group 327" transform="translate(80.541 9.293)">
                            <path id="Path_7810" data-name="Path 7810" d="M105.052,46.88H92.661V32.822a1.432,1.432,0,0,0-1.431-1.431H40V19H91.23a13.839,13.839,0,0,1,13.822,13.822Z" transform="translate(-40 -19)" fill={color} />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    );
};
