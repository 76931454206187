import React, { useState, useRef, useEffect } from 'react';
import './chat-box.scss';
import { MAIN_CONFIG } from '../../../config/main';
import Close from '../../Components/SVG/Close';
import Field from '../../Components/Field/Field';
import CTAButton from '../../Components/CTAButton/CTAButton';
import { DateTime } from 'luxon';
import Expand from '../../Components/SVG/Expand';
import Minimize from '../../Components/SVG/Minimize';
import UserBadge from '../../Components/UserBadge/UserBadge';
import TimeAgo from 'react-timeago';

export default ({
    currentUser = {},
    isOpenChatBox =false, 
    setIsOpenChatBox = () => {},
    index=1, 
    showChatBox= false, 
    userParticularChat= {}, 
    handleCreateMessage =() => {}, 
    handleClickClose = () => {},
    updateScroll = () => {},
    currentAccount
}) => {
    const [isToggleType, setIsToggleType]=useState("minimize")
    const [message, setMessage]=useState("")
    const [btnDisabled, setDisabled]=useState(false)

    const receiverMsgs = userParticularChat.messages.filter(msg => msg.user_id !== currentUser.id)

    const [active, setActive]=useState(false)

    const handleSendMessage= async (e) => {
        e.preventDefault()
        if(message) {
            await setDisabled(true)
            await handleCreateMessage(e, userParticularChat.uuid, message)
            setMessage('')
            await setDisabled(false)
        }
    }

    useEffect(() => {
        setTimeout(updateScroll(userParticularChat.uuid), 2000)
    }, [userParticularChat.uuid, userParticularChat.messages.length])

    const handleClickToggle =(type) => {
        setIsToggleType(type)
    }

    var messageDate =""
    var showDate=true

    const primaryColor = currentAccount ? currentAccount.primary_color__c : MAIN_CONFIG.COLORS.fillGreen;
    const secondaryColor = currentAccount ? currentAccount.secondary_color__c : MAIN_CONFIG.COLORS.fillWhite;
    const receiverUser = currentUser.id === userParticularChat.sender.id ? userParticularChat.receiver : userParticularChat.sender

    const getStatus = () => {
        var now = (new Date()).getTime()
        var isOnline = receiverMsgs.length > 0 ? DateTime.fromISO(userParticularChat.messages[0].read_at).toMillis() < 30000 : false
        return userParticularChat.messages.length > 0 ? isOnline ? 'Online' : <TimeAgo date={DateTime.fromISO(userParticularChat.messages[0].read_at).toMillis()} /> : "Offline"
    }

    return (
        <div
            className="open-particular-chatting"
        >
            <div className={`msg-overlay-conversation-bubble chat-window-${index} ${isToggleType === "expand" && "msg-overlay-conversation-bubble--jumbo"}`}
                style={{right: !showChatBox && (index == 1 ? "0px" : "360px")} }
            >
                <header className="msg-overlay-bubble-header">
                    <div className="msg-overlay-bubble-header__profile-image">
                        <UserBadge
                            firstName={receiverUser.first_name}
                            lastName={receiverUser.last_name}
                            profileImage={receiverUser.avatar_data}
                            backgroundColor={secondaryColor}
                            color={primaryColor}
                        />
                        <div className={`presence-entity__indicator presence-entity__indicator--size-1 presence-indicator presence-indicator--is-reachable presence-indicator--size-1 ${getStatus() === 'Online' && 'presence-indicator--is-online'}`}></div>
                    </div>
                    <div className="msg-overlay-bubble-header__details">
                        <div>
                            <h4 className="msg-overlay-bubble-header__title">
                                {receiverUser.first_name} {receiverUser.last_name}
                            </h4>
                            <div className="msg-overlay-bubble-header__device-name-with-timestamp">Mobile <span>.</span> {getStatus()}</div>
                        </div>
                    </div>
                    <div className="msg-overlay-bubble-header__controls">                
                        {
                            isToggleType === "expand" ?
                            <button className="full-screen-menu__close-btn" onClick={() => handleClickToggle("minimize")}>
                                <Minimize color = {MAIN_CONFIG.COLORS.fillWhite } />
                            </button>
                            :
                            <button className="full-screen-menu__close-btn" onClick={() => handleClickToggle("expand")}>
                                <Expand color = {MAIN_CONFIG.COLORS.fillWhite } />
                            </button>
                        }

                        <button className="full-screen-menu__close-btn" onClick={() => handleClickClose(index-1)}>
                            <Close color = {MAIN_CONFIG.COLORS.fillWhite } />
                        </button>
                    </div>
                </header>

                <div className="msg-overlay-conversation-bubble__content-wrapper relative display-flex flex-column">
                    <div className="msg-s-message-list-container relative display-flex mtA">
                        <div className="msg-s-message-list full-width scrollable">
                            <ul className="msg-s-message-list-content list-style-none full-width">
                                {
                                    userParticularChat.messages &&
                                    userParticularChat.messages.length> 0 &&
                                    userParticularChat.messages.map((msg, i ) => {
                                        if(msg.created_at !== messageDate){
                                            messageDate = msg.created_at
                                            showDate=true
                                        }else{
                                            showDate=false
                                        }
                                        const msger = msg.user_id === userParticularChat.sender.id ? userParticularChat.sender : userParticularChat.receiver
                                        return <li key={msg.id} className="msg-s-message-list__event">
                                                <time className="msg-s-message-list__time-heading"></time>
                                                <div className="msg-s-event-listitem">
                                                    <a href="#" className="msg-s-event-listitem__link">
                                                        <UserBadge
                                                            firstName={msger.first_name}
                                                            lastName={msger.last_name}
                                                            profileImage={msger.avatar_data}
                                                        />
                                                    </a>
                                                    <div className="msg-s-message-group__meta">
                                                        <a href=""><span className="msg-s-message-group__name">
                                                           {`${msger.first_name} ${msger.last_name}`}
                                                        </span></a>
                                                        <time className="msg-s-message-group__timestamp">
                                                            {DateTime.fromISO(msg.created_at).toFormat('t')}
                                                        </time>
                                                    </div>
                                                    <div className="msg-s-event-listitem__message-bubble">
                                                        <p className="msg-s-event-listitem__body">{msg.text}</p>
                                                    </div>
                                                </div>
                                            </li>
                                    })
                                }
                                <li id={`msg-container__${userParticularChat.uuid}`}>&nbsp;&nbsp;</li>
                            </ul>
                        </div>
                    </div>

                    <form className="msg-form">
                        <div className="msg-form__msg-content-container msg-form__message-texteditor">
                            <div className="msg-form__msg-content-container--scrollable scrollable">
                                <div className="flex-grow-1">
                                    <div className="msg-form__contenteditable t-14 t-black--light t-normal flex-grow-1 full-height notranslate">
                                        <Field 
                                            className='' 
                                            id='write-a-message-textarea' 
                                            type='textarea'  
                                            value={message}
                                            handleChange={setMessage}
                                            placeholder="Write a message..."
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer className="msg-form__footer flex-shrink-zero ">
                            <div className="msg-form__left-actions display-flex">
                            </div>
                            <div className="msg-form__right-actions display-flex align-items-center">
                                <div>
                                    <CTAButton id='chat-message-send-button' type='tertiary' onClick={handleSendMessage} disabled={btnDisabled}>
                                        Send
                                    </CTAButton>
                                </div>
                            </div>
                        </footer>
                    </form>
                </div>
            </div>
        </div>
    );
};
