import React, { useState, useEffect } from 'react';
import axios from 'axios'; // You may need to install axios
import { isObject } from 'lodash';
// import './CustomAddressSearchField.css';

export const classnames = (...args) => {
  const classes = [];
  args.forEach(arg => {
    if (typeof arg === 'string') {
      classes.push(arg);
    } else if (isObject(arg)) {
      Object.keys(arg).forEach(key => {
        if (arg[key]) {
          classes.push(key);
        }
      });
    } else {
      throw new Error(
        '`classnames` only accepts string or object as arguments'
      );
    }
  });

  return classes.join(' ');
};

const AzureAddressSearchField = ({ value, handleAddressSelection, fieldObj, name = '' }) => {
  const [address, setAddress] = useState(value || '');
  const [suggestions, setSuggestions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  useEffect(() => {
    setAddress(value);
  }, [value]);

  const handleChange = (newAddress) => {
    setAddress(newAddress);
    if (newAddress.trim() === '') {
      setSuggestions([]); // Clear the suggestions if the input is empty
      setErrorMessage('');
    } else {
      // Fetch suggestions from Azure Maps
      searchAzureMaps(newAddress);
    }
  };
  const searchAzureMaps = (query) => {
    const azureMapsBaseUrl = process.env.NEXT_PUBLIC_AZURE_MAPS_API_URL;
    const azureMapsEndpoint = `${azureMapsBaseUrl}&query=${query}`;
    setIsSearching(true);
    axios
      .get(azureMapsEndpoint)
      .then((response) => {
        const suggestions = response.data.results || [];
        setSuggestions(suggestions);
        setIsSearching(false);
      })
      .catch((error) => {
        setErrorMessage('Error fetching suggestions');
        setIsSearching(false);
      });
  };
  const handleSelect = (selected) => {
    const selectedSuggestion = selected

    if (selectedSuggestion) {
      setAddress(selectedSuggestion.address.freeformAddress);
      // You can use the latitude and longitude from selectedSuggestion to handle the selection
      handleAddressSelection(
        fieldObj,
        selectedSuggestion.address
      );
    }
    setSuggestions([])
  };
  return (
    <div>
      <div className="address__search-bar-container">
        <div className="address__search-input-container">
          <input
            placeholder="Search Places"
            className="H6DesktopBlack field__text-field"
            name={name}
            value={address}
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
        {suggestions.length > 0 && (
          <div className="address__autocomplete-container address-selector">
            {suggestions.map(suggestion => {
              const className = classnames('address__suggestion-item', {
                'address__suggestion-item--active': suggestion.active,
                'suggest-val': true,
              });

              return (
                /* eslint-disable react/jsx-key */
                <div
                  key={suggestion.id}
                  className={className}
                  onClick={() => handleSelect(suggestion)}     >
                  {/* <i className="fa fa-map-marker"></i> */}
                  <div className="address__suggestion-item-big-text">
                    {suggestion.address.freeformAddress}
                  </div>
                  <div className="address__suggestion-item-small-text">
                  {`${suggestion.address.municipalitySubdivision ? suggestion.address.municipalitySubdivision + ',' : ''} ${suggestion.address.municipality || suggestion.address.localName ? suggestion.address.municipality + ',' : ''} ${suggestion.address.country || ''}`}
                  </div>
                </div>
              );
              /* eslint-enable react/jsx-key */
            })}
            <div className="address__dropdown-footer">
              <div>
              </div>
            </div>
          </div>
        )}
      </div>
      {errorMessage && (
        <div className="address__error-message">{errorMessage}</div>
      )}
      {isSearching && (
        <div>
          <i className="fa fa-spinner fa-pulse fa-3x fa-fw address__spinner" />
        </div>
      )}
    </div>
  );
};

export default AzureAddressSearchField;