import React from 'react';

export default ({ id, className }) => {
	return (
		<svg id={id} className={className} stroke="currentColor" fill="currentColor" strokeWidth="0" version="1" viewBox="0 0 48 48" enableBackground="new 0 0 48 48" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
			<title>Circle Info</title>
			<desc>Circle Info</desc>
			<circle fill="#fff" cx="24" cy="24" r="21"></circle>
			<rect x="22" y="22" fill="#000000" width="4" height="11"></rect>
			<circle fill="#000000" cx="24" cy="16.5" r="2.5"></circle>
		</svg>
	);
}
