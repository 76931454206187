import React, { useState, useEffect } from 'react';
import Field from '../../Components/Field/Field';
import CTAButton from '../../Components/CTAButton/CTAButton';
import { MAIN_CONFIG } from '../../../config/main';
import FlashMessage from '../../Components/FlashMessage/Message';
import KaleidoscopeAPI from '../../Core/KaleidoscopeAPI';
import { toast } from 'react-toastify';
import './NewChat.scss';
import SelectAutosuggest from '../SelectAutosuggest/SelectAutosuggest';
import UserBadge from '../../Components/UserBadge/UserBadge';


export default ({
	handleClose = () => { },
	handleSubmit = () => { },
	token
}) => {
	const [contactList, setContactList] = useState([]);
	const [value, setValue] = useState('');
	const [valueSelect, setValueSelect] = useState('');

	const [searchText, setSearchText] = useState('');
	const API = new KaleidoscopeAPI({});

	useEffect(() => {
		(async () => {
			const respones = await API.fetchConversationContacts({ token, value: searchText })
			if (respones.users) {
				var tempArr = []
				respones.users.map((item) => {
					tempArr.push({
						label: getControlData(item),
						value: item.full_name + "," + item.id
					})
				})
				setContactList([...tempArr])
			}
		})();
	}, [searchText]);

	const handleTextChange = (value) => {
		var tempArr = value.split(/[,]+/);
		setSearchText(tempArr[0])
		setValueSelect(tempArr[1])
	}

	const getControlData = (data) => {
		let str = data
		var html = <div style={{ height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
			<div className="auto-suggest-select-user-for-chat">
				<div className="auto-suggest-select-user-for-chat__profile-image">
					<UserBadge
						firstName={data.first_name}
						lastName={data.last_name}
						profileImage={data.avatar_data}
					/>
				</div>
				<div className="auto-suggest-select-user-for-chat__name-and-email-id">
					<div className="auto-suggest-select-user-for-chat__name-and-email-id__name">{data.full_name}</div>
					<div className="auto-suggest-select-user-for-chat__name-and-email-id__email-id">{data.email}</div>
				</div>
			</div>


		</div>
		if (str != "") {
			data = html;
		}
		return data;
	}

	/**
	 * Handle modal field value change.
	 */
	const handleChangeChat = (value) => {
		var tempArr = value.split(/[,]+/);
		var last = tempArr[1]
		var result = tempArr[0]
		setValueSelect(last)
		setValue(result)
	}

	/**
	 * Handle contact form submission.
	 */
	const submitForm = (e) => {
		e.preventDefault();
		if (value) {
			handleSubmit(valueSelect)
		} else {
			toast.error(<FlashMessage message='Please select an user.' />)
		}
	}

	const optionData = (users = []) => {
		return users.map(user => {
			return {
				label: user.full_name,
				value: user.id
			}
		})
	}

	return (
		<div id='task-modal-content'>
			<div className="task-head_container">
				<div className="H1DesktopGreen event-head">
					Please select a contact.
				</div>
			</div>

			<div className="user-task-add__form">
				<form onSubmit={(e) => submitForm(e)}>
					<div className="section group">
						<div className="col span_12_of_12">
							<SelectAutosuggest
								options={contactList}
								handleSchoolSearch={handleTextChange}
								handleChangeChat={handleChangeChat}
								isChat={true}
								isSchool={searchText}
							/>
						</div>
					</div>

					<div className="chat-select-user-footer">
						<CTAButton id='footer-submit-btn' type='tertiary' disabled={(valueSelect) ? false : true}>
							Submit
						</CTAButton>
						<CTAButton id='footer-cancel-btn' type='inverse' onClick={handleClose}>
							Cancel
						</CTAButton>
					</div>

				</form>
			</div>
		</div>
	)
}