import React from 'react';
import PropTypes from 'prop-types';
import FieldCheckbox from '../../../SVG/FieldCheckbox';
import { uuid4 } from '@sentry/utils';
import '../CheckCleared/CheckCleared.scss'
import CheckBox from '../../../SVG/CheckBox';

const CheckCleared = ({ value, columnId }) => {

    const getTite = (customTitle, defaultTitle) => {
        if(columnId == 'check_cleared__c'){
            return customTitle
        } else {
            return defaultTitle
        }
    }

    return (
        <div className="checkbox-incomplete verified-notVerified">
            <label className="option-checkbox">
                <span className="checkbox__input">
                    <input
                        className="table__checkbox"
                        id={uuid4()}
                        type="checkbox"
                        checked={value}
                        disabled
                    />
                    <span className="checkbox__control">
                       {value ? 
                       <FieldCheckbox title={getTite('Check Cleared', 'Checked')} id={uuid4()} />
                       :
                       <CheckBox title={getTite('Check Not Cleared', 'Unchecked')} id={uuid4()} width={'20.95'} height={'20.95'} />
                     } 
                       
                    </span>
                </span>
            </label>
        </div>
    );}

export default CheckCleared;

CheckCleared.propTypes = {
      value: PropTypes.bool,
      columnId: PropTypes.string
      // ... other expected properties
  };