import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ width = 40, height = 40, color = MAIN_CONFIG.COLORS.fillGreen, ariaLabel = "TestTubes", ariaLabelDescription = "TestTubes" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 40 40">
        <title>{ariaLabel}</title>
        <desc>{ariaLabelDescription}</desc>
        <defs>
            <filter id="z2yayudr5a">
                <feColorMatrix in="SourceGraphic"
                    values="0 0 0 0 0.000000 0 0 0 0 0.501961 0 0 0 0 0.403922 0 0 0 1.000000 0" />
            </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
            <g>
                <g transform="translate(-106 -988) translate(106 988)">
                    <g>
                        <path fill={color}
                            d="M11.475 1.936c-.127 0-.23.103-.23.23v8.501H1.677v-8.5c0-.128-.103-.231-.23-.231-.43 0-.985-.358-.985-.74 0-.405.33-.734.735-.734h10.528c.406 0 .736.33.736.735 0 .38-.555.739-.986.739M11.725 0H1.197C.537 0 0 .537 0 1.197c0 .588.62 1.075 1.216 1.18v26.056c0 3.001 2.353 5.444 5.245 5.444s5.245-2.443 5.245-5.444v-2.94H6.888v-.462h4.818v-3.189H6.888v-.461h4.818v-3.189H6.888v-.462h4.818v-3.188H6.888v-.462h4.818V2.377c.596-.105 1.216-.592 1.216-1.18 0-.66-.537-1.197-1.197-1.197M22.804 9.718c-.127 0-.23.104-.23.23v6.4h-7.089v-6.4c0-.126-.103-.23-.23-.23-.293 0-.685-.247-.685-.5 0-.273.223-.496.497-.496h7.925c.274 0 .497.223.497.497 0 .252-.392.5-.685.5zm-3.467 9.63h3.68v-.462h-3.68v.462zm0 2.739h3.68v-.462h-3.68v.462zm0 2.74h3.68v-.462h-3.68v.461zm0 2.738h3.68v-.461h-3.68v.461zm3.655-19.304h-7.925c-.529 0-.959.43-.959.958 0 .463.453.838.916.936v19.567c0 2.29 1.797 4.155 4.005 4.155 2.209 0 4.006-1.864 4.006-4.155V10.155c.463-.098.915-.473.915-.936 0-.529-.43-.958-.958-.958z"
                            transform="translate(8 3.077)" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
