import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, width='20px', height='20px', className, color = MAIN_CONFIG.COLORS.fillWhite, secondaryColor = MAIN_CONFIG.COLORS.fillWhite, stroke = MAIN_CONFIG.COLORS.fillBlack }) => {
	return (
		<svg id={id} className={className} stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 48 48" enableBackground="new 0 0 48 48" height={height} width={width} xmlns="http://www.w3.org/2000/svg">
			<title>Circle Check</title>
			<desc>Circle Check</desc>
			<circle fill={color} cx="24" cy="24" r="21" stroke={stroke} stroke-width="4"></circle>
			<polygon fill={secondaryColor} points="34.6,14.6 21,28.2 15.4,22.6 12.6,25.4 21,33.8 37.4,17.4"></polygon>
		</svg>
	);
}
