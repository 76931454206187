import React from 'react';
import './progress-complete-cell-type.scss';

export default ({ value, barBackgroundColor, barProgressColor = "#1C21DF" }) => {
    // Handle cases when a non-percent value is passed

    const showProgressBar = value === null  || value.indexOf('%') !== -1;


    return (
        <div className='progress-complete-cell-type'>
            <div className='progress-complete-cell-type__text BodySmallRegularBlack'>{value ? value : '0%'}</div>
            {
                showProgressBar &&
                <div className='progress-complete-cell-type__bar-container'>
                    <div className='progress-complete-cell-type__bar' barBackgroundColor={barBackgroundColor}> </div>
                    <div className='progress-complete-cell-type__bar active' barProgressColor={barProgressColor} style={{ width: value.replace("%","") > 100 ? 100+"%" : value === 0 ? 0 : value , backgroundColor: `${barProgressColor}` }}> </div>
                </div>
            }
        </div>
    );
};
