import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';


export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGrey, width = "24px", height = "24px", ariaLabel = "Pencil", ariaLabelDescription = "Pencil",tabIndex = -1 }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" tabIndex={tabIndex}>
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <defs>
                <filter id="hgee3yq6va">
                    <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.450980 0 0 0 0 0.450980 0 0 0 0 0.450980 0 0 0 1.000000 0" />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-377 -322) translate(164 318)">
                        <g>
                            <path fill={color} d="M19.648.364c-.18-.005-.354.01-.523.045-.339.072-.65.229-.898.477l-3 3-12.58 12.58-.056.057-.023.08-2.182 6.545-.238.693.693-.227 6.545-2.182.08-.023.068-.068 15.58-15.568c.497-.497.613-1.233.454-1.966-.159-.733-.588-1.497-1.238-2.148-.651-.65-1.404-1.069-2.137-1.227-.183-.04-.366-.064-.545-.068zm-.193.727c.175-.014.366 0 .58.045.567.123 1.222.473 1.783 1.034.562.562.923 1.217 1.046 1.785.123.567.02 1.024-.262 1.306l-2.136 2.137c-.012-.178-.044-.364-.091-.546-.179-.694-.591-1.41-1.205-2.022-.613-.614-1.328-1.026-2.022-1.205-.186-.048-.374-.067-.557-.08l2.148-2.147c.14-.141.326-.24.545-.284.055-.012.112-.018.17-.023zm-2.887 3.17c.125.01.261.033.398.069.546.14 1.153.482 1.682 1.01.528.53.882 1.148 1.022 1.694.141.547.073.999-.147 1.296L7.966 19.875c-.017-.202-.055-.408-.114-.614-.191-.669-.596-1.358-1.182-1.943-.585-.585-1.262-.979-1.931-1.17-.21-.06-.42-.096-.625-.114L15.659 4.477c.223-.165.534-.246.91-.216zM3.795 16.75c.222-.014.472.015.74.09.533.154 1.122.499 1.624 1 .502.503.836 1.08.989 1.615.149.52.122.978-.046 1.284l-.011.022H7.08l-3.33 1.114c-.196-.317-.432-.636-.716-.92-.289-.29-.6-.52-.92-.716l1.113-3.319v-.01c.007-.005.016-.008.023-.012.15-.083.334-.135.545-.148zm-1.92 4.205c.223.148.444.319.648.522.202.203.374.42.522.637l-1.75.58.58-1.74z" transform="translate(213 4)" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
