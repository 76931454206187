import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "24", height = "24", iconColor = MAIN_CONFIG.COLORS.fillBlack }) => {
    return (
        <svg id={id} className={className} data-name="Elements / System / Number" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
            <title>Number</title>
            <desc>Number</desc>
            <path id="_" data-name="#" d="M4.612,20H.957c.2-1.63.386-3.264.539-4.667H.074a15.5,15.5,0,0,1,0-3.011H1.815l.392-4.341H.564a15.5,15.5,0,0,1,0-3.01H2.453C2.583,3.215,2.7,1.628,2.8,0H6.451L5.887,4.971H8.045L8.058,4.8c.116-1.573.236-3.2.331-4.8h3.655L11.48,4.971h1.447a15.5,15.5,0,0,1,0,3.01H11.185c-.148,1.451-.272,2.919-.392,4.339l1.643,0a15.5,15.5,0,0,1,0,3.011H10.547l-.023.3c-.112,1.428-.228,2.906-.32,4.37H6.549c.2-1.63.386-3.264.539-4.667H4.955l-.023.3c-.112,1.429-.228,2.906-.32,4.37Zm.981-12.02C5.445,9.432,5.32,10.9,5.2,12.32l2.208,0L7.8,7.981H5.593Z" transform="translate(6 2)" fill={color} />
        </svg>
    );
};