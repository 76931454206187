export function applicationIsLoading(state = false, action) {
  	switch (action.type) {
	    case "APPLICATION_IS_LOADING":
	        return action.isLoading;

	    default:
	        return state;
  	}
}


export function conversations(state = [], action) {
  	switch (action.type) {
	    case "FETCH_USER_CONVERSATIONS":
	        return action.conversations;

	    default:
	        return state;
  	}
}


export function metaTitle(state = '', action) {
	switch (action.type) {
	  case "SET_MATE_TAG":
		  return action.metaTags;

	  default:
		  return state;
	}
}


export function handleCurrentTabIndex (state = 0 , action){
	switch(action.type){
		case "CURRENT_TAB_INDEX_ACTION":
			return action.index

		default:
			return state
	}
}


export function handleperPageCount (state = { label: '10', value: 10 } , action){
	switch(action.type){
		case "CURRENT_ITEM_PER_PAGE":
			return action.itemPerPage

		default:
			return state
	}
}

export function backButtonClicked(state= false , action){
	switch(action.type){
		case "BACK_BUTTON_CLICKED":
			return action.isClicked

		default:
			return state
	}
}

export function manageFilters(state= false , action){
	switch(action.type){
		case "MANAGE_FILTER_DATA":
			return action.filters
    default:
      return state
	}
}
export function downloadLink(state= false , action){
	switch(action.type){
		case "DOWNLOAD_CLICKED":
			console.log(action.isDownloadClicked , "this is action.isLCicked in the reducer");
			return action.isDownloadClicked
		default:
			return state
	}
}

export function uploadPercentage(state= 0 , action){
	switch(action.type){
		case "UPLOAD_PERCENT":
			return action.percentage

		default:
			return state
	}
}
const initialState = {
	visible: false,
	className: '',
	content: [],
};
export function secondaryModalReducer (state = initialState, action) {
	switch (action.type) {
		case "UPDATE_SECONDARY_MODAL_STATE":
			return action.payload

		default:
			return state
	}
}