import React from 'react';
import './date-cell-type.scss';
import { DateTime } from 'luxon';
import Calendar from '../../../SVG/Calendar';
import { formatDateByMoment } from '../../../../Utility/FormatDate';  // ../../../Utility/FormatDate';

export default ({ value }) => {
    // TODO: Update this when dates are available in tables
    const date = value ? formatDateByMoment(value, 'MMM DD'): 'N/A'; // DateTime.fromISO(value).toFormat('LLL dd') : 'N/A';
    return (
        <div className='date-cell-type'>
            <div className='date-cell-type__icon'><Calendar ariaLabel="Calendar Icon" ariaLabelDescription="for date" /></div>
            <div className='date-cell-type__date BodySmallMediumBlack'>{date}</div>
        </div>
    );
};
