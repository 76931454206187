import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "24", height = "24" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 -4.5 16 24" fill={color} width={width} height={height} focusable="false">
            <title>Expand</title>
            <desc>Expand</desc>
            <path d="M8 6.59L6.59 8 3 4.41V7H1V1h6v2H4.41zM13 9v2.59L9.41 8 8 9.41 11.59 13H9v2h6V9z"></path>
        </svg>
    );
};