import React, { useState, useEffect } from 'react';
import './review-board-modal-content.scss';
import CTAButton from '../CTAButton/CTAButton';
import Field from '../Field/Field';
import UserBadge from '../UserBadge/UserBadge';
import { debounce } from 'throttle-debounce';
import { MAIN_CONFIG } from '../../../config/main';
import KaleidoscopeAPI from '../../Core/KaleidoscopeAPI';
import { ToastContainer, toast } from 'react-toastify';
import FlashMessage from '../FlashMessage/Message';
import { validateEmailRegex } from '../../Utility/HelperFunctions';

/**
 *
 * @param {object} data
 * @param {function} handleClose
 * @param {function} handleSave
 * @param {function} handleAdd
 * @param {function} handleRemove
 * @returns {JSX.Element}
 */
export default ({
                    scholarship = false,
                    data,
                    handleClose = () => {},
                    handleSave = () => {},
                    handleAdd = () => {},
                    handleRemove = () => {},
                    token,
                    accountId,
                    scholarshipId,
                    loading=false,
                    setLoading= () => {},
}) => {


    const [currentSearchTerms, setCurrentSearchTerms] = useState('');
    const [currentMembers, setCurrentMembers] = useState([]);
    const [currentMembersCopy, setCurrentMembersCopy] = useState([]);
    const [currentTitle, setCurrentTitle] = useState('REVIEW BOARD');
    const [showInviteView, setShowInviteView] = useState(false);
    const [inviteEmailAddress, setInviteEmailAddress] = useState('');
    const [addedMemberCount, setAddedMemberCount] = useState('');
    const [userExist, setUserExist] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [error, setError] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    // Update when props change
    useEffect(() => {
        if (!scholarship) {
            const { associated_rb_members, other_rb_members } = data;
            var members = []
            if (associated_rb_members) {
                members = [
                    ...associated_rb_members.map(i => ({ ...i, currentMember: true })),
                    ...other_rb_members.map(i => ({ ...i, currentMember: false }))
                ];
            }else{
                members = [
                    ...other_rb_members.map(i => ({ ...i, currentMember: false }))
                ]
            } 

            setCurrentMembers(members);
            setAddedMemberCount(associated_rb_members?.length || 0);
            setCurrentMembersCopy(members)
        } else {
            if (data.length > 0) {
                setCurrentMembers(data);
                setCurrentMembersCopy(data)
                setAddedMemberCount(data.map(i => (i.currentMember === true)).length);
            }
        }
    }, [data]);


    const API = new KaleidoscopeAPI({});

    // Search
    useEffect(() => {
        // TODO: Trigger search
    }, [currentSearchTerms])

    // TODO: Determine other role options
    const roleOptions = [
        { label: 'Reviewer', value: 'reviewer' }
    ]

    /**
     * Handle adding a member as a reviewer.
     * @param {object} member
     */
    const handleAddReviewer = (member) => {
        handleAdd(member);
    }

    /**
     * Handle removing a current member as a reviewer.
     * @param {object} member
     */
    const handleRemoveReviewer = (member) => {
        handleRemove(member);
    }

    /**
     * Execute search on reviewers
     * @type {function(): (undefined)}
     */
    const handleSearch = debounce(
        100,
        false,
        (value) => {
            var tempArr = []
            var currentMembersTemp = currentMembersCopy
            if (value) {
                if (currentMembersTemp.length > 0) {
                    currentMembersTemp.map((item) => {
                        if (item.full_name.toLowerCase().search(value.toLowerCase()) !== -1 || item.email.toLowerCase().search(value.toLowerCase()) !== -1) {
                            tempArr.push(item)
                        }
                    })
                    if(tempArr.length === 0 && validateEmailRegex(value)){
                        searchAPI(value)
                    }
                    setCurrentMembers(tempArr)
                    setError(false)
                }else{
                    if(tempArr.length === 0 && validateEmailRegex(value)){
                        searchAPI(value)
                    }
                }
            }
            else {
                setCurrentMembers(currentMembersCopy)
            }
            // TODO: Handle search logic here
            setCurrentSearchTerms(value);
            setInviteEmailAddress(value)
        });

    const searchAPI = async(value) => {
        const response = await API.searchReviewBoardMember({ token: token, accountId: accountId, scholarshipId: scholarshipId, email: value })
        if(response.success){
            setUserExist(true)
        }else{
            setUserExist(false)
        }
    } 


    const handleShowInvite = () => {
        if(validateEmailRegex(currentSearchTerms)){
            setShowInviteView(true)
        }else{
            setError(true)
        }
    }

    const handleHideInvite = () => {
        setShowInviteView(false); 
        if(scholarship){
            setCurrentMembersCopy(data)
            setCurrentMembers(data)
        }else{
            setCurrentMembers(currentMembersCopy)            
            setCurrentMembersCopy(currentMembersCopy)            
        }
        setInviteEmailAddress("")
        setFirstName("")
        setLastName("")
        setCurrentSearchTerms("")
    }

    const handleInvite = async(e) => {
        e.preventDefault();
        setDisableButton(true)
        // TODO: Add logic to invite user to review board
        const response = await API.inviteReviewBoardMember({ token, accountId, scholarshipId, firstName, lastName, email: inviteEmailAddress })
        if(response.success){
            toast.success(<FlashMessage type="success" message={response.message} />)
            handleHideInvite()
        }
        setDisableButton(false)
    }

    /**
     * Render the member list list.
     * @returns {object[]}
     */
    const renderMembers = () => {
        if (scholarship) {
            if (currentMembers.length > 0) {
                return currentMembers.map((member, index) => {
                    const { firstname, lastname, avatar, currentMember, email } = member;
                    const ctaType = currentMember ? 'secondary' : 'tertiary';
                    const ctaText = currentMember ? 'Remove' : 'Add';
                    const ctaAction = currentMember ? handleRemoveReviewer : handleAddReviewer;

                    return (
                        <li className='review-board-modal-content__member' key={`review-board-modal-member-${index}`}>
                            <div className='review-board-modal-content__member-left'>
                                {/* // TODO: Pass colors here for user badge */}
                                <UserBadge
                                    firstName={firstname}
                                    lastName={lastname}
                                    profileImage={avatar}
                                />
                                <div className='review-board-modal-content__member-text'>
                                    <div className='BodyDefaultBoldBlack review-board-modal-content__member-name'>
                                        <span>{firstname} </span><span>{lastname}</span>
                                    </div>
                                    <div className='BodySmallRegularGrey review-board-modal-content__member-email'>
                                        {email}
                                    </div>
                                </div>
                            </div>

                            <div className='review-board-modal-content__member-right'>
                                <div className='BodySmallRegularGrey review-board-modal-content__member-role'>

                                </div>
                                <CTAButton type={ctaType} small onClick={(() => ctaAction(member))}>
                                    {ctaText}
                                </CTAButton>
                            </div>
                        </li>
                    );
                });
            } else {
                return (
                    <div id='review-board-modal-content__no-results'>
                        <div className='BodyDefaultBoldBlack' id='review-board-modal-content__no-results-text'>
                            No one in your organization matches this search
                        </div>
                        <div
                            id='review-board-modal-content__no-results-invite'
                            className='CTABlue'
                            onClick={handleShowInvite}
                        >
                            Invite them
                        </div>
                    </div>
                );
                // return (
                //     <div id='review-board-modal-content__no-results'>
                //         <div className='BodyDefaultBoldBlack' id='review-board-modal-content__no-results-text'>
                //             No one in your organization matches this search
                //         </div>
                //     </div>
                // );
            }
        } else {
            if (currentMembers.length > 0) {
                return currentMembers.map((member, index) => {
                    const { firstname, lastname, avatar, email, role, currentMember } = member;
                    const ctaType = currentMember ? 'secondary' : 'tertiary';
                    const ctaText = currentMember ? 'Remove' : 'Add';
                    const ctaAction = currentMember ? handleRemoveReviewer : handleAddReviewer;

                    return (
                        <li className='review-board-modal-content__member' key={`review-board-modal-member-${index}`}>
                            <div className='review-board-modal-content__member-left'>
                                {/* // TODO: Pass colors here for user badge */}
                                <UserBadge
                                    firstName={firstname}
                                    lastName={lastname}
                                    profileImage={avatar}
                                />
                                <div className='review-board-modal-content__member-text'>
                                    <div className='BodyDefaultBoldBlack review-board-modal-content__member-name'>
                                        <span>{firstname} </span><span>{lastname}</span>
                                    </div>
                                    <div className='BodySmallRegularGrey review-board-modal-content__member-email'>
                                        {email}
                                    </div>
                                </div>
                            </div>

                            <div className='review-board-modal-content__member-right'>
                                <div className='BodySmallRegularGrey review-board-modal-content__member-role'>
                                
                                </div>
                                <CTAButton type={ctaType} small onClick={(() => ctaAction(member))}>
                                    {ctaText}
                                </CTAButton>
                            </div>
                        </li>
                    );
                });
            } else {
                return (
                    <div id='review-board-modal-content__no-results'>
                        <div className='BodyDefaultBoldBlack' id='review-board-modal-content__no-results-text'>
                            No one in your organization matches this search
                        </div>
                        <div
                            id='review-board-modal-content__no-results-invite'
                            className='CTABlue'
                            onClick={handleShowInvite}
                        >
                            Invite them
                        </div>
                    </div>
                );
            }
        }
    };

    return (
        <div id='review-board-modal-content'>
            {!showInviteView &&
                <>
                    <div id='review-board-modal-content__top'>
                        <div id='review-board-modal-content__top-left'>
                            <span id='review-board-modal-content__title' className='H3BlackDesktop'>Review Board</span>
                        </div>
                        <CTAButton type='tertiary' outline dark onClick={handleClose}>Back</CTAButton>
                    </div>
                    <Field
                        id='review-board-modal-content__search'
                        type='search'
                        placeholder='Search reviewers'
                        handleChange={handleSearch}
                        stylizePlaceholder
                        errorMsg={error ? "Please enter valid email" : ""}
                    />
                    <ul id='review-board-modal-content__members'>{renderMembers()}</ul>
                    {scholarship && currentMembers.length > 0 && <CTAButton id='review-board-modal-content__save' onClick={handleSave} disabled={loading}>Save Changes</CTAButton>}
                </>
            }

            {showInviteView &&
                <form onSubmit={!disableButton ? handleInvite : ''}>
                    <div id='review-board-modal-content__top'>
                        <div id='review-board-modal-content__top-left'>
                            <span id='review-board-modal-content__title' className='H3BlackDesktop'>Invite To Team</span>
                        </div>
                        <CTAButton type='tertiary' outline dark onClick={() => handleHideInvite()}>Cancel</CTAButton>
                    </div>
                    {!userExist &&
                        <>
                            <Field
                                id='review-board-modal-content__invite-email'
                                type='text'
                                placeholder='First Name'
                                handleChange={setFirstName}
                                value={firstName}
                                label='First Name'
                                required={true}
                            />
                            <Field
                                id='review-board-modal-content__invite-email'
                                type='text'
                                placeholder='Last Name'
                                handleChange={setLastName}
                                value={lastName}
                                label='Last Name'
                                required={true}
                            />    
                        </>
                    }
                    <Field
                        id='review-board-modal-content__invite-email'
                        type='email'
                        placeholder='Reviewer Email'
                        handleChange={setInviteEmailAddress}
                        value={inviteEmailAddress}
                        label='Email Address'
                        disabled={true}
                    />

                    <CTAButton
                        id='review-board-modal-content__save'
                        type='tertiary'
                        disabled={disableButton}
                    >
                        Invite to team
                    </CTAButton>
                </form>
            }

        </div>
    );
};
