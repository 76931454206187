import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "200", height = "200" }) => {
    return (


        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 200">
            <title>User</title>
            <desc>User</desc>
            <defs>
                <clipPath id="clip-User">
                    <rect width="200" height="200" />
                </clipPath>
            </defs>
            <g id="User" clipPath="url(#clip-User)">
                <g id="noun_cogs_230269" transform="translate(51.327 376)">
                    <g id="Group_336" data-name="Group 336" transform="translate(-37 -366)">
                        <g id="Group_334" data-name="Group 334" transform="translate(0.673 44.845)">
                            <path id="Path_7851" data-name="Path 7851" d="M79.291,141.155H53.761a7.1,7.1,0,0,1-6.878-5.7l-2.11-14.98a50.2,50.2,0,0,1-7.884-4.61L22.976,121.46a7.022,7.022,0,0,1-8.545-2.921L1.637,96.426a6.927,6.927,0,0,1,1.554-8.853L15.145,78.21c-.15-1.547-.24-3.086-.24-4.633,0-1.524.09-3.049.233-4.543L3.3,59.762a6.976,6.976,0,0,1-1.629-8.988L14.4,28.751a7.018,7.018,0,0,1,8.485-3l14,5.631a52.857,52.857,0,0,1,7.877-4.6l2.08-14.822A7.157,7.157,0,0,1,53.754,6H79.283a7.1,7.1,0,0,1,6.878,5.7l2.11,14.98a50.2,50.2,0,0,1,7.884,4.61L110.069,25.7a7.03,7.03,0,0,1,8.545,2.921l12.795,22.113a6.927,6.927,0,0,1-1.554,8.853l-11.946,9.371c.15,1.547.24,3.086.24,4.633,0,1.524-.09,3.049-.233,4.543L129.749,87.4a6.976,6.976,0,0,1,1.629,8.988L118.643,118.4a7,7,0,0,1-8.485,3l-14-5.631a52.856,52.856,0,0,1-7.877,4.6l-2.08,14.822A7.157,7.157,0,0,1,79.291,141.155ZM54.2,133.646H79.291l1.847-16.376a3.763,3.763,0,0,1,2.238-2.928,44.747,44.747,0,0,0,9.926-5.8,3.738,3.738,0,0,1,3.709-.518l15.385,6.187,12.292-21.242L111.623,82.723a3.757,3.757,0,0,1-1.4-3.454,43.038,43.038,0,0,0,.42-5.7,44.506,44.506,0,0,0-.428-5.812,3.771,3.771,0,0,1,1.412-3.439l13.065-10.242L112.4,32.836l-15.385,6.2A3.727,3.727,0,0,1,93.3,38.52a42.775,42.775,0,0,0-9.9-5.789,3.756,3.756,0,0,1-2.268-2.936L78.848,13.509H53.754l-1.84,16.376a3.767,3.767,0,0,1-2.238,2.921,44.747,44.747,0,0,0-9.926,5.8,3.761,3.761,0,0,1-3.709.518L20.656,32.941,8.364,54.183,21.429,64.425a3.757,3.757,0,0,1,1.4,3.454,43.037,43.037,0,0,0-.42,5.7,44.507,44.507,0,0,0,.428,5.812,3.771,3.771,0,0,1-1.412,3.439L8.364,93.07l12.292,21.242,15.385-6.195a3.753,3.753,0,0,1,3.709.518,42.776,42.776,0,0,0,9.9,5.789,3.756,3.756,0,0,1,2.268,2.936ZM66.526,96.1A22.526,22.526,0,1,1,89.052,73.578,22.553,22.553,0,0,1,66.526,96.1Zm0-37.543A15.017,15.017,0,1,0,81.543,73.578,15.032,15.032,0,0,0,66.526,58.56Z" transform="translate(-0.673 -6)" fill={color} />
                        </g>
                        <g id="Group_335" data-name="Group 335" transform="translate(97.208)">
                            <path id="Path_7852" data-name="Path 7852" d="M57.014,75.086H43.5a5.447,5.447,0,0,1-5.391-4.55l-1-6.953a26.02,26.02,0,0,1-2.831-1.644l-6.472,2.605a5.541,5.541,0,0,1-6.8-2.425L14.3,50.51a5.53,5.53,0,0,1,1.164-6.855l5.737-4.5c-.023-.533-.038-1.066-.038-1.614s.015-1.081.038-1.607l-5.564-4.355a5.4,5.4,0,0,1-1.382-6.915l6.8-11.766a5.539,5.539,0,0,1,6.63-2.388l6.608,2.658a30.467,30.467,0,0,1,2.808-1.607l.991-6.915A5.55,5.55,0,0,1,43.5,0H57.014a5.368,5.368,0,0,1,5.361,4.866l.961,6.638a26.02,26.02,0,0,1,2.831,1.644l6.472-2.605a5.54,5.54,0,0,1,6.8,2.425l6.713,11.608a5.519,5.519,0,0,1-1.156,6.84l-5.684,4.46c.03.556.045,1.1.045,1.667s-.023,1.1-.068,1.629L84.863,43.5c.06.053.128.105.188.158a5.5,5.5,0,0,1,1.216,6.765L79.465,62.2a5.573,5.573,0,0,1-6.638,2.388l-6.638-2.658a27.043,27.043,0,0,1-2.771,1.592l-.991,6.923A5.556,5.556,0,0,1,57.014,75.086ZM45.263,67.578h9.979l1.021-7.118a3.753,3.753,0,0,1,2.223-2.906,21.245,21.245,0,0,0,4.828-2.8,3.765,3.765,0,0,1,3.739-.556l6.788,2.711L78.8,48.3l-5.789-4.49A3.756,3.756,0,0,1,71.6,40.381a21.678,21.678,0,0,0,.248-2.838,13.673,13.673,0,0,0-.21-2.621,3.744,3.744,0,0,1,1.382-3.642l5.737-4.49-4.978-8.6L67.068,20.9a3.737,3.737,0,0,1-3.694-.5,19.113,19.113,0,0,0-4.813-2.786A3.742,3.742,0,0,1,56.2,14.642L55.174,7.509H45.263l-1.021,7.118a3.753,3.753,0,0,1-2.223,2.906,23.522,23.522,0,0,0-4.956,2.846,3.763,3.763,0,0,1-3.694.5l-6.713-2.7-4.971,8.6,5.737,4.49A3.737,3.737,0,0,1,28.834,34.7a23.471,23.471,0,0,0-.165,2.846,22.03,22.03,0,0,0,.173,2.846,3.751,3.751,0,0,1-1.412,3.424L21.693,48.3l4.978,8.6L33.377,54.2a3.761,3.761,0,0,1,3.694.5,19.113,19.113,0,0,0,4.813,2.786,3.765,3.765,0,0,1,2.365,2.966ZM50.256,52.56A15.017,15.017,0,1,1,65.273,37.543,15.032,15.032,0,0,1,50.256,52.56Zm0-22.526a7.509,7.509,0,1,0,7.509,7.509A7.521,7.521,0,0,0,50.256,30.034Z" transform="translate(-13.555)" fill={color} />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    );
};
