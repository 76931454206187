import React, { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import "./SessionExpireAlert.scss";
import Alert from '../SVG/Alert';
import KaleidoscopeAPI from '../../Core/KaleidoscopeAPI';
import cookies from 'next-cookies';
import { handleSetBeforeLogoutRoute } from '../../Utility/HelperFunctions';

export default function SessionExpireAlert({ handleLogout, staySignIn }) {

    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(0);
    const API = new KaleidoscopeAPI({});
    let adminToken = cookies('context').admintoken
    let userToken = cookies('context').token

    const handleStaySignIn = async ()=>{
        const result = await API.fetchProfile({ token: adminToken? adminToken :userToken });
			if(result.user){
                staySignIn()
            }else{
                handleSetBeforeLogoutRoute()
                handleLogout()
            }
    }

    useEffect(() => {
        let timer = setInterval(() => {
            if (seconds > 0) {
                setSeconds((prevSeconds) => prevSeconds - 1);
            } else {
                if (minutes === 0) {
                    clearInterval(timer);
                } else {
                    setMinutes((prevMinutes) => prevMinutes - 1);
                    setSeconds(59);
                }
            }
            if (minutes === 0 && seconds === 0) {
                handleSetBeforeLogoutRoute()
                handleLogout()
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [minutes, seconds]);

    return (<div className='save-changes-confirmation-alert modal-utility'>
        <SweetAlert
            showCancel
            confirmBtnText="Stay signed in"
            confirmBtnBsStyle="primary"
            cancelBtnText={"Sign out"}
            // onConfirm={staySignIn}
            btnSize='xs'
            // onCancel={handleLogout}
            customButtons={
                <div className='action-footer'>
                    <button onClick={(e) => handleLogout()} className='cta-button CTABlack cta-button__text'>Sign out</button>
                    <button onClick={(e) => {
                        handleStaySignIn()
                    }}
                        className='cta-button CTAWhite tertiary cta-button__text'>Stay signed in</button>
                </div>
            }
        >
                <div style={{display:"flex"}}>
                    <Alert />
                <div className='content'>
                    <h2 className='title'>Are you still there?</h2>
                    <p className='CTAgrey'>Your session is about to expire, you will be automatically signed out in : <b className='session-timer'>{`${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`}</b> </p>
                </div>
                </div>
        </SweetAlert>

    </div>)
}
