import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "27", height = "18.273", iconColor = MAIN_CONFIG.COLORS.fillBlack, ariaLabel = "Video", ariaLabelDescription = "Video" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 27 18.273">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <g id="Icon_feather-video" data-name="Icon feather-video" transform="translate(0 -6)">
                <path id="Path_1" data-name="Path 1" d="M31.734,10.5,24,15.927l7.734,5.427Z" transform="translate(-6.234 -0.79)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                <path id="Path_2" data-name="Path 2" d="M3.669,7.5H15.6a2.175,2.175,0,0,1,2.169,2.182V20.591A2.175,2.175,0,0,1,15.6,22.773H3.669A2.175,2.175,0,0,1,1.5,20.591V9.682A2.175,2.175,0,0,1,3.669,7.5Z" transform="translate(0 0)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
            </g>
        </svg>
    );
};
