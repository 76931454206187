import React from 'react';
import './score-cell-type.scss';
import NumberHighlight from '../../../NumberHighlight/NumberHighlight';

export default ({ value }) => {
    const displayValue = value ? value : 0;

    return (
        <span className='score-cell-type'>
            <NumberHighlight number={displayValue} />
        </span>
    );
};
