import React from 'react';
import './form.scss'
import { MAIN_CONFIG } from '../../../config/main';
export const MultiLevelSelect = (props) => {

 const  getMenuItemTitle = (menuItem, index, depthLevel) => {
    return menuItem.title;
  };

  const getMenuItem = (menuItem, depthLevel, index) => {
    let title = getMenuItemTitle(menuItem, index, depthLevel);

    if (menuItem.submenu && menuItem.submenu.length > 0) {
      let { fieldObj, controlFunc } = props;
      return (
        <li style={{color: fieldObj?.answer ==='' ? `${MAIN_CONFIG.COLORS.fillDarkGrey}` : `#333333`}}>
          {title}
          <MultiLevelSelect config={menuItem.submenu} submenu={true} controlFunc={controlFunc} fieldObj={fieldObj}/>
        </li>
      );
    } else {
      let { fieldObj, controlFunc } = props;
      return <li onClick={controlFunc.bind(this, fieldObj, title)}>{title}</li>;
    }
  };


    let { config, fieldObj, noDesc } = props;

    let options = [];
    config.map((item, index) => {
      options.push(getMenuItem(item, 0, index));
      return null
    });

    const createMarkup = (string) => {
      return {__html: string};
    }

    if (props.submenu && props.submenu === true)
      return <ul>{options}</ul>;

    return(
      <div className="form-group select-field config_input" id="hover-picklist">
        <ul className="dropdown-menu multi-level-select">{options}</ul>
        {
          (config[0] && config[0].submenu && config[0].submenu.length > 4) ? (
            <style>{"\
              .step-input .config_input .multi-level-select li ul li:nth-last-child(1):hover ul{\
                  bottom: 0;\
                  margin-top: -200px !important;\
                  overflow-y: scroll !important;\
                  width: 50% !important;\
                  right: 0;\
                  overflow-x: auto !important;\
              }\
              .step-input .config_input .multi-level-select li ul li:nth-last-child(2):hover ul {\
                  bottom: 0;\
                  margin-top: -130px !important;\
                  overflow-y: scroll !important;\
                  width: 50% !important;\
                  right: 0;\
                  overflow-x: auto !important;\
              }\
              .step-input .config_input .multi-level-select li ul li:nth-last-child(3):hover ul {\
                  bottom: 0;\
                  margin-top: -130px !important;\
                  overflow-y: scroll !important;\
                  width: 50% !important;\
                  right: 0;\
                  overflow-x: auto !important;\
              }\
              .step-input .config_input .multi-level-select li ul li:nth-last-child(4):hover ul {\
                  overflow-y: scroll !important;\
                  width: 50% !important;\
                  right: 0;\
                  overflow-x: auto !important;\
                  bottom: 0;\
                  margin-top: -130px !important;\
              }\
            "}</style>
          ) : null
        }
      </div>
    );
}

