import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "20", height = "20", iconColor = MAIN_CONFIG.COLORS.fillWhite, title, ariaLabel = "Checkbox", ariaLabelDescription = "Checkbox" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
            <title> {title ? title : "Checkbox"}</title>
            <desc>{ariaLabelDescription}</desc>
            <defs>
                <clipPath id={`clip-check-${id}`}>
                    <rect width="20" height="20" />
                </clipPath>
            </defs>
            <g id={`check-${id}`}>
                <path id={`Icon_material-check-${id}`} data-name="Icon material-check" d="M6,10.78,3.22,8l-.947.94L6,12.667l8-8-.94-.94Z" transform="translate(1.727 2.273)" fill={iconColor} />
            </g>
        </svg>
    );
}


