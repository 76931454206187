import React from 'react';
import FieldCheckbox from '../../../SVG/FieldCheckbox';
import '../StatusCheckBox/StatusCheckBox.scss';
import { uuid4 } from '@sentry/utils';



const StatusCheckBox = ({ value }) => {
    return (
        <div className="checkbox-incomplete verified-notVerified">
            <label className="option-checkbox">
                <span className="checkbox__input">
                    <input
                        className="table__checkbox"
                        id={uuid4()}
                        type="checkbox"
                        checked={value}
                        disabled
                    />
                    <span className="checkbox__control">
                        <FieldCheckbox title= {value ? "Verified" : "Unverified"} id={uuid4()} />
                    </span>
                </span>
            </label>
            <div className="LabelGrey">{value ? "Verified" : "Unverified"}</div>
        </div>

    )
}

export default StatusCheckBox
