import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

const styles = {
    icon: {
        backgroundColor: MAIN_CONFIG.COLORS.fillLightGrey,
        padding: '15px 9px',
        borderRadius: '4px',
    },
    line: {
        display: 'block',
        width: '22px',
        height: '2px',
        backgroundColor: MAIN_CONFIG.COLORS.fillBlack,
    }
};

export default () => {
    return (
        <div className="hamburger-icon" style={styles.icon}>
            <span className="hamburger-icon__line" style={styles.line} />
            <span className="hamburger-icon__spacer" style={{ display: 'block', height: '9px' }} />
            <span className="hamburger-icon__line" style={styles.line} />
        </div>
    );
};
