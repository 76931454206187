import React, { useState, useEffect, useRef } from 'react';
import BrandMark from '../../../Components/SVG/BrandMark';
import AccountMenu from '../../../Components/AccountMenu/AccountMenu';
import { MAIN_CONFIG } from '../../../../config/main';
import './staff-header.scss';
import ArrowDown from '../../../Components/SVG/ArrowDown';
import ChatList from '../../../Components/ChatList/ChatList';
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import { setConversationsData } from '../../../hooks/ConversationAction';
import Chat from '../../../Components/SVG/Chat';
import { useDispatch, useSelector } from 'react-redux';
import { reduxPage } from '../../../Core/store';
import '../DonorHeader/donor-header.scss';

const { USER_CONTEXT, PAGE_TYPES } = MAIN_CONFIG;

const RecommenderHeader = ({ data, currentUser, applicant_recommendations = {}, currentPath, herokuRole, setRoleSwitcherVisible, notifications, activeHeaderNav, userContext, pageType, setModalState = () => { } }) => {
	const { currentAccount, user, token } = data;
	const [showChatBox, setShowChatBox] = useState(false);
	const dispatch = useDispatch();
	const userConversations = useSelector(state => state.conversations)
	const unreadMessages = conversations && conversations.filter(conversation => conversation.messages.filter(message => !message.read_at).length > 0).length > 0
	const [conversations, setConversations] = useState(userConversations);
	const messageTrigger = useRef()

	const API = new KaleidoscopeAPI({});
	useEffect(() => {
		setConversations(userConversations)
	}, [userConversations])

	useEffect(() => {
		(async () => {
			// await fetchConversations()
		})();
	}, []);

	const fetchConversations = async () => {
		const response = await API.fetchConversations({ 'token': token })
		if (response) {
			setConversations(response)
			dispatch(setConversationsData(response))
		}
	}

	return (
		<div id='recommender-header__inner'>
			<div id='recommender-header__left'>
				<a href="/">
					<BrandMark id='recommender-header__brand-mark' />
				</a>
				<nav>
					<ul className='header__menu'>
						<li className="CTAGrey CTAWhite">
							<a href="/">Dashboard</a>
						</li>
					</ul>
				</nav>
			</div>

			{
				pageType === MAIN_CONFIG.PAGE_TYPES.RECOMMENDER.RECOMMENDATION ? (
					<>
						<p className='header__sholarship-name'>
							{applicant_recommendations.scholarship_name__c}
						</p>
						<div id='recommender-header__right'>
							<a href="/"><span className='recommender-header__close'>close</span></a>
						</div>
					</>
				) : (
					<>
						<AccountMenu
							setRoleSwitcherVisible={setRoleSwitcherVisible}
							userContext={userContext}
							setShowChatBox={setShowChatBox}
							user={user}
							herokuRole={herokuRole}
						/>
					</>
				)
			}
		</div>
	);
}
export default reduxPage(RecommenderHeader)
// export default (RecommenderHeader)

