import React, {useEffect} from 'react';
import { useRouter } from 'next/router';
import './account-switcher.scss';
import BrandMark from '../SVG/BrandMark';
import { MAIN_CONFIG } from '../../../config/main';
import UserBadge from '../UserBadge/UserBadge';
import DrawnArrow from '../SVG/DrawnArrow';
import { checkMarketPlace } from '../../Utility/HelperFunctions';
import Loader from '../Loader/Loader';
import { handleKeyDown } from '../../Utility/ApplicationFormUtility';


export default ({ accountData, setAccountLoaderVisible= () => { }, hideGoBack = false ,redirectToDashBoard = false }) => {
    const LOADING_SCREEN_SECONDS = 2;
    const { user } = accountData;
    const [loading, setLoading] = React.useState(false);
    const [redirectRoute, setRedirectRoute] = React.useState('');

    const router = useRouter();

    const accounts = accountData.availableAccounts.map(account => {
        return {
            firstName: user.first_name,
            lastName: user.last_name,
            company: account.name,
            companyLogo: account.account_logo_url__c,
            primaryColor: account.primary_color__c,
            secondaryColor: account.secondary_color__c,
            profileImage: user.avatar,
            sfid: account.sfid
        }
    });
    useEffect(() => {
        setRedirectRoute(redirectToDashBoard)
    }, [])
    const currentEmail = user.email;
    const currentAccountName = accountData.currentAccount.name;

    /**
     * Triggers an account switch by showing the loader
     * then changing the account context.
     * @param {object} account
     */
    const handleAccountSwitch = (account) => {
        setLoading(true)
        setAccountLoaderVisible(true, account);
        document.cookie = `currentAccount=${account.sfid};path=/`;

        setTimeout(() => {
            window.location.href = checkMarketPlace();
        }, LOADING_SCREEN_SECONDS * 1000);
    };

    /**
     * Renders the available accounts.
     * @param {object[]} accounts
     * @returns {*}
     */
    const checkPreviousPath = () => {
        if (redirectRoute) {
            router.push('/')
        }
        else {
            router.back()
        }
    }
    const renderAccounts = (accounts) => {
        const availableAccounts = accounts.filter((item) => item.company !== currentAccountName);
        // Use Set to store unique sfids
        const uniqueSfidsSet = new Set(availableAccounts.map((account) => account.sfid));
        // Convert uniqueSfidsSet to an array to render unique sfids
        const uniqueSfids = Array.from(uniqueSfidsSet);

        return uniqueSfids.map((sfid, index) => {
            const uniqueAccount = availableAccounts.find((account) => account.sfid === sfid);
            return (
                <li key={`account-switcher-${index}`} tabIndex={0} role='button' onKeyDown={(e) => handleKeyDown(e, () => handleAccountSwitch(uniqueAccount))}>
                    <a href={'#'} onClick={() => handleAccountSwitch(uniqueAccount)} tabIndex={-1}>
                        <div className='account-switcher__account'>
                        <div className='account-switcher__left'>
                            <UserBadge
                                className='account-switcher__badge'
                                firstName={uniqueAccount.firstName}
                                lastName={uniqueAccount.lastName}
                                profileImage={uniqueAccount.profileImage}
                                color={uniqueAccount.secondaryColor}
                                backgroundColor={uniqueAccount.primaryColor}
                            />
                            <div className='CTABlack account-switcher__company'>{uniqueAccount.company}</div>
                        </div>

                            <DrawnArrow tabIndex={-1} className='account-switcher__arrow' color={MAIN_CONFIG.COLORS.fillBlue} ariaLabel="Right Arrow" ariaLabelDescription={`Click here to select ${uniqueAccount.company} account`} />
                        </div>
                    </a>
                </li>
            );
        });
    };

    return (
        <div id='account-switcher'>
            <Loader loading={loading} />
            <div id='account-switcher__logo'>
                <a href="/"><BrandMark color={MAIN_CONFIG.COLORS.fillBlue} ariaLabel="Kaleidoscope Logo" ariaLabelDescription="" /></a>
            </div>
            <div id='account-switcher__inner'>
                <div id='account-switcher__title' className='H1DesktopGreen'>
                    Select Account
                </div>
                <div id='account-switcher__copy' className='BodySmallRegularBlack'>
                You're currently signed into <span className='CTAGreen'>{currentAccountName}. </span><span className='BodySmallRegularBlack'>Select another account below, </span> 
                    {hideGoBack &&
                        <>
                            or <a href={'#'} onClick={() => {setLoading(true);(checkPreviousPath(), undefined, { shallow: true })}}><span className='CTAGreen'><u>GO BACK.</u></span> </a>
                        </>
                    }
                    {/* <a href={'#'} onClick={() => setAccountSwitcherVisible(false)}><span className='CTAGreen'>GO BACK</span> </a> */}
                </div>

                <ul id='account-switcher__accounts'>
                    {renderAccounts(accounts)}
                </ul>
            </div>
        </div>
    );
};
