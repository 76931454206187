import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "200", height = "200" }) => {
    return (

  



<svg  id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 700 700">
 <defs>
  <symbol id="v" overflow="visible">
   <path d="m26.047-1.5625c-1.3359 0.69922-2.7305 1.2266-4.1875 1.5781-1.4609 0.35156-2.9805 0.53125-4.5625 0.53125-4.7188 0-8.4609-1.3164-11.219-3.9531-2.7617-2.6445-4.1406-6.2227-4.1406-10.734 0-4.5391 1.3789-8.1289 4.1406-10.766 2.7578-2.6328 6.5-3.9531 11.219-3.9531 1.582 0 3.1016 0.17969 4.5625 0.53125 1.457 0.35547 2.8516 0.88281 4.1875 1.5781v5.8594c-1.3555-0.92578-2.6875-1.6016-4-2.0312-1.3125-0.42578-2.6992-0.64062-4.1562-0.64062-2.6172 0-4.668 0.83984-6.1562 2.5156-1.4922 1.668-2.2344 3.9688-2.2344 6.9062 0 2.918 0.74219 5.2148 2.2344 6.8906 1.4883 1.668 3.5391 2.5 6.1562 2.5 1.457 0 2.8438-0.21094 4.1562-0.64062 1.3125-0.42578 2.6445-1.1016 4-2.0312z" fill={color} />
  </symbol>
  <symbol id="b" overflow="visible">
   <path d="m19.062-15.469c-0.59375-0.28125-1.1875-0.48828-1.7812-0.625-0.58594-0.13281-1.1719-0.20312-1.7656-0.20312-1.75 0-3.1016 0.5625-4.0469 1.6875-0.9375 1.1172-1.4062 2.7188-1.4062 4.8125v9.7969h-6.7969v-21.266h6.7969v3.4844c0.875-1.3828 1.8789-2.3945 3.0156-3.0312 1.1328-0.64453 2.4883-0.96875 4.0625-0.96875 0.22656 0 0.47266 0.011719 0.73438 0.03125 0.26953 0.023438 0.66016 0.0625 1.1719 0.125z" fill={color} />
  </symbol>
  <symbol id="a" overflow="visible">
   <path d="m24.5-10.688v1.9375h-15.891c0.15625 1.5938 0.72656 2.793 1.7188 3.5938 0.98828 0.79297 2.3672 1.1875 4.1406 1.1875 1.4258 0 2.8906-0.20703 4.3906-0.625 1.5-0.42578 3.0391-1.0703 4.625-1.9375v5.2344c-1.6055 0.61719-3.2148 1.0742-4.8281 1.375-1.6055 0.3125-3.2109 0.46875-4.8125 0.46875-3.8438 0-6.8359-0.97266-8.9688-2.9219-2.1367-1.957-3.2031-4.7031-3.2031-8.2344 0-3.4688 1.0469-6.1953 3.1406-8.1875 2.0938-1.9883 4.9766-2.9844 8.6562-2.9844 3.3438 0 6.0156 1.0078 8.0156 3.0156 2.0078 2.0117 3.0156 4.7031 3.0156 8.0781zm-6.9844-2.2656c0-1.2891-0.38281-2.332-1.1406-3.125-0.75-0.78906-1.7344-1.1875-2.9531-1.1875-1.3125 0-2.3828 0.37109-3.2031 1.1094-0.82422 0.74219-1.3398 1.8086-1.5469 3.2031z" fill={color} />
  </symbol>
  <symbol id="f" overflow="visible">
   <path d="m12.797-9.5781c-1.418 0-2.4844 0.24609-3.2031 0.73438-0.71094 0.48047-1.0625 1.1875-1.0625 2.125 0 0.85547 0.28516 1.5273 0.85938 2.0156 0.57031 0.49219 1.375 0.73438 2.4062 0.73438 1.2695 0 2.3438-0.45703 3.2188-1.375 0.875-0.91406 1.3125-2.0664 1.3125-3.4531v-0.78125zm10.391-2.5625v12.141h-6.8594v-3.1562c-0.90625 1.293-1.9336 2.2344-3.0781 2.8281-1.1367 0.58203-2.5234 0.875-4.1562 0.875-2.1992 0-3.9844-0.64062-5.3594-1.9219s-2.0625-2.9453-2.0625-5c0-2.5 0.85156-4.3281 2.5625-5.4844 1.7188-1.1641 4.4141-1.75 8.0938-1.75h4v-0.53125c0-1.082-0.42969-1.875-1.2812-2.375-0.84375-0.5-2.1641-0.75-3.9531-0.75-1.4609 0-2.8203 0.14844-4.0781 0.4375-1.25 0.29297-2.4141 0.73047-3.4844 1.3125v-5.1875c1.457-0.35156 2.9219-0.61719 4.3906-0.79688 1.4688-0.1875 2.9375-0.28125 4.4062-0.28125 3.832 0 6.5977 0.75781 8.2969 2.2656 1.707 1.5117 2.5625 3.9688 2.5625 7.375z" fill={color} />
  </symbol>
  <symbol id="d" overflow="visible">
   <path d="m10.688-27.312v6.0469h7.0156v4.8594h-7.0156v9.0156c0 0.99219 0.19531 1.6641 0.59375 2.0156 0.39453 0.34375 1.1719 0.51562 2.3281 0.51562h3.5v4.8594h-5.8281c-2.6875 0-4.5938-0.55469-5.7188-1.6719-1.1172-1.125-1.6719-3.0312-1.6719-5.7188v-9.0156h-3.375v-4.8594h3.375v-6.0469z" fill={color} />
  </symbol>
  <symbol id="k" overflow="visible">
   <path d="m17.734-18.156v-11.391h6.8438v29.547h-6.8438v-3.0781c-0.9375 1.25-1.9688 2.168-3.0938 2.75s-2.4297 0.875-3.9062 0.875c-2.625 0-4.7812-1.0391-6.4688-3.125-1.6797-2.082-2.5156-4.7578-2.5156-8.0312 0-3.2812 0.83594-5.9609 2.5156-8.0469 1.6875-2.082 3.8438-3.125 6.4688-3.125 1.4688 0 2.7656 0.29688 3.8906 0.89062 1.1328 0.58594 2.1719 1.4961 3.1094 2.7344zm-4.4844 13.766c1.457 0 2.5664-0.53125 3.3281-1.5938 0.76953-1.0625 1.1562-2.6016 1.1562-4.625 0-2.0312-0.38672-3.5781-1.1562-4.6406-0.76172-1.0625-1.8711-1.5938-3.3281-1.5938-1.4375 0-2.543 0.53125-3.3125 1.5938-0.76172 1.0625-1.1406 2.6094-1.1406 4.6406 0 2.0234 0.37891 3.5625 1.1406 4.625 0.76953 1.0625 1.875 1.5938 3.3125 1.5938z" fill={color} />
  </symbol>
  <symbol id="e" overflow="visible">
   <path d="m14.578-4.3906c1.457 0 2.5664-0.53125 3.3281-1.5938 0.76953-1.0625 1.1562-2.6016 1.1562-4.625 0-2.0312-0.38672-3.5781-1.1562-4.6406-0.76172-1.0625-1.8711-1.5938-3.3281-1.5938-1.4492 0-2.5625 0.53906-3.3438 1.6094-0.78125 1.0625-1.1719 2.6055-1.1719 4.625 0 2.0117 0.39062 3.5547 1.1719 4.625 0.78125 1.0625 1.8945 1.5938 3.3438 1.5938zm-4.5156-13.766c0.9375-1.2383 1.9727-2.1484 3.1094-2.7344 1.1445-0.59375 2.457-0.89062 3.9375-0.89062 2.625 0 4.7734 1.043 6.4531 3.125 1.6875 2.0859 2.5312 4.7656 2.5312 8.0469 0 3.2734-0.84375 5.9492-2.5312 8.0312-1.6797 2.0859-3.8281 3.125-6.4531 3.125-1.4805 0-2.793-0.29297-3.9375-0.875-1.1367-0.59375-2.1719-1.5078-3.1094-2.75v3.0781h-6.7969v-29.547h6.7969z" fill={color} />
  </symbol>
  <symbol id="j" overflow="visible">
   <path d="m0.46875-21.266h6.7969l5.7188 14.422 4.8594-14.422h6.8125l-8.9531 23.281c-0.89844 2.3633-1.9453 4.0156-3.1406 4.9531-1.1992 0.94531-2.7773 1.4219-4.7344 1.4219h-3.9375v-4.4531h2.125c1.1562 0 1.9922-0.1875 2.5156-0.5625 0.53125-0.36719 0.94141-1.0234 1.2344-1.9688l0.1875-0.59375z" fill={color} />
  </symbol>
  <symbol id="i" overflow="visible">
   <path d="m3.5625-28.344h19.734v5.5156h-12.422v5.2812h11.688v5.5312h-11.688v12.016h-7.3125z" fill={color} />
  </symbol>
  <symbol id="u" overflow="visible">
   <path d="m3.2656-29.547h6.7969v29.547h-6.7969z" fill={color} />
  </symbol>
  <symbol id="c" overflow="visible">
   <path d="m13.391-16.922c-1.5117 0-2.6641 0.54297-3.4531 1.625-0.79297 1.0859-1.1875 2.6484-1.1875 4.6875 0 2.0312 0.39453 3.5898 1.1875 4.6719 0.78906 1.0859 1.9414 1.625 3.4531 1.625 1.4766 0 2.6094-0.53906 3.3906-1.625 0.78906-1.082 1.1875-2.6406 1.1875-4.6719 0-2.0391-0.39844-3.6016-1.1875-4.6875-0.78125-1.082-1.9141-1.625-3.3906-1.625zm0-4.8594c3.6562 0 6.5078 0.99219 8.5625 2.9688 2.0625 1.9688 3.0938 4.7031 3.0938 8.2031 0 3.4922-1.0312 6.2266-3.0938 8.2031-2.0547 1.9688-4.9062 2.9531-8.5625 2.9531-3.6797 0-6.5547-0.98438-8.625-2.9531-2.0625-1.9766-3.0938-4.7109-3.0938-8.2031 0-3.5 1.0312-6.2344 3.0938-8.2031 2.0703-1.9766 4.9453-2.9688 8.625-2.9688z" fill={color} />
  </symbol>
  <symbol id="t" overflow="visible">
   <path d="m3.2656-21.266h6.7969v21.266h-6.7969zm0-8.2812h6.7969v5.5469h-6.7969z" fill={color} />
  </symbol>
  <symbol id="h" overflow="visible">
   <path d="m24.656-12.953v12.953h-6.8438v-9.9062c0-1.8438-0.042969-3.1094-0.125-3.7969-0.085938-0.69531-0.22656-1.2109-0.42188-1.5469-0.27344-0.4375-0.63672-0.78125-1.0938-1.0312-0.44922-0.25-0.96484-0.375-1.5469-0.375-1.418 0-2.5312 0.54688-3.3438 1.6406s-1.2188 2.6094-1.2188 4.5469v10.469h-6.7969v-21.266h6.7969v3.1094c1.0312-1.2383 2.1172-2.1484 3.2656-2.7344 1.1562-0.59375 2.4258-0.89062 3.8125-0.89062 2.457 0 4.3203 0.75781 5.5938 2.2656 1.2812 1.5 1.9219 3.6875 1.9219 6.5625z" fill={color} />
  </symbol>
  <symbol id="s" overflow="visible">
   <path d="m3.5625-28.344h7.3125v10.797h10.797v-10.797h7.3125v28.344h-7.3125v-12.016h-10.797v12.016h-7.3125z"/>
  </symbol>
  <symbol id="g" overflow="visible">
   <path d="m3.0312-8.2812v-12.984h6.8438v2.125c0 1.1562-0.007812 2.6055-0.015625 4.3438-0.011719 1.7422-0.015625 2.9023-0.015625 3.4844 0 1.7109 0.039062 2.9375 0.125 3.6875 0.09375 0.75 0.24219 1.2969 0.45312 1.6406 0.28125 0.44922 0.64453 0.79297 1.0938 1.0312 0.44531 0.24219 0.96094 0.35938 1.5469 0.35938 1.4141 0 2.5312-0.53906 3.3438-1.625 0.8125-1.0938 1.2188-2.6094 1.2188-4.5469v-10.5h6.7969v21.266h-6.7969v-3.0781c-1.0312 1.2422-2.1211 2.1562-3.2656 2.75-1.1484 0.58203-2.4062 0.875-3.7812 0.875-2.4609 0-4.3359-0.75-5.625-2.25-1.2812-1.5078-1.9219-3.7031-1.9219-6.5781z" fill={color} />
  </symbol>
  <symbol id="r" overflow="visible">
   <path d="m17.266-29.547v4.4688h-3.7656c-0.96094 0-1.6328 0.17188-2.0156 0.51562-0.375 0.34375-0.5625 0.94922-0.5625 1.8125v1.4844h5.8125v4.8594h-5.8125v16.406h-6.7969v-16.406h-3.3906v-4.8594h3.3906v-1.4844c0-2.3125 0.64453-4.0195 1.9375-5.125 1.2891-1.1133 3.2891-1.6719 6-1.6719z" fill={color} />
  </symbol>
  <symbol id="q" overflow="visible">
   <path d="m22.984-17.734c0.85156-1.3203 1.8672-2.3281 3.0469-3.0156 1.1875-0.6875 2.4922-1.0312 3.9219-1.0312 2.4375 0 4.2969 0.75781 5.5781 2.2656 1.2812 1.5 1.9219 3.6875 1.9219 6.5625v12.953h-6.8438v-11.094c0.007813-0.16406 0.019531-0.33594 0.03125-0.51562 0.007813-0.17578 0.015625-0.42578 0.015625-0.75 0-1.5078-0.22656-2.6016-0.67188-3.2812-0.44922-0.67578-1.1641-1.0156-2.1406-1.0156-1.293 0-2.293 0.53125-3 1.5938-0.69922 1.0625-1.0625 2.6055-1.0938 4.625v10.438h-6.8281v-11.094c0-2.3516-0.20312-3.8672-0.60938-4.5469-0.40625-0.67578-1.1328-1.0156-2.1719-1.0156-1.3047 0-2.3086 0.53906-3.0156 1.6094-0.71094 1.0742-1.0625 2.6016-1.0625 4.5781v10.469h-6.8281v-21.266h6.8281v3.1094c0.83203-1.1953 1.7891-2.0977 2.875-2.7031 1.082-0.61328 2.2734-0.92188 3.5781-0.92188 1.4688 0 2.7656 0.35547 3.8906 1.0625 1.1328 0.71094 1.9922 1.7031 2.5781 2.9844z" fill={color} />
  </symbol>
  <symbol id="p" overflow="visible">
   <path d="m24.656-12.953v12.953h-6.8438v-9.875c0-1.8633-0.042969-3.1406-0.125-3.8281-0.085938-0.69531-0.22656-1.2109-0.42188-1.5469-0.27344-0.4375-0.63672-0.78125-1.0938-1.0312-0.44922-0.25-0.96484-0.375-1.5469-0.375-1.418 0-2.5312 0.54688-3.3438 1.6406s-1.2188 2.6094-1.2188 4.5469v10.469h-6.7969v-29.547h6.7969v11.391c1.0312-1.2383 2.1172-2.1484 3.2656-2.7344 1.1562-0.59375 2.4258-0.89062 3.8125-0.89062 2.457 0 4.3203 0.75781 5.5938 2.2656 1.2812 1.5 1.9219 3.6875 1.9219 6.5625z" fill={color} />
  </symbol>
  <symbol id="o" overflow="visible">
   <path d="m3.5625-28.344h8.1719l10.312 19.438v-19.438h6.9375v28.344h-8.1719l-10.312-19.453v19.453h-6.9375z" fill={color} />
  </symbol>
  <symbol id="n" overflow="visible">
   <path d="m3.5625-28.344h12.141c3.6016 0 6.3672 0.80469 8.2969 2.4062 1.9375 1.5938 2.9062 3.8711 2.9062 6.8281 0 2.9805-0.96875 5.2734-2.9062 6.875-1.9297 1.5938-4.6953 2.3906-8.2969 2.3906h-4.8281v9.8438h-7.3125zm7.3125 5.2969v7.9062h4.0469c1.4141 0 2.5078-0.34375 3.2812-1.0312 0.78125-0.6875 1.1719-1.6641 1.1719-2.9375 0-1.2578-0.39062-2.2266-1.1719-2.9062-0.77344-0.6875-1.8672-1.0312-3.2812-1.0312z" fill={color} />
  </symbol>
  <symbol id="m" overflow="visible">
   <path d="m3.2656-21.266h6.7969v20.891c0 2.8438-0.68359 5.0156-2.0469 6.5156-1.3672 1.5-3.3516 2.25-5.9531 2.25h-3.3594v-4.4531h1.1875c1.2891 0 2.1758-0.29297 2.6562-0.875 0.47656-0.58594 0.71875-1.7305 0.71875-3.4375zm0-8.2812h6.7969v5.5469h-6.7969z" fill={color} />
  </symbol>
  <symbol id="l" overflow="visible">
   <path d="m20.453-20.609v5.5469c-0.92969-0.63281-1.8555-1.1016-2.7812-1.4062-0.92969-0.30078-1.8906-0.45312-2.8906-0.45312-1.9062 0-3.3906 0.55859-4.4531 1.6719-1.0547 1.1055-1.5781 2.6523-1.5781 4.6406 0 1.9805 0.52344 3.5273 1.5781 4.6406 1.0625 1.1055 2.5469 1.6562 4.4531 1.6562 1.0625 0 2.0664-0.15625 3.0156-0.46875 0.95703-0.32031 1.8438-0.78906 2.6562-1.4062v5.5625c-1.0625 0.39844-2.1406 0.6875-3.2344 0.875-1.0938 0.19531-2.1953 0.29688-3.2969 0.29688-3.8359 0-6.8359-0.98438-9-2.9531-2.168-1.9688-3.25-4.7031-3.25-8.2031 0-3.5078 1.082-6.25 3.25-8.2188 2.1641-1.9688 5.1641-2.9531 9-2.9531 1.1133 0 2.2109 0.10156 3.2969 0.29688 1.082 0.19922 2.1602 0.49219 3.2344 0.875z" fill={color} />
  </symbol>
 </defs>
 <g>
  <path d="m240.54 283.24c32.809-8.2891 65.957 3.2578 86.844 27.062l162.77-41.121 46.688 23.168-42.645 57.477-28.848-17.211-17.211 28.848-28.848-17.211-17.211 28.848-55.301 13.969c-7.0742 30.867-30.762 56.77-63.57 65.059-46.637 11.781-93.996-16.473-105.78-63.109s16.473-93.996 63.109-105.78zm-11.355 68.199c-12.719 3.2148-20.426 16.129-17.211 28.848s16.129 20.426 28.848 17.211 20.426-16.129 17.211-28.848-16.129-20.426-28.848-17.211z" fill={color} />
  <path d="m405 95.004c90.977 0 165 74.027 165 165 0 0.69141-0.0625 1.375-0.125 2.0547-0.078125 1.0742-0.14062 2.1719-0.17188 3.2422l-0.58203 18.371 18.246 2.1406c52.812 6.1992 92.633 51.008 92.633 104.2 0 57.898-47.109 105-105 105-0.56641 0-1.1367-0.0625-1.7031-0.10156-0.81641-0.046875-1.6562-0.10938-2.4727-0.125l-20.828-0.59766v0.81641h-420.96l-6.8438-0.75391c-58.254-6.457-102.2-55.57-102.2-114.25 0-48.578 30.855-92.129 76.766-108.34l12.996-4.5898 0.34375-13.781c0.91797-38.289 31.625-68.289 69.891-68.289 16 0 31.07 5.3047 43.633 15.336l20.199 16.156 10.539-23.629c26.562-59.422 85.688-97.852 150.63-97.852m0-20.004c-75.336 0-140.02 45.102-168.89 109.71-15.391-12.312-34.883-19.715-56.117-19.715-48.961 0-88.707 39.121-89.895 87.805-52.438 18.543-90.098 68.406-90.098 127.21 0 69.48 52.508 126.65 120 134.12v0.87891h450v-0.24219c1.6641 0.054687 3.2969 0.24219 5 0.24219 69.043 0 125-55.969 125-125 0-64.043-48.199-116.78-110.3-124.05 0.070313-1.9922 0.30469-3.9414 0.30469-5.9492 0-102.16-82.832-185-185-185" fill={color} />

 </g>
</svg>















    );
};
