import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "24", height = "24", iconColor = MAIN_CONFIG.COLORS.fillBlack }) => {
    return (
        <svg id={id} className={className} data-name="Elements / System / Arrow / Down (fill)" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
            <title>Drop Down</title>
            <desc>Drop Down</desc>
            <path id="Dropdown" d="M0,0,7.029,6,14,0Z" transform="translate(5 9)" fill={color} />
        </svg>
    );
};
