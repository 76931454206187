import React from 'react'
import PropTypes from 'prop-types';

function CTAImageTag({data, title ='', className='', loading='lazy'}) {
    return (
        <img className={className} src={data} alt={title} loading={loading}/>
    )
}

CTAImageTag.propTypes = {
    data: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    loading: PropTypes.any
};

export default CTAImageTag;