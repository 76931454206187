import React from 'react';
import './number-highlight.scss';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ number, color = MAIN_CONFIG.COLORS.fillPink }) => {
    return (
        <span className='number-highlight H4DesktopBlack' style={{ backgroundColor: color }}>
            { number }
        </span>
    );
}
