import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Globe from '../../Components/SVG/Globe';
import AirPlane from '../../Components/SVG/AirPlane';
import Pin from '../../Components/SVG/Pin';
import PaperClip from '../../Components/SVG/PaperClip';
import Person from '../../Components/SVG/Person';
import Chat from '../../Components/SVG/Chat';
import Paper from '../../Components/SVG/Paper';
import Calendar from '../../Components/SVG/Calendar';
import Book from '../../Components/SVG/Book';
import Medal from '../../Components/SVG/Medal';
import TestTubes from '../../Components/SVG/TestTubes';
import Bolt from '../../Components/SVG/Bolt';
import { MAIN_CONFIG } from '../../../config/main';
import Field from '../../Components/Field/Field';
import './sidebar.scss';
import cookies from 'next-cookies'
import WordMark from '../../Components/SVG/WordMark';
import BrandMark from '../../Components/SVG/BrandMark';
import Logout from '../../Components/SVG/Logout';
import SwitchProfile from '../../Components/SVG/SwitchProfile';

// import ProgressCompleteCellType from  '../Table';
import ProgressCompleteCellType from '../Table/CellTypes/ProgressCompleteCellType/ProgressCompleteCellType';
import { checkMarketPlace } from '../../Utility/HelperFunctions';
import { useRouter } from 'next/router';
import { reduxPage } from '../../Core/store';
import SweetAlert from 'react-bootstrap-sweetalert';
import { isEmpty } from 'lodash';
import Alert from '../SVG/Alert';
import Loader from '../Loader/Loader';


const Sidebar = ({ activeTab, setActiveTab, currentUser, accountData, marketPlaceData = {}, setAccountSwitcherVisible, setAccountLoaderVisible, setRoleSwitcherVisible, roleData = [], userContext, setToggleHamburgerMenu, toggleHamburgerMenu, isMobile = false, pageType }) => {

	useEffect(() => {
		var tempActiveTab = localStorage.getItem("activeTab")
		if (tempActiveTab == "Switch Role") {
			setActiveTab(tempActiveTab)
		}
	}, [])
const [loading,setLoading]=useState(false)
	const router = useRouter();
	let route = router.query
	const { action } = router.query;

	const activeColor = (type) => {
		if(pageType === "HOME"){
			return type.toUpperCase() === 'EXPLORE' ? 'CTAGreen' : 'CTAGrey';
		}else if(pageType === "MYAPPLICATIONS"){
			return type.toUpperCase() === 'APPLICATIONS' ? 'CTAGreen' : 'CTAGrey';
		}else{
			return type.toUpperCase() === pageType ? 'CTAGreen' : 'CTAGrey';
		}
	}
	const iconColor = (type) => {

		if(pageType === "HOME"){
			return type.toUpperCase() === 'EXPLORE' ? MAIN_CONFIG.COLORS.fillWhite : MAIN_CONFIG.COLORS.fillBlack;
		}else if(pageType === "MYAPPLICATIONS"){
			return type.toUpperCase() === 'APPLICATIONS' ? MAIN_CONFIG.COLORS.fillWhite : MAIN_CONFIG.COLORS.fillBlack;
		}else{
			return type.toUpperCase() === pageType ?  MAIN_CONFIG.COLORS.fillWhite : MAIN_CONFIG.COLORS.fillBlack
		}
	}
	/**
	 * Delete token cookie to logout and redirect to login page.
	 */

	// if (!route.indexId) {
	// 	document.cookie = 'marketPlace=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
	// }

	const handleLogout = () => {
		var isRediractLocal = localStorage.getItem("isRedireactSave") ? JSON.parse(localStorage.getItem("isRedireactSave")) : false
		if (pageType == MAIN_CONFIG.PAGE_TYPES.APPLICANT.APPLY && isRediractLocal || pageType == MAIN_CONFIG.PAGE_TYPES.APPLICANT.RESUME && action === "submit" || route.applicationstepid) {
			alretMsg()
		} else {
			document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
			document.cookie = 'context=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
			document.cookie = 'user=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
			document.cookie = 'currentAccount=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
			document.cookie = 'marketPlace=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';

			localStorage.clear();
			setTimeout(() => {
				// router.push(MAIN_CONFIG.URLS.BASE, undefined, { shallow: true})
				const adminToken = cookies('context').admintoken
				window.location.href = (marketPlaceData.account && marketPlaceData.account.after_logout_redirect_url__c && !adminToken) ? marketPlaceData.account.after_logout_redirect_url__c : MAIN_CONFIG.URLS.BASE;
			}, 2000);
		}
	}
	const LOADING_SCREEN_SECONDS = 2;
	const { user, currentAccount, sectionVisibilityOptions } = accountData;

	let selectedAccount = accountData.availableAccounts ? accountData.availableAccounts.filter(account => (account.url_name__c === route.indexId || account.sfid === route.indexId || account.sfid === cookies('currentAccount').currentAccount && !route?.indexId)) : [];

	const userConversations = useSelector(state => state.conversations)
	const [isOpenSweetAlert, setIsOpenSweetAlert] = useState(false)
	const [isOpenActiveVal, setIsOpenActiveVal] = useState("")

	const [conversations, setConversations] = useState(userConversations);
	const unreadMessages = conversations && conversations.filter(conversation => conversation.messages.filter(message => message.user_id !== currentUser.id && !message.read_at).length > 0).length > 0

	const [acount, setAccount] = useState({
		label: (selectedAccount.length > 0) ? selectedAccount[0].name : 'All',
		value: (selectedAccount.length > 0) ? selectedAccount[0].sfid : ''
	})

	var accounts = accountData.availableAccounts?.map(account => {
		return {
			firstName: user.first_name,
			lastName: user.last_name,
			label: account.name,
			companyLogo: account.account_logo_url__c,
			primaryColor: account.primary_color__c,
			secondaryColor: account.secondary_color__c,
			profileImage: user.avatar,
			sfid: account.sfid,
			value: account.sfid,
			url_name: account.url_name__c,
		}
	});
	accounts.push({ label: "All", value: "", sfid: "" })
	accounts.reverse();

	useEffect(() => {
		setConversations(userConversations)
	}, [userConversations.length])

	let accountId = cookies('currentAccount').currentAccount

	const showGradeBook = userContext === MAIN_CONFIG.USER_CONTEXT.LOGGED_OUT ? !route.indexId : isEmpty(accountId || route.indexId)


	/**
	 * Triggers an account switch by showing the loader
	 * then changing the account context.
	 * @param {object} account
	 */
	const handleAccountSwitch = async (account) => {
		var isRediractLocal = localStorage.getItem("isRedireactSave") ? JSON.parse(localStorage.getItem("isRedireactSave")) : false
		if (pageType == MAIN_CONFIG.PAGE_TYPES.APPLICANT.APPLY && isRediractLocal || pageType == MAIN_CONFIG.PAGE_TYPES.APPLICANT.RESUME && action === "submit" || route.applicationstepid) {
			alretMsg()
		} else {
			setAccount(account)
			setAccountLoaderVisible(true, account);
			document.cookie = `currentAccount=${account.sfid};path=/`;
			// Remove marketplace from storage
			localStorage.marketPlace = ""
			document.cookie = 'marketPlace=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';

			setTimeout(() => {
				window.location.href = `/applicant/${account.url_name || account.sfid}`;
			}, LOADING_SCREEN_SECONDS * 1000);
		}


	};


	const handleClickTab = (val , url) => {
		setIsOpenActiveVal(val)
		var isRediractLocal = localStorage.getItem("isRedireactSave") ? JSON.parse(localStorage.getItem("isRedireactSave")) : false
		if (pageType == MAIN_CONFIG.PAGE_TYPES.APPLICANT.APPLY && isRediractLocal || pageType == MAIN_CONFIG.PAGE_TYPES.APPLICANT.RESUME && action === "submit" || route.applicationstepid) {
			alretMsg(val , url)
			localStorage.removeItem('hasAccessCode')
			localStorage.removeItem('accessCode')
		} else {
			router.push(url, undefined, { shallow: true })
			setActiveTab(val)
		}
	}

	const iterationModalCloseMsg = "Any progress for your entry will not be saved until you hit “Submit”."

	const isModalOpen = localStorage.getItem("isModalOpen")
	const alretMsg = (val = '' , url) => {
		const getAlert = () => (
			<>

				<div className="save-changes-confirmation-alert modal-utility">
					{/* <SweetAlert
						// warning
						title="Are you sure you want to exit?"
						showConfirm={true}

						confirmBtnText="Okay"
						onConfirm={(e) => {
							localStorage.removeItem("isModalOpen")
							localStorage.removeItem('hasAccessCode')
							localStorage.removeItem('accessCode')
							// setActiveTab(val || MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EXPLORE)
							router.push(url, undefined, { shallow: true })
							setIsOpenSweetAlert(false)
						}}
						showCancel={true}

						cancelBtnText="Cancel"
						onCancel={(e) => setIsOpenSweetAlert(false)}

						cancelBtnCssClass="cta-button CTAWhite primary cta-button__text"
						confirmBtnCssClass="cta-button CTAWhite tertiary cta-button__text"
						className=""
					>
						{pageType == MAIN_CONFIG.PAGE_TYPES.APPLICANT.RESUME && action === "submit" ? <p className="CTAGrey">Your application will not be considered until you click Submit.</p> : isModalOpen ? <p className="CTAGrey">{iterationModalCloseMsg}</p> : <p className="CTAGrey">All changes will be lost if you leave this page without saving.</p>}
					</SweetAlert> */}
					<SweetAlert
                  
                  showConfirm={true}

                  confirmBtnText="Okay"
                  onConfirm={(e) => {
					localStorage.removeItem("isModalOpen")
					localStorage.removeItem('hasAccessCode')
					localStorage.removeItem('accessCode')
					// setActiveTab(val || MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EXPLORE)
					router.push(url, undefined, { shallow: true })
					setIsOpenSweetAlert(false)
				}}
                  showCancel={true}

                  cancelBtnText="Cancel"
                  onCancel={(e) => setIsOpenSweetAlert(false)}
                  customButtons={
                    <div className='action-footer'>
                      <button onClick={(e) => setIsOpenSweetAlert(false)} className='cta-button CTABlack cta-button__text'>Cancel</button>
                      <button onClick={(e) => {
							localStorage.removeItem("isModalOpen")
							localStorage.removeItem('hasAccessCode')
							localStorage.removeItem('accessCode')
							// setActiveTab(val || MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EXPLORE)
							router.push(url, undefined, { shallow: true })
							setIsOpenSweetAlert(false)
						}} className='cta-button CTAWhite tertiary cta-button__text'>Ok</button>
                    </div>
                  }
                  // cancelBtnCssClass="cta-button CTABlack cta-button__text"
                  // confirmBtnCssClass="cta-button CTAWhite tertiary cta-button__text"
                  className=""
                  // reverseButtons = {true}
              >
                <div style={{display: 'flex'}}>
                  <div>
                    <Alert />
                  </div>
                  <div className='content'>
                    <p className='title'>Are you sure you want to exit?</p>
					{pageType == MAIN_CONFIG.PAGE_TYPES.APPLICANT.RESUME && action === "submit" ?
					 <p className="CTAGrey">Your application will not be considered until you click Submit.</p>
					  : isModalOpen ?
					   <p className="CTAGrey">{iterationModalCloseMsg}</p> : 
					   <p className="CTAGrey">All changes will be lost if you leave this page without saving.</p>}
                  </div>
                </div>
              </SweetAlert>
				</div>
			</>
		);
		setIsOpenSweetAlert(getAlert)
	}
	// const alretMsg = (val = '' , url) => {
	// 	const getAlert = () => (
	// 		<>
	// 			<div className="save-changes-confirmation-alert">
	// 				<SweetAlert
	// 					warning
	// 					title="Are you sure you want to exit?"
	// 					showConfirm={true}

	// 					confirmBtnText="Okay"
	// 					onConfirm={(e) => {
	// 						localStorage.removeItem("isModalOpen")
	// 						localStorage.removeItem('hasAccessCode')
	// 						localStorage.removeItem('accessCode')
	// 						// setActiveTab(val || MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EXPLORE)
	// 						router.push(url, undefined, { shallow: true })
	// 						setIsOpenSweetAlert(false)
	// 					}}
	// 					showCancel={true}

	// 					cancelBtnText="Cancel"
	// 					onCancel={(e) => setIsOpenSweetAlert(false)}

	// 					cancelBtnCssClass="cta-button CTAWhite primary cta-button__text"
	// 					confirmBtnCssClass="cta-button CTAWhite tertiary cta-button__text"
	// 					className=""
	// 				>
	// 					{pageType == MAIN_CONFIG.PAGE_TYPES.APPLICANT.RESUME && action === "submit" ? <p className="CTAGrey">Your application will not be considered until you click Submit.</p> : isModalOpen ? <p className="CTAGrey">{iterationModalCloseMsg}</p> : <p className="CTAGrey">All changes will be lost if you leave this page without saving.</p>}
	// 				</SweetAlert>
	// 			</div>
	// 		</>
	// 	);
	// 	setIsOpenSweetAlert(getAlert)
	// }

	const handleLogoClick = (e) => {
		var isRediractLocal = localStorage.getItem("isRedireactSave") ? JSON.parse(localStorage.getItem("isRedireactSave")) : false
		if (pageType == MAIN_CONFIG.PAGE_TYPES.APPLICANT.APPLY && isRediractLocal || pageType == MAIN_CONFIG.PAGE_TYPES.APPLICANT.RESUME && action === "submit" || isModalOpen || route.applicationstepid) {
			alretMsg()
		} else {
			router.push('/', undefined, { shallow: true })
		}
	}

	const { USER_ROLE_CONTEXT } = MAIN_CONFIG;
	const roleContext = USER_ROLE_CONTEXT.find(i => i.context === userContext);
	roleData = roleData ? roleData : [];
	let role = roleData.length > 0 ? roleData.filter(item => item !== roleContext.role) : ''
	return (
		<div className={isMobile && toggleHamburgerMenu ? 'is-mobile-applicant-logged-in-sidebar-menu side-drawer-menu' : !isMobile && toggleHamburgerMenu ? "side-drawer-menu-small" : "side-drawer-menu"} id="side-drawer-menu">
			<Loader loading={loading}/>
			<ul className="side-drawer-logo-notification">
				<li className="side-drawer-logo" onClick={(e) => { handleLogoClick(e) }}>
					<BrandMark
						color={MAIN_CONFIG.COLORS.fillBlue}
						width={30}
						height={30}
						className="small-icon"
						ariaLabel="Kaleidoscope"
						ariaLabelDescription=""
					/>
					<BrandMark
						color={MAIN_CONFIG.COLORS.fillBlue}
						width={30}
						height={30}
						className="large-icon"
						ariaLabel="Kaleidoscope"
						ariaLabelDescription=""
					/>
					{/* <WordMark
						color={MAIN_CONFIG.COLORS.fillBlue}
						className="large-icon"
						ariaLabel="Kaleidoscope"
						ariaLabelDescription=""
					/> */}
				</li>
				<li className="side-drawer-hamburger-menu-icon">
					{!isMobile && <div className="hamburger-menu-icon-container" onClick={() => setToggleHamburgerMenu(!toggleHamburgerMenu)}>
						<div className="hamburger-menu-icon-container__icon"></div>
						<div className="hamburger-menu-icon-container__icon"></div>
					</div>
					}
				</li>
			</ul>

			<nav aria-label="Main Navigation">
				<ul className="side-drawer-menu-list">
					{
						((accountData.availableAccounts.length > 0) && !accountData.enable_sso__c) &&
						<li >
							<div className="program-sponsors">
								<Field
									className='field-group__field-full'
									type='select'
									placeholder='Select Account'
									options={accounts}
									value={acount}
									label='Program Sponsors'
									handleChange={(val) => handleAccountSwitch(val)}
								/>
							</div>

						</li>
					}

					<li className={`side-drawer-menu-list-item ${activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EXPLORE)}`}
						onClick={(e) => handleClickTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EXPLORE , MAIN_CONFIG.URLS.APPLICANT.EXPLORE)}
					>
						<Globe
							className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EXPLORE)}
							width="32"
							height="32"
							color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EXPLORE)}
							ariaLabel="Explore"
							ariaLabelDescription=""
						/>
						<div className='side-drawer-menu-list__item-copy'>
							<div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EXPLORE)}>
								{MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EXPLORE}
							</div>
						</div>
					</li>
					<li className={`side-drawer-menu-list-item ${activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.APPLICATIONS)}`}
						onClick={(e) => handleClickTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.APPLICATIONS , MAIN_CONFIG.URLS.APPLICANT.APPLICATIONS)}
					>
						<AirPlane
							className='side-drawer-menu-list__item-icon'
							width="32"
							height="32"
							color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.APPLICATIONS)}
							ariaLabel="Applications"
							ariaLabelDescription=""
						/>
						<div className='side-drawer-menu-list__item-copy'>
							<div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.APPLICATIONS)}>
								{MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.APPLICATIONS}
							</div>
						</div>
					</li>

					{sectionVisibilityOptions && sectionVisibilityOptions.is_gradebook_section_visible && !showGradeBook &&
						<li className={`side-drawer-menu-list-item ${activeColor('Gradebook')}`}
							onClick={(e) => handleClickTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.GRADEBOOK , MAIN_CONFIG.URLS.APPLICANT.GRADEBOOK)}
						>
							<Book
								className='side-drawer-menu-list__item-icon'
								width="32"
								height="32"
								color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.GRADEBOOK)}
								ariaLabel="Gradebook"
								ariaLabelDescription=""
							/>
							<div className='side-drawer-menu-list__item-copy'>
								<div className={activeColor('Gradebook')}>
									{MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.GRADEBOOK}
								</div>
							</div>
						</li>
					}

					{/* <li className={`side-drawer-menu-list-item ${activeColor('Saved')}`}
						onClick={(e) => handleClickTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.SAVED , MAIN_CONFIG.URLS.APPLICANT.SAVED)}
					>
						<Pin
							className='side-drawer-menu-list__item-icon'
							width="32"
							height="32"
							color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.SAVED)}
							ariaLabel="Saved"
							ariaLabelDescription=""
						/>
						<div className='side-drawer-menu-list__item-copy'>
							<div className={activeColor('Saved')}>
								{MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.SAVED}
							</div>
						</div>
					</li> */}

					<li className={`side-drawer-menu-list-item ${activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PROFILE)}`}
						onClick={(e) => handleClickTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PROFILE , MAIN_CONFIG.URLS.APPLICANT.PROFILE)}
					>
						<Person
							className='side-drawer-menu-list__item-icon'
							width="32"
							height="32"
							color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PROFILE)}
							ariaLabel="Profile"
							ariaLabelDescription=""
						/>
						<div className='side-drawer-menu-list__item-copy'>
							<div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PROFILE)}>
								{MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.PROFILE}
							</div>
						</div>
					</li>

					{/*sectionVisibilityOptions && sectionVisibilityOptions.is_message_section_visible &&
						<li className="side-drawer-menu-list-item"
							onClick={(e) => handleClickTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.MESSAGE)}

						>
							<Chat
								className='side-drawer-menu-list__item-icon'
								width="32"
								height="32"
								color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.MESSAGE)}
								ariaLabel="Message"
								ariaLabelDescription=""
							/>
							<div className='side-drawer-menu-list__item-copy'>
								<div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.MESSAGE)}>
									{MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.MESSAGE}
								</div>
							</div>
						</li>
					*/}

					{sectionVisibilityOptions && sectionVisibilityOptions.is_donor_information_section_visible &&
						<li className={`side-drawer-menu-list-item ${activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DONOR)}`}
							onClick={(e) => handleClickTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DONOR , MAIN_CONFIG.URLS.APPLICANT.DONOR)}

						>
							<TestTubes
								className='side-drawer-menu-list__item-icon'
								width="32"
								height="32"
								color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DONOR)}
								ariaLabel="Donors"
								ariaLabelDescription=""
							/>
							<div className='side-drawer-menu-list__item-copy'>
								<div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DONOR)}>
									{MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DONOR}
								</div>
							</div>
						</li>
					}
					{/* {sectionVisibilityOptions && sectionVisibilityOptions.is_tasks_section_visible &&
						<li className={`side-drawer-menu-list-item ${activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.TASKS)}`}
							onClick={(e) => handleClickTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.TASKS , MAIN_CONFIG.URLS.APPLICANT.TASKS )}

						>
							<Paper
								className='side-drawer-menu-list__item-icon'
								width="32"
								height="32"
								color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.TASKS)}
								ariaLabel="Tasks"
								ariaLabelDescription=""
							/>
							<div className='side-drawer-menu-list__item-copy'>
								<div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.TASKS)}>
									{MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.TASKS}
								</div>
							</div>
						</li>
					} */}
					{/* {sectionVisibilityOptions && sectionVisibilityOptions.is_event_section_visible &&
						<li className={`side-drawer-menu-list-item ${activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EVENTS)}`}
							onClick={(e) => handleClickTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EVENTS)}

						>
							<Calendar
								className='side-drawer-menu-list__item-icon'
								width="32"
								height="32"
								color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EVENTS)}
								ariaLabel="Events"
								ariaLabelDescription=""
							/>
							<div className='side-drawer-menu-list__item-copy'>
								<div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EVENTS)}>
									{MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EVENTS}
								</div>
							</div>
						</li>
					} */}
					{sectionVisibilityOptions && sectionVisibilityOptions.is_disbursement_section_visible &&
						<li className={`side-drawer-menu-list-item ${activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DISBURSEMENTS)}`}
							onClick={(e) => handleClickTab(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DISBURSEMENTS , MAIN_CONFIG.URLS.APPLICANT.DISBURSEMENTS)}

						>
							<Medal
								className='side-drawer-menu-list__item-icon'
								width="32"
								height="32"
								color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DISBURSEMENTS)}
								ariaLabel="Disbursements"
								ariaLabelDescription=""
							/>
							<div className='side-drawer-menu-list__item-copy'>
								<div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DISBURSEMENTS)}>
									{MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.DISBURSEMENTS}
								</div>
							</div>
						</li>
					}
					{sectionVisibilityOptions && sectionVisibilityOptions.is_document_library_section_visible &&
						<li className={`side-drawer-menu-list-item ${activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.RESOURCES)}`}
							onClick={(e) => handleClickTab( MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.RESOURCES , MAIN_CONFIG.URLS.APPLICANT.RESOURCES)}
							// onClick={(e) => router.push(MAIN_CONFIG.URLS.APPLICANT.RESOURCES, undefined, { shallow: true })}

						>
							<PaperClip
								className='side-drawer-menu-list__item-icon'
								width="32"
								height="32"
								color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.RESOURCES)}
								ariaLabel="Resources"
								ariaLabelDescription=""
							/>
							<div className='side-drawer-menu-list__item-copy'>
								<div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.RESOURCES)}>
									{MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.RESOURCES}
								</div>
							</div>
						</li>
					}


					{role.length > 0 &&
						<li className={`side-drawer-menu-list-item ${activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.SWITCHROLE)}`}
							onClick={(e) => router.push(MAIN_CONFIG.URLS.ROLE_SWITCH, undefined, { shallow: true })}
						>
							<SwitchProfile
								className='side-drawer-menu-list__item-icon'
								width="32"
								height="32"
								color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.SWITCHROLE)}
								ariaLabel="Switch Role"
								ariaLabelDescription=""
							/>
							<div className='side-drawer-menu-list__item-copy' >
								<div className={activeColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.SWITCHROLE)}>
									{MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.SWITCHROLE}
								</div>
							</div>
						</li>
					}

					<li className={`side-drawer-menu-list-item`}
						onClick={(e) =>{setLoading(true), router.push(MAIN_CONFIG.URLS.LOGOUT, undefined, { shallow: true })}}
					>
						<Logout
							className='side-drawer-menu-list__item-icon'
							width="32"
							height="32"
							color={iconColor(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.LOGOUT)}
							ariaLabel="Logout"
							ariaLabelDescription=""
						/>
						<div className='side-drawer-menu-list__item-copy'>
							<div className='CTAGrey'>
								{MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.LOGOUT}
							</div>
						</div>
					</li>

				</ul>
			</nav>
			{isOpenSweetAlert}
		</div>
	)
}

export default reduxPage(Sidebar)